import React, { useState, useEffect } from 'react'
import { useNotification } from '../../controls/NotificationContext'
import gql from 'graphql-tag'
import {
  useGetEditInvoiceDataLazyQuery,
  useAddInvoiceItemMutation,
  useAddInvoiceDetailMutation,
  useDeleteDraftInvoiceMutation,
  useDeleteInvoiceItemMutation,
  useDeleteInvoiceDetailMutation,
  useUpdateInvoiceItemsMutation,
  useUpdateInvoiceTemplateMutation,
  useSetInvoiceSubjectMutation,
  AddInvoiceDetailMutation,  
  AddInvoiceItemMutation,
  UpdateInvoiceItemsMutation,
  Invoice,
  Mutation,
  UpdateInvoiceItemInput,
  GetInvoicesQuery,
  InvoiceStatus,
  UpdateInvoiceTemplateMutation,
  SetInvoiceSubjectMutation,
} from '../../../generated/graphql'
import PrepareInvoiceForm from '../../ui/forms/PrepareInvoiceForm'
import { useParams, useHistory, useLocation } from 'react-router'
import { GET_INVOICES_QUERY } from '../lists/InvoiceListContainer'
import * as qs from 'query-string';

// eslint-disable-next-line
export const GET_EDIT_INVOICE_DATA_QUERY = gql`
  query getEditInvoiceData($id: String!) {
    invoice(id: $id) {
      id
      invoiceStatus
      subject
      customer {
        id
        firstName
        lastName
        companyName
      }
      template {
        id
      }
      invoiceItems {
        id
        description
        price
        amount
        VAT
        item {
          id
          price
          description
        }
      }
      invoiceDetails {
        id
        description
        detail {
          id
          description
        }
      }
      user {
        VAT1
        VAT2
        VAT3
        items {
          id
          name
          price
          description
        }
        details {
          id
          name
          description
        }
        templates {
          id
          name
        }
        globalTemplates {
          id
          name
        }
      }
    }
  }
`

// eslint-disable-next-line
export const ADD_INVOICE_ITEM_MUTATION = gql`
  mutation addInvoiceItem(
    $invoiceId: String!,
    $itemId: String!,
    $force: Boolean
  ) {
    addInvoiceItem(invoiceId: $invoiceId, itemId: $itemId, force: $force) {
      id
      description
      price
      item {
        id
        name
        description
        price
      }
    }
  }
`

// eslint-disable-next-line
export const UPDATE_INVOICE_ITEMS_MUTATION = gql`
  mutation updateInvoiceItems(
    $invoiceId: String!
    $data: [UpdateInvoiceItemInput!]!
    $force: Boolean
  ) {
    updateInvoiceItems(invoiceId: $invoiceId, data: $data, force: $force) {
      id
    }
  }
`

// eslint-disable-next-line
export const DELETE_INVOICE_ITEM_MUTATION = gql`
  mutation deleteInvoiceItem($id: String!, $force: Boolean) {
    deleteInvoiceItem(id: $id, force: $force)
  }
`

// eslint-disable-next-line
export const ADD_INVOICE_DETAIL_MUTATION = gql`
  mutation addInvoiceDetail($invoiceId: String!, $detailId: String!, $force: Boolean) {
    addInvoiceDetail(invoiceId: $invoiceId, detailId: $detailId, force: $force) {
      id
      description
      detail {
        id
        name
        description
      }
    }
  }
`

// eslint-disable-next-line
export const DELETE_INVOICE_DETAIL_MUTATION = gql`
  mutation deleteInvoiceDetail($id: String!, $force: Boolean) {
    deleteInvoiceDetail(id: $id, force: $force)
  }
`

export const UPDATE_INVOICE_TEMPLATE = gql`
  mutation updateInvoiceTemplate($invoiceId: String!, $templateId: String!, $force: Boolean) {
    updateInvoiceTemplate(invoiceId: $invoiceId, templateId: $templateId, force: $force) {
      id
    }
  }
`

export const SET_INVOICE_SUBJECT = gql`
  mutation setInvoiceSubject($id: String!, $subject: String!) {
    setInvoiceSubject(id: $id, subject: $subject) {
      id
      subject
    }
  }
`

// eslint-disable-next-line
export const DELETE_DRAFT_INVOICE = gql`
  mutation deleteDraftInvoice($id: String!) {
    deleteDraftInvoice(id: $id)
  }
`

const PrepareInvoiceFormContainer: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const notifications = useNotification()
  const { id, customerId } = useParams<{id: string, customerId: string}>()
  const [invoice, setInvoice] = useState({} as Invoice)
  const query = qs.parse(location.search)
  const forceEdit = query.forceEdit === 'true'
  const fromCustomerMenu = !!customerId
  
  var [
    getPrepareInvoiceData,
    { loading, refetch }
  ] = useGetEditInvoiceDataLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: any) => {
      if (data.invoice.invoiceStatus === InvoiceStatus.Draft || forceEdit) {
        setInvoice(data.invoice)
      } else {
        let url = fromCustomerMenu ? `/customer/${customerId}/invoice/${id}/view` : `/invoice/${id}/view`
        history.replace(url)
      }
    },
    onError: console.log
  })

  const refresh = () => {
    refetch().then(res => {
      if (res && res.data) {
        setInvoice(res.data.invoice as Invoice)
      }
    })
  }

  var [addInvoiceItem] = useAddInvoiceItemMutation({
    onError: console.log,
    onCompleted: (data: AddInvoiceItemMutation) => {
      notifications.success('Invoice data saved')
      refresh()
    }
  })
  var [deleteInvoiceItem] = useDeleteInvoiceItemMutation({
    onError: console.log,
    onCompleted: (data: Mutation) => {
      notifications.success('Invoice data saved')
      refresh()
    }
  })
  var [addInvoiceDetail] = useAddInvoiceDetailMutation({
    onError: console.log,
    onCompleted: (data: AddInvoiceDetailMutation) => {
      notifications.success('Invoice data saved')
      refresh()
    }
  })
  var [deleteInvoiceDetail] = useDeleteInvoiceDetailMutation({
    onError: console.log,
    onCompleted: (data: Mutation) => {
      refresh()
    }
  })
  var [deleteDraftInvoice] = useDeleteDraftInvoiceMutation({
    onError: console.log,
    onCompleted: (data: Mutation) => {      
      notifications.success('Draft invoice deleted')      
    },
    update(proxy, { data }) {
      if (!data || !data.deleteDraftInvoice) return
      let customerId = invoice?.customer?.id || ''
      try {
        const invoicesCache = proxy.readQuery<GetInvoicesQuery>({
          query: GET_INVOICES_QUERY
        })
        if (invoicesCache && invoicesCache.invoices) {
          proxy.writeQuery({
            query: GET_INVOICES_QUERY,
            data: { invoices: invoicesCache.invoices.filter(x => x.id !== data.deleteDraftInvoice) },
          })
        }
      } catch {}

      try {
        const customerInvoicesCache = proxy.readQuery<GetInvoicesQuery>({
          query: GET_INVOICES_QUERY,
          variables: {
            customerId
          }
        })
        if (customerInvoicesCache && customerInvoicesCache.invoices) {
          proxy.writeQuery({
            query: GET_INVOICES_QUERY,
            variables: {
              customerId
            },
            data: { invoices: customerInvoicesCache.invoices.filter(x => x.id !== data.deleteDraftInvoice) },
          })
        }
      } catch {}

      history.push(`/customer/${customerId}`)
    }
  })
  var [updateInvoiceItems] = useUpdateInvoiceItemsMutation({
    onError: console.log,
    onCompleted: (data: UpdateInvoiceItemsMutation) => {
      notifications.success('Invoice data saved')
      refresh()
    }
  })
  var [updateInvoiceTemplate] = useUpdateInvoiceTemplateMutation({
    onError: console.log,
    onCompleted: (data: UpdateInvoiceTemplateMutation) => {
      notifications.success('Invoice template updated')
      refresh()
    }
  })

  var [setInvoiceSubject] = useSetInvoiceSubjectMutation({
    onError: console.log,
    onCompleted: (data: SetInvoiceSubjectMutation) => {
      notifications.success('Subject updated')
      refresh()
    }
  })

  const onAddInvoiceItem = (invoiceId: string, itemId: string) => {
    if (invoiceId && itemId) {
      addInvoiceItem({ variables: { invoiceId, itemId, force: forceEdit } })
    }
  }

  const onDeleteInvoiceItem = (id: string) => {
    if (id) {
      deleteInvoiceItem({ variables: { id, force: forceEdit } })
    }
  }

  const onAddInvoiceDetail = (invoiceId: string, detailId: string) => {
    if (invoiceId && detailId) {
      addInvoiceDetail({ variables: { invoiceId, detailId, force: forceEdit } })
    }
  }

  const onDeleteInvoiceDetail = (id: string) => {
    if (id) {
      deleteInvoiceDetail({ variables: { id, force: forceEdit } })
    }
  }

  const onDeleteDraftInvoice = (id: string) => {
    if (id) {
      deleteDraftInvoice({variables: { id } })
    }
  }

  const onSaveItemChanges = (data: UpdateInvoiceItemInput[]) => {
    if (id && data && data.length > 0) {
      updateInvoiceItems({ variables: { invoiceId: id, data, force: forceEdit} })
    }
  }

  const onSetTemplate = (templateId: string) => {
    if (id && templateId) {
      updateInvoiceTemplate({ variables: { invoiceId: id, templateId, force: forceEdit } })
    }
  }

  const onSetSubject = (subject: string) => {
    if (id) {
      setInvoiceSubject({ variables: { id, subject } })
    }
  }

  useEffect(() => {
    if (id) {
      getPrepareInvoiceData({ variables: { id } })
    }
  }, [id, getPrepareInvoiceData])

  let props = {
    invoice,
    loading: loading || invoice.id == null,
    forceEdit,
    fromCustomerMenu,
    customerId,
    onAddInvoiceItem,
    onAddInvoiceDetail,
    onDeleteInvoiceItem,
    onDeleteInvoiceDetail,
    onDeleteDraftInvoice,
    onSaveItemChanges,
    onSetTemplate,
    onSetSubject
  }

  return (loading || !invoice?.id) ? <div>Loading...</div> : <PrepareInvoiceForm {...props} />
}

export default PrepareInvoiceFormContainer

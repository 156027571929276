import React, { useState, useEffect } from 'react'
import {
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core'
import Countries from '../../../lib/Countries'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Customer, UpdateCustomerInput } from '../../../generated/graphql'
import CustomerInvoiceListContainer from '../../containers/lists/CustomerInvoiceListContainer'
import { BreadcrumbsContext } from '../../controls/BreadcrumbsContext'
import SectionForm from '../controls/SectionForm'
import FormTextField from '../controls/FormTextField'
import CustomFormControl from '../controls/CustomFormControl'
import { useHistory } from 'react-router'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
      textAlign: 'left'
    },
    customerInvoices: {
      marginTop: '3em'
    },
  })
)

function getUpdateFields(customer: Customer): UpdateCustomerInput {
  return {
    companyName: customer.companyName,
    vatNumber: customer.vatNumber,
    firstName: customer.firstName,
    lastName: customer.lastName,
    address: customer.address,
    postbox: customer.postbox,
    postCode: customer.postCode,
    city: customer.city,
    country: customer.country,
    telephone: customer.telephone,
    email: customer.email,
    tags: customer.tags
  }
}

interface CustomerFormProps {
  customer: Customer
  onUpdateCustomer(data: UpdateCustomerInput, id: string | null): Promise<any>
  loading: boolean
  isNew: boolean
}

const CustomerForm: React.FC<CustomerFormProps> = ({
  customer,
  loading,
  onUpdateCustomer,
  isNew
}) => {
  const { setBreadcrumbItems } = React.useContext(BreadcrumbsContext)
  const classes = useStyles()
  const [data, setData] = useState(getUpdateFields(customer))
  const [editMode, setEditMode] = useState(isNew)
  const history = useHistory()

  let customerId = customer && customer.id;

  React.useEffect(() => {
    let items = [
      { name: 'Home', location: '/home' },
      { name: 'Customers', location: '/customers' },
    ]

    if (isNew) {
      items.push({ name: 'New', location: '/customer/new' },)
    } else if (customerId) {
      let name = `${customer.firstName || ''} ${customer.lastName || ''}`.trim() || customer.companyName || 'Unnamed customer'
      items.push({ name, location: `/customer/${customerId}` })
    }

    setBreadcrumbItems(items)
    // eslint-disable-next-line
  }, [customer])

  const setValue = (name: keyof UpdateCustomerInput, value: string) => {
    let newData = { ...data }
    newData[name] = value
    setData(newData)
  }

  useEffect(() => {
    if (!loading) {
      setData(getUpdateFields(customer))
    }
  }, [customer, loading])

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    let p: Promise<any>
    if (isNew && !customer.id) {
      p = onUpdateCustomer(data, null)
    } else {
      p = onUpdateCustomer(data, customer.id)
    }
    p.then(() => setEditMode(false))
  }

  const onCancelEdit = () => {
    setEditMode(false)
    if (isNew) {
      history.replace('/customers')
    } else {
      setData(getUpdateFields(customer))
    }
  }

  return (
    <div>
      <SectionForm
        title="Customer details"
        loading={loading || !customer}
        isNew={isNew}
        editMode={editMode}
        onEdit={() => setEditMode(true)}      
        onCancel={onCancelEdit}
        onSubmit={handleSubmit}
      >
        <Grid item xs={12} md={6}>
          <FormTextField
            readOnly={!editMode}
            variant='outlined'
            label='First Name'
            value={data.firstName || ''}
            onChange={e => setValue('firstName', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            readOnly={!editMode}
            variant='outlined'
            label='Last name'
            value={data.lastName || ''}
            onChange={e => setValue('lastName', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            readOnly={!editMode}
            variant='outlined'
            label='Company name'
            value={data.companyName || ''}
            onChange={e => setValue('companyName', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            readOnly={!editMode}
            variant='outlined'
            label='VAT Number'
            value={data.vatNumber || ''}
            onChange={e => setValue('vatNumber', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            readOnly={!editMode}
            variant='outlined'
            label='Email'
            value={data.email || ''}
            onChange={e => setValue('email', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            readOnly={!editMode}
            variant='outlined'
            label='Telephone'
            value={data.telephone || ''}
            onChange={e => setValue('telephone', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            readOnly={!editMode}
            variant='outlined'
            required
            label='Address'
            value={data.address || ''}
            onChange={e => setValue('address', e.target.value)}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormTextField
            readOnly={!editMode}
            variant='outlined'
            required
            label='Postal Code'
            value={data.postCode || ''}
            onChange={e => setValue('postCode', e.target.value)}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormTextField
            readOnly={!editMode}
            variant='outlined'
            label='Postbox'
            value={data.postbox || ''}
            onChange={e => setValue('postbox', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            readOnly={!editMode}
            variant='outlined'
            required
            label='City'
            value={data.city || ''}
            onChange={e => setValue('city', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomFormControl
            readOnly={!editMode}
            variant='outlined'
            className={classes.formControl}
          >
            <InputLabel>Country</InputLabel>
            <Select
              displayEmpty
              labelWidth={60}
              value={data.country || ''}
              onChange={e =>
                setValue('country', e.target.value as string)
              }
            >
              {Countries.map((name, index) => (
                <MenuItem key={index} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </CustomFormControl>
        </Grid>
      </SectionForm>
      
      {!loading && customer && (
        <div className={classes.customerInvoices}>
          <CustomerInvoiceListContainer customerId={customer.id}/>
        </div>
      )}
    </div>
  )
}

export default CustomerForm

import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import TemplateForm from '../../ui/forms/TemplateForm'
import {
  Template,
  UpdateTemplateInput,
  useGetTemplateLazyQuery,
  useUpdateTemplateMutation,
  GetTemplatesQuery,
} from '../../../generated/graphql'
import { useParams, useHistory } from 'react-router'
import { GET_TEMPLATES_QUERY } from '../lists/TemplateListContainer'
import { useNotification } from '../../controls/NotificationContext'

// eslint-disable-next-line
export const GET_TEMPLATE_QUERY = gql`
  query getTemplate($id: String!) {
    template(id: $id) {
      id
      name
      description
      options
      updatedAt
      scope
    }
  }
`
// eslint-disable-next-line
const UPDATE_TEMPLATE_MUTATION = gql`
  mutation updateTemplate($id: String, $data: UpdateTemplateInput!) {
    updateTemplate(id: $id, data: $data) {
      id
      name
      description
      options
      updatedAt
      scope
    }
  }
`
const TemplateFormContainer: React.FC = () => {
  const notifications = useNotification()
  const history = useHistory()
  const { id } = useParams<{id:string}>()
  const [template, setTemplate] = useState({} as Template)
  const [updateTemplate] = useUpdateTemplateMutation({
    onError: console.log,
    update( proxy, { data } ) {
      if (id != null || !data || !data.updateTemplate) return;      
      try {
        const cache = proxy.readQuery<GetTemplatesQuery>({ query: GET_TEMPLATES_QUERY })
        if (cache && cache.templates) {
          proxy.writeQuery({
            query: GET_TEMPLATES_QUERY,
            data: { templates: cache.templates.concat([data.updateTemplate])},
          })
        }
      } catch {}
    },
    onCompleted: (data: any) => {
      if (data && data.updateTemplate) {
        notifications.success('Template data saved')
        let x = data.updateTemplate as Template
        if (!id) {
          history.push(`/template/${x.id}`)
        } else {
          setTemplate(x)
        }
      }
    },
  })
  const [getTemplate, { loading }] = useGetTemplateLazyQuery({
    onCompleted: (data: any) => {
      setTemplate(data.template)
    },
    onError: console.log
  })  
  const onUpdateTemplate = (data: UpdateTemplateInput, id: string | null): Promise<any> => {
    return updateTemplate({
      variables: {
        id,
        data: data
      }
    })
  }

  useEffect(() => {
    if (id) {
      getTemplate({
        variables: {
          id
        }
      })
    }
  }, [id, getTemplate])

  let props = {
    template,
    loading: loading,
    onUpdateTemplate,
    isNew: id == null,
  }

  return <TemplateForm {...props} />
}

export default TemplateFormContainer

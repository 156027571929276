import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useGetCustomersQuery, Customer, useDeleteCustomerMutation, Mutation } from '../../../generated/graphql'
import CustomerList from '../../ui/lists/CustomerList'
import { useNotification } from '../../controls/NotificationContext'

// eslint-disable-next-line
export const GET_CUSTOMERS_QUERY = gql`
  query getCustomers {
    customers {
      id
      companyName
      firstName
      lastName
      address
      postbox
      postCode
      city
      country
      telephone
      email
    }
  }
`

// eslint-disable-next-line
const DELETE_CUSTOMER_MUTATION = gql`
  mutation deleteCustomer($id: String!) {
    deleteCustomer(id: $id)
  }
`

const CustomerListContainer: React.FC = () => {
  const notifications = useNotification()
  const [customers, setCustomers] = useState([] as Customer[])
  var { loading, refetch } = useGetCustomersQuery({
    onCompleted: (data: any) => {
      setCustomers(data.customers)
    },
    onError: console.log
  })
  const [deleteCustomer] = useDeleteCustomerMutation({
    onError: (error) => {
      notifications.error(error.message)
    },
    onCompleted: (data: Mutation) => {
      notifications.success('Customer deleted')
      refetch().then(res => {
        if (res && res.data) {
          setCustomers(res.data.customers as Customer[])
        }
      })
    }
  })

  const onDeleteCustomer = (id: string) => {
    deleteCustomer({
      variables: { id }
    })
  }

  let props = {
    customers,
    loading,
    onDeleteCustomer
  }

  return (
    <CustomerList {...props}/>
  )
}

export default CustomerListContainer

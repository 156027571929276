import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import ItemForm from '../../ui/forms/ItemForm'
import {
  Item,
  UpdateItemInput,
  useGetItemLazyQuery,
  useUpdateItemMutation,
  GetItemsQuery,
} from '../../../generated/graphql'
import { useParams, useHistory } from 'react-router'
import { useNotification } from '../../controls/NotificationContext'
import { GET_ITEMS_QUERY } from '../lists/ItemListContainer'
import { deletePrepareInvoiceDetailsCache } from '../../../lib/ApolloHelper'

// eslint-disable-next-line
export const GET_ITEM_QUERY = gql`
  query getItem($id: String!) {
    item(id: $id) {
      id
      name
      description
      price
      tags
    }
  }
`
// eslint-disable-next-line
const UPDATE_ITEM_MUTATION = gql`
  mutation updateItem($id: String, $data: UpdateItemInput!) {
    updateItem(id: $id, data: $data) {
      id
      name
      description
      price
      tags
    }
  }
`
const ItemDetailsContainer: React.FC = () => {
  const notifications = useNotification()
  const history = useHistory()
  const { id } = useParams<{id: string}>()
  const [item, setItem] = useState({} as Item)
  const [updateItem] = useUpdateItemMutation({
    onError: console.log,
    update( proxy, { data } ) {
      if (id != null || !data || !data.updateItem) return;
      try {
        // Invalidate GetEditInvoiceDataQuery Cache
        deletePrepareInvoiceDetailsCache(proxy)
        
        const cacheItems = proxy.readQuery<GetItemsQuery>({ query: GET_ITEMS_QUERY })
        if (cacheItems && cacheItems.items) {
          console.log({ cachedItems: cacheItems.items, newItems: data })
          proxy.writeQuery({
            query: GET_ITEMS_QUERY,
            data: { items: cacheItems.items.concat([data.updateItem])},
          })
        }       
      } catch {}
    },
    onCompleted: (data: any) => {
      if (data && data.updateItem) {
        notifications.success('Billable data saved')
        let x = data.updateItem as Item
        if (!id) {
          history.push(`/billable/${x.id}`)
        } else {
          setItem(x)
        }
      }
    }
  })
  const [getItem, { loading }] = useGetItemLazyQuery({
    onCompleted: (data: any) => {
      setItem(data.item)
    },
    onError: console.log
  })
  const onUpdateItem = (data: UpdateItemInput, id: string | null): Promise<any> => {
    return updateItem({
      variables: {
        id,
        data: data
      }
    })
  }

  useEffect(() => {
    if (id) {
      getItem({
        variables: {
          id
        }
      })
    }
  }, [id, getItem])

  let props = {
    item,
    loading,
    onUpdateItem,
    isNew: id == null,
  }

  return <ItemForm {...props} />
}

export default ItemDetailsContainer

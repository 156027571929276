import React, { useState, useEffect } from 'react'
import { FormTextField } from '../controls/FormTextField'
import Grid from '@material-ui/core/Grid'
import { Item, UpdateItemInput } from '../../../generated/graphql'
import { BreadcrumbsContext } from '../../controls/BreadcrumbsContext'
import SectionForm from '../controls/SectionForm'
import { useHistory } from 'react-router'

interface ItemFormProps {
  item: Item
  onUpdateItem(data: UpdateItemInput, id: string | null): Promise<any>
  loading: boolean,
  isNew: boolean,
}

const ItemForm: React.FC<ItemFormProps> = ({ item, loading, onUpdateItem, isNew }) => {
  const { setBreadcrumbItems } = React.useContext(BreadcrumbsContext)  
  const [name, setName] = useState(item.name)
  const [description, setDescription] = useState(item.description)
  const [price, setPrice] = useState((item.price || 0).toFixed(2))
  const [editMode, setEditMode] = useState(isNew)
  const history = useHistory()

  const itemId = (item && item.id) || ''

  React.useEffect(() => {
    let items = [
      {  name: 'Home', location: '/home' },
      {  name: 'Billables', location: '/billables' }
    ]    
        
    if (isNew) {
      items.push({ name: 'New', location: `/billable/new` })
    } else if (itemId) {
      items.push({ name: item.name, location: `/billable/${itemId}` })
    }
    
    setBreadcrumbItems(items);
    // eslint-disable-next-line
  }, [itemId])

  useEffect(() => {
    if (!loading) {
      loadFields()
    }
    // eslint-disable-next-line
  }, [item, loading])

  const loadFields = () => {
    setName(item.name)
    setDescription(item.description)
    setPrice((item.price || 0).toFixed(2).toString())
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    let data = {
      name,
      description,
      price: parseFloat(price || '0'),
    }

    let p:Promise<any>
    if (isNew && !item.id) {
      p = onUpdateItem(data, null)
    } else {
      p = onUpdateItem(data, item.id) 
    }
    p.then(() => setEditMode(false))
  }

  const onCancelEdit = () => {
    setEditMode(false)
    if (isNew) {
      history.replace('/billables')
    } else {
      loadFields()
    }
  }

  return (
    <SectionForm
      title="Billable details"
      loading={loading || !item}
      isNew={isNew}
      editMode={editMode}
      onEdit={() => setEditMode(true)}      
      onCancel={onCancelEdit}
      onSubmit={handleSubmit}
    >
      <Grid item xs={12} md={3}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          required                  
          label='Name'
          value={name || ''}
          onChange={e => setName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          required
          label='Description'
          value={description || ''}
          onChange={e => setDescription(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          label='Price (€)'
          value={ price || ''}
          onChange={e => setPrice(e.target.value)}
        />
      </Grid>
    </SectionForm>
  )
}

export default ItemForm

import React, { useState } from 'react'
import gql from 'graphql-tag'
import { 
  useGetItemsQuery,
  useDeleteItemMutation,
  Item,
  Mutation,
} from '../../../generated/graphql'
import ItemList from '../../ui/lists/ItemList'
import { useNotification } from '../../controls/NotificationContext'

// eslint-disable-next-line
export const GET_ITEMS_QUERY = gql`
  query getItems {
    items {
      id
      name
      description
      price
      tags
    }
  }
`

// eslint-disable-next-line
const DELETE_ITEM_MUTATION = gql`
  mutation deleteItem($id: String!) {
    deleteItem(id: $id)
  }
`

const ItemListContainer: React.FC = () => {
  const notifications = useNotification()
  const [items, setItems] = useState([] as Item[])
  const { loading, refetch } = useGetItemsQuery({
    onCompleted: (data: any) => {
      setItems(data.items)
    },
    onError: console.log
  })

  const [deleteInvoiceItem] = useDeleteItemMutation({
    onError: console.log,
    onCompleted: (data: Mutation) => {
      notifications.success('Billable deleted')
      refetch().then(res => {
        if (res && res.data) {
          setItems(res.data.items as Item[])
        }
      })
    }
  })

  
  const onDeleteItem = (id: string) => {
    deleteInvoiceItem({
      variables: { id }
    })
  }

  let props = {
    items,
    loading,
    onDeleteItem
  }

  return <ItemList {...props} />
}

export default ItemListContainer

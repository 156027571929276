import React from 'react'
import {
  TextField,
  TextFieldProps,  
} from '@material-ui/core'
import { withStyles, Theme } from '@material-ui/core/styles'

export interface CustomTextFieldProps {
  readOnly?: boolean
}

export type FormTextFieldProps = CustomTextFieldProps & TextFieldProps

export const FormTextField: React.FC<FormTextFieldProps> = (props) => {    
  let disabled = props.disabled || props.readOnly
  let required = props.readOnly ? false : props.required
  let value = props.readOnly ? props.value || '-' : props.value
    
  let updatedProps = {
    ...props,
    ...{
      disabled,
      required,
      value,
    }
  } as TextFieldProps

  const ThemedTextField = props.readOnly ? withStyles(({palette}: Theme) => ({
    root: {
      '& label.Mui-disabled': {
        color: palette.text.secondary
      },
      '& input.Mui-disabled': {
        color: props.disabled ? palette.text.secondary : palette.text.primary
      },
      '& .MuiInput-underline:before' : {
        borderBottom: 'none'
      },
      '& fieldset': {
        border: 'none'
      },
    }
  }))(TextField) : TextField

  return (
    <ThemedTextField { ...updatedProps } />
  )
}

export default FormTextField

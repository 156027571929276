import React from 'react';
import * as Colors from '@material-ui/core/colors'
import {
  Description as EditIcon,
  Help as UnknownIcon,
  Info as CreatedIcon,
  WatchLater as WaitingIcon,
  CheckCircle as FilledCircleIcon,
  HighlightOffOutlined as OffIcon,
} from '@material-ui/icons'
import { Tooltip, useTheme } from '@material-ui/core';

interface InvoiceStatusProps {
  className?: string
  status: string
  color?: string
  tooltip?: string
}

export const InvoiceStatusIcon: React.FC<InvoiceStatusProps> = ({ className, status, color, tooltip}) => {
  const theme = useTheme();
  let Component = UnknownIcon
  let defaultColor: string = Colors.grey[500]

  switch (status.toUpperCase()) {    
    case 'DRAFT':
      Component = EditIcon
      defaultColor =  Colors.grey[500]
      tooltip= tooltip || "Draft"
      break;
    case 'CREATED':
      Component = CreatedIcon
      defaultColor = theme.palette.warning.dark
      tooltip= tooltip || "Created and awaiting sending"
      break;
    case 'SENT':
      Component = WaitingIcon;
      defaultColor = theme.palette.error.main
      tooltip= tooltip || "Sent and awaiting payment"
      break;
    case 'PAID':
      Component = FilledCircleIcon
      defaultColor = theme.palette.success.main
      tooltip= tooltip || "Paid"
      break;
    default:
      break;
  }

  if (tooltip) {
    return (<Tooltip title={tooltip}><Component className={className} style={{ color: color || defaultColor }} /></Tooltip>)
  } else {
    return (<Component className={className} style={{color: color || defaultColor}}/>)
  }
}

interface AttachmentStatusProps {
  className?: string
  numAttachments: number
  color?: string
  tooltip?: string
}

export const AttachmentStatusIcon: React.FC<AttachmentStatusProps> = ({ className, numAttachments, color, tooltip}) => {
  const theme = useTheme();
  let Component = UnknownIcon
  let defaultColor: string = Colors.grey[500]

  if (numAttachments > 0) {
    Component = FilledCircleIcon
    defaultColor =  theme.palette.success.main
    tooltip= tooltip || `${numAttachments} attachment${ numAttachments > 1 ? 's' : ''}`
  } else {
    Component = OffIcon
    defaultColor =  Colors.grey[500]
    tooltip= tooltip || "No attachments"
  }

  if (tooltip) {
    return (<Tooltip title={tooltip}><Component className={className} style={{ color: color || defaultColor }} /></Tooltip>)
  } else {
    return (<Component className={className} style={{color: color || defaultColor}}/>)
  }
}

import React from 'react'
import clsx from 'clsx'
import * as Colors from '@material-ui/core/colors'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useNotification, NotificationType } from '../../controls/NotificationContext'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'absolute',
      top: '64px',
    },
    notification: {      
      lineHeight: '48px',
      height: '48px',
      width: '100%',
      fontSize: '16px',
      fontWeight: 500,
      paddingLeft: '1em',
    }
  })
)

interface NotificationProps {
  id: string,
  type: NotificationType,
  text: string,
}

const Notification: React.FC<NotificationProps> = (props) => {
  const classes = useStyles()
  let color: string = Colors.blue[900];
  
  switch (props.type) {
  case NotificationType.ERROR:
    color = Colors.red[800];
    break;
  case NotificationType.WARNING:
    color = Colors.orange[500];
    break;  
  case NotificationType.SUCCESS:
    color = Colors.green[500];
    break;
  case NotificationType.INFO:
    default:
    color = Colors.blue[900];
    break;
  }

  return (
    <div className={classes.notification} style={{backgroundColor: color}}>
      { props.text }
    </div>
  )  
}

const NotificationArea: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const classes = useStyles()

  let notifContext = useNotification()  
  return (  
    <div className={clsx(props.className, classes.root)}>
      { notifContext.notifications.map( (n, index) => (
        <Notification key={index} id={n.id} type={n.type} text={n.text} />
      ))}
    </div>
  )
}      

export default NotificationArea

import { IconButton, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons'
import * as _ from 'lodash'
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables"
import * as React from 'react'
import { useHistory } from 'react-router'
import { Expense } from '../../../generated/graphql'
import { BreadcrumbsContext } from '../../controls/BreadcrumbsContext'
import ConfirmDialog from '../controls/ConfirmDialog'
import { SortedColumn, createColumns, onColumnSortChange, onRowsSelect, defaultTableOptions } from '../../../lib/TableUtils'
import Section from '../controls/Section'
import moment from 'moment'
import { AttachmentStatusIcon } from '../controls/StatusIcon'

const useStyles = makeStyles({  
  row: {
    cursor: 'pointer'
  },
  attachmentIcon: {
    textAlign: 'center'
  }
})

interface RowData {
  id: string
  date: Date
  name: string
  tags: string
  description: string
  cost: number
  vatAmount: number
  numAttachments: number
}

function createData(x: Expense): RowData {
  return {
    id: x.id,
    date: x.date,
    name: x.name,
    tags: x.tags || '',
    description: x.description || '-',
    cost: x.price * x.amount,
    vatAmount: (x.price * x.amount) - x.price * x.amount / (1 + (x.VAT || 0) / 100),
    numAttachments: x.attachments.length,
  }
}

interface ExpenseListProps {
  expenses: Expense[]
  loading: boolean
  onDeleteExpense(id: string): void
}

const ExpenseList: React.FC<ExpenseListProps> = ({ expenses, loading, onDeleteExpense }) => {
  const { setBreadcrumbItems } = React.useContext(BreadcrumbsContext)
  const [rows, setRows] = React.useState([] as RowData[])
  const [rowsSelected, setRowsSelected] = React.useState([] as number[])
  const [sortedColumn, setSortedColumn] = React.useState({name: '', direction: 'none' } as SortedColumn);
  const classes = useStyles()
  const history = useHistory()

  const columns: MUIDataTableColumnDef[] = createColumns([
    {
      name: 'date',
      label: 'Date',
      options: {
        customBodyRender: (value) => `${moment(value).format('LL')}`,
      }
    },
    { name: 'name', label: 'Vendor' },
    { name: 'description', label: 'Details' },
    {
      name: 'numAttachments',
      label: 'Attachments',
      options: {
        customBodyRender: (value) => (
          <div className={classes.attachmentIcon}>
            <AttachmentStatusIcon numAttachments={value} />
          </div>
        ),
      }
    },
    {
      name: 'cost',
      label: 'Cost',
      options: {
        setCellProps: () => ({align: 'right'}),
        customBodyRender: (value) => `${value.toFixed(2)} €`,
      }
    },
    {
      name: 'vatAmount',
      label: 'VAT',
      options: {
        setCellProps: () => ({align: 'right'}),
        customBodyRender: (value) => `${value.toFixed(2)} €`,
      }
    },
  ], sortedColumn)

  const tableOptions:MUIDataTableOptions = {
    selectableRows: 'single',
    rowsSelected: rowsSelected,
    download: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    onRowClick: (rowData, rowMeta) => {
      let row = rows[rowMeta.dataIndex]
      history.push(`/expense/${row.id}`)
    },
    setRowProps: (row) => ({ className: classes.row }),
    onColumnSortChange: (column, direction) => onColumnSortChange(column, direction, setSortedColumn),
    customToolbar: () => (
      <React.Fragment>
        <Tooltip title='Add new expense'>
          <IconButton onClick={onCreateExpense}>
            <AddIcon/>
          </IconButton>
        </Tooltip>
      </React.Fragment>
    ),
    onRowsSelect: (current, all) => onRowsSelect(current, all, rowsSelected, setRowsSelected),    
    customToolbarSelect: (selectedRows) => {
      let index = (selectedRows as any).data[0].dataIndex;
      let row = rows[index];
      return (
        <React.Fragment>          
          <ConfirmDialog
            customButton={(
            <Tooltip title='Delete expense'>
              <IconButton>
                <DeleteIcon/>
              </IconButton>
            </Tooltip>
            )}
            title='Delete Expense'
            onAccept={() => handleDeleteExpense(row.id)}
          >
            Are you sure you want to delete the expense{' '}
            <strong>"{row.description}"</strong>?
          </ConfirmDialog>
        </React.Fragment>
      )
    }
  }
  

  React.useEffect(() => {
    setBreadcrumbItems([
      { name: 'Home', location: '/home' },
      { name: 'Expenses', location: '/expenses' }
    ])
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (!loading && expenses) {
      setRowsSelected([])
      let tableRows = _.sortBy(expenses.map(x => createData(x)), ['date']).reverse()
      setRows(tableRows)
    }
  }, [expenses, loading])

  const onCreateExpense = () => {
    history.push('/expense/new')
  }

  const handleDeleteExpense = (id: string) => {
    onDeleteExpense(id)
  }

  return (
    <Section title="Expenses">
      {loading || !expenses ? (
        <div>Loading...</div>
      ) : (
        <MUIDataTable
          title=''
          data={rows}
          columns={columns}
          options={{...defaultTableOptions, ...tableOptions}}
        />
      )}
    </Section>
  )
}

export default ExpenseList

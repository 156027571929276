import React from 'react'
import { makeStyles, Tooltip } from '@material-ui/core'
import { Help as InfoIcon } from '@material-ui/icons'
import * as Colors from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  root: {
    border: `solid 1px ${theme.palette.primary.dark}`,
    borderRadius: '15px',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    padding: '16px',
    marginTop: '16px',
  },
  header: {
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    top: '-26px',
    left: '26px',
    padding: '0px 8px',
  },
  title: {
    fontSize: '28px',
    fontWeight: 600,    
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 600,
    marginLeft: '0.5em',
  },
  container: {
  },
  widgets: {
    position: 'absolute',
    right: '20px',
    top: '-27px',
    padding: '8px 8px',
    backgroundColor: theme.palette.background.paper,
    border: `solid 1px ${theme.palette.primary.dark}`,
    borderRadius: '10px',
    height: '54px',
  },
  infoIcon: {
    color: Colors.blue[900],
    verticalAlign: 'bottom',
    marginLeft: '6px',
  },
}))

interface SectionProps {
  title: string,
  subtitle?: string,
  widgets?: React.ReactNode
  infoText?: string
}

export const Section: React.FC<SectionProps> = ({
  title,
  subtitle,
  children,
  widgets,
  infoText,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <span className={classes.title}>{title}</span>
        { subtitle && (
        <span className={classes.subtitle}>{subtitle}</span>
        )}
        { infoText && (
        <span className={classes.infoIcon}>
          <Tooltip title={infoText}>
            <InfoIcon />
          </Tooltip>
        </span>
        )}
      </div>
      { widgets && (
      <div className={classes.widgets}>
        { widgets }
      </div>
      )}    
      <div className={classes.container}>
        { children }
      </div>
    </div>
  )
}

export default Section
import React, { useState, useEffect } from 'react'
import {
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core'
import DateFnsUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Expense, UpdateExpenseInput } from '../../../generated/graphql'
import { BreadcrumbsContext } from '../../controls/BreadcrumbsContext'
import moment from 'moment'
import SectionForm from '../controls/SectionForm'
import { useHistory } from 'react-router'
import FormTextField from '../controls/FormTextField'
import FormKeyboardDatePicker from '../controls/FormKeyboardDatePicker'
import CustomFormControl from '../controls/CustomFormControl'
import ExpenseListContainer from '../../containers/lists/AttachmentListContainer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
      textAlign: 'left'
    }
  })
)

interface ExpenseFormProps {
  expense: Expense,
  vatList: number[],
  onUpdateExpense(data: UpdateExpenseInput, id: string | null): Promise<any>
  loading: boolean,
  isNew: boolean,
}

const ItemForm: React.FC<ExpenseFormProps> = ({ expense, vatList, loading, onUpdateExpense, isNew }) => {
  const { setBreadcrumbItems } = React.useContext(BreadcrumbsContext)
  const classes = useStyles()
  const [date, setDate] = useState(expense.date || new Date())
  const [name, setName] = useState(expense.name)
  const [description, setDescription] = useState(expense.description)
  const [amount, setAmount] = useState((expense.amount || 1).toFixed(2))
  const [price, setPrice] = useState((expense.price || 0).toFixed(2))
  const [VAT, setVAT] = useState(expense.VAT || 0)
  const [editMode, setEditMode] = useState(isNew)
  const history = useHistory()
  const expenseId = (expense && expense.id) || ''

  React.useEffect(() => {
    let items = [
      {  name: 'Home', location: '/home' },
      {  name: 'Expenses', location: '/expenses' }
    ]    
        
    if (isNew) {
      items.push({ name: 'New', location: `/expense/new` })
    } else if (expenseId) {
      items.push({ name: expense.name, location: `/expense/${expenseId}` })
    }
    
    setBreadcrumbItems(items);
    // eslint-disable-next-line
  }, [expenseId])
  
  useEffect(() => {
    if (!loading) {
      loadFields()
    }
    // eslint-disable-next-line
  }, [expense, loading])

  const loadFields = () => {
    setDate(expense.date || new Date())
    setName(expense.name)      
    setDescription(expense.description)
    setVAT(expense.VAT || 0)
    setAmount((expense.amount || 1).toFixed(2).toString())
    setPrice((expense.price || 0).toFixed(2).toString())
  }

  const handleSubmit = (event: React.FormEvent) => {    
    event.preventDefault()
    let data = {
      date,
      name,
      description,
      amount: parseFloat(amount || '1'),
      VAT: VAT || 0,
      price: parseFloat(price || '0'),
    }

    let p: Promise<any>
    if (isNew && !expense.id) {
      p = onUpdateExpense(data, null)
    } else {
      p = onUpdateExpense(data, expense.id) 
    }
    p.then(() => setEditMode(false))
  }

  const handleDateChange = (value: any) => {
    let mom = moment(value)    
    let date = mom.toDate()
    setDate(date)
  }

  const onCancelEdit = () => {
    setEditMode(false)
    if (isNew) {
      history.replace('/expenses')
    } else {
      loadFields()
    }
  }

  const getVatAmount = () => {
    let result = '-'
    if (!isNaN(parseFloat(amount)) && !isNaN(parseFloat(price))) {
      let total = parseFloat(amount) * parseFloat(price);
      let value =  total - ( total / (1 + VAT/100));
      result = `${value.toFixed(2)} €`
    }
    return result
  }

  const vats = vatList.length ? vatList : [0]

  return (
    <div>
      <SectionForm
        title="Expense details"
        loading={loading || !expense}
        isNew={isNew}
        editMode={editMode}
        onEdit={() => setEditMode(true)}      
        onCancel={onCancelEdit}
        onSubmit={handleSubmit}
      >
        <Grid item xs={12} md={8}>
          <FormTextField
            readOnly={!editMode}
            variant='outlined'
            required
            label='Vendor'
            value={name || ''}
            onChange={e => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormKeyboardDatePicker
              readOnly={!editMode}
              style={{marginTop: 0}}
              margin='normal'
              inputVariant='outlined'
              label='Expense date'
              format='LL'
              value={date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormTextField
            readOnly={!editMode}
            variant='outlined'
            label='Description'
            value={description || ''}
            onChange={e => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormTextField
            readOnly={!editMode}
            variant='outlined'
            label='Price with VAT (€)'
            value={price || ''}
            onChange={e => setPrice(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormTextField
            readOnly={!editMode}
            variant='outlined'
            required
            label='Quantity / Amount'
            value={amount || ''}
            onChange={e => setAmount(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CustomFormControl
            readOnly={!editMode}
            variant='outlined'
            className={classes.formControl}>
            <InputLabel>VAT</InputLabel>
            <Select
              value={VAT || vats[0].toString()}
              required
              onChange={e => setVAT(e.target.value as number)}
            >
              {vats.map((v, index) => {
                let vat = v || 0
                return (
                  <MenuItem key={index} value={vat}>
                    {vat} %
                  </MenuItem>
                )
              })}
            </Select>
          </CustomFormControl>                
        </Grid>
        <Grid item xs={12} md={3}>
          <FormTextField
            readOnly={true}
            variant='outlined'
            label='VAT Amount'
            value={getVatAmount() || ''}
            onChange={() => {}}
          />               
        </Grid>
      </SectionForm>
      { !isNew && (
        <React.Fragment>      
          <br/>
          <ExpenseListContainer expenseId={expense.id} />
        </React.Fragment>
      )}        
    </div>
  )
}

export default ItemForm

import React, { Fragment } from 'react'
import { Button, IconButton, Tooltip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import Section from './Section'

const useStyles = makeStyles(({palette}: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        width: '100%'
      },      
    },    
    formControl: {
      width: '100%',
      textAlign: 'left'
    },
    submitDiv: {
      marginTop: '2em',
      textAlign: 'right',
      '& .MuiButtonBase-root': {
        marginLeft: '1em'
      }
    },
    editButton: {
      marginTop: '-6px'
    }
  })
)

interface SectionFormProps {
  title: string
  subtitle?: string
  disableEdit?: boolean
  editMode: boolean
  loading?: boolean
  isNew: boolean
  onEdit: () => void
  onCancel: () => void
  onSubmit: (event: React.FormEvent<Element>) => void
}

const SectionForm: React.FC<SectionFormProps> = ({
  title,
  subtitle,
  disableEdit,
  editMode,
  loading,
  isNew,
  onEdit,
  onCancel,
  onSubmit,
  children,
}) => {
  const classes = useStyles()

  const widgets = !disableEdit && !editMode && (
    <Tooltip title="Edit">
      <IconButton className={classes.editButton} aria-label='Edit' onClick={() => onEdit()}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  )

  return (
    <Section title={title} subtitle={subtitle} widgets={widgets}>
      <br />
      <br />        
      { loading ? (
        <div>Loading...</div>
      ) : (
        <form className={classes.root} onSubmit={onSubmit}>
          <div className={classes.root}>          
            <Grid container spacing={3}>
              { children }
            </Grid>
          </div>          
          <Grid className={classes.submitDiv} item xs={12}>
            { !disableEdit && editMode && (
              <Fragment>
                <Button variant='outlined' onClick={() => onCancel()}>Cancel</Button>                    
                <Button type='submit' variant='contained' color='primary'>
                  { isNew ? "Create" : "Save" }
                </Button>
              </Fragment>
            )}
          </Grid>
        </form>
      )}
    </Section>
  )
}

export default SectionForm

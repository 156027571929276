import * as React from 'react'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
 } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables"
import {  
  Tooltip,
  IconButton
} from '@material-ui/core'
import { Customer } from '../../../generated/graphql'
import { useHistory } from 'react-router'
import * as _ from 'lodash'
import { BreadcrumbsContext } from '../../controls/BreadcrumbsContext'
import ConfirmDialog from '../controls/ConfirmDialog'
import { SortedColumn, createColumns, onColumnSortChange, onRowsSelect, defaultTableOptions } from '../../../lib/TableUtils'
import Section from '../controls/Section'

interface CustomerListProps {
  customers: Customer[]
  loading: boolean,
  onDeleteCustomer(id: string): void
}

const useStyles = makeStyles({
  row: {
    cursor: 'pointer'
  }
})

interface RowData {
  id: string
  fullName: string
  email: string
  telephone: string
}

function createData(
  id: string,
  firstName: string | null | undefined,
  lastName: string | null | undefined,
  companyName: string | null | undefined,
  email: string | null | undefined,
  telephone: string | null | undefined
): RowData {
  return {
    id,
    fullName: `${firstName || ''} ${lastName || ''}`.trim() || companyName || '',
    email: email || '',
    telephone: telephone || ''
  }
}

const CustomerList: React.FC<CustomerListProps> = ({ customers, loading, onDeleteCustomer }) => {
  const { setBreadcrumbItems } = React.useContext(BreadcrumbsContext)
  const [rows, setRows] = React.useState([] as RowData[])
  const [rowsSelected, setRowsSelected] = React.useState([] as number[])
  const [sortedColumn, setSortedColumn] = React.useState({name: '', direction: 'none' } as SortedColumn);
  const classes = useStyles()
  const history = useHistory()

  const columns: MUIDataTableColumnDef[] = createColumns([
    {name: 'fullName', label: 'Name'},
    {name: 'email', label: 'Email'},
    {name: 'telephone', label: 'Telephone'}
  ], sortedColumn)
  
  const tableOptions:MUIDataTableOptions = {
    selectableRows: 'single',
    rowsSelected: rowsSelected,
    download: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    onRowClick: (rowData, rowMeta) => {
      let row = rows[rowMeta.dataIndex]
      history.push(`/customer/${row.id}`)
    },
    setRowProps: (row) => ({ className: classes.row }),
    onColumnSortChange: (column, direction) => onColumnSortChange(column, direction, setSortedColumn),
    customToolbar: () => (
      <React.Fragment>
        <Tooltip title='Add new customer'>
          <IconButton onClick={onCreateCustomer}>
            <AddIcon/>
          </IconButton>
        </Tooltip>
      </React.Fragment>
    ),
    onRowsSelect: (current, all) => onRowsSelect(current, all, rowsSelected, setRowsSelected),
    customToolbarSelect: (selectedRows) => {      
      let index = (selectedRows as any).data[0].dataIndex;
      let row = rows[index];
      
      return (
        <React.Fragment>          
          <ConfirmDialog
            customButton={(
            <Tooltip title='Delete customer'>
              <IconButton>
                <DeleteIcon/>
              </IconButton>
            </Tooltip>
            )}
            title='Delete Customer'
            onAccept={() => handleDeleteCustomer(row.id)}
          >
            Are you sure you want to delete the customer{' '}
            <strong>"{row.fullName}"</strong>?
          </ConfirmDialog>
        </React.Fragment>
      )
    }
  }  

  React.useEffect(() => {
    setBreadcrumbItems([
      {  name: 'Home', location: '/home' },
      {  name: 'Customers', location: '/customers' }
    ])
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (!loading && customers) {
      setRowsSelected([])
      let tableRows = _.sortBy(customers.map(c =>
        createData(c.id, c.firstName, c.lastName, c.companyName, c.email, c.telephone)
        ), ['fullName'])
      setRows(tableRows)
    }
  }, [customers, loading])

  const onCreateCustomer = () => {
    history.push('/customer/new')
  }

  const handleDeleteCustomer = (id: string) => {
    onDeleteCustomer(id)
  }

  return (
    <Section title="Customers">
      {loading || !customers ? (
        <div>Loading...</div>
      ) : (
        <MUIDataTable
          title=''
          data={rows}
          columns={columns}
          options={{...defaultTableOptions, ...tableOptions}}
        />
      )}
    </Section>
  )
}

export default CustomerList

import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import { makeStyles, withStyles, Theme, Color } from '@material-ui/core'
import * as Colors from '@material-ui/core/colors'

const useStyles = makeStyles({
  button: {
    display: 'inline-flex'
  },
})

interface ConfirmDialogProps {
  customButton?: React.ReactNode
  title: string
  buttonLabel?: string
  buttonClassName?: string
  disabled?: boolean
  buttonVariant?: 'text' | 'outlined' | 'contained'
  buttonColor?: 'inherit' | 'primary' | 'secondary' | 'default'
  customButtonColor?: Color
  onAccept(): void
  onCancel?(): void
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = props => {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    if (props.onCancel) {
      props.onCancel()
    }
  }

  const handleAccept = () => {
    setOpen(false)
    props.onAccept()
  }
  
  var CustomButton:any = Button;
  if (props.customButtonColor) {
    let color = props.customButtonColor || Colors.grey
    CustomButton = withStyles((theme: Theme) => ({
      root: {
        color: theme.palette.getContrastText(color[500]),
        backgroundColor: color[500],
        '&:hover': {
          backgroundColor: color[700],
        },
      },
    }))(Button)
  }
  
  return (
    <div className={classes.button}>
        { props.customButton ? (
          <span onClick={handleClickOpen}>
            {props.customButton}
          </span>
        ) : (
          <CustomButton
            disabled={props.disabled || false }
            className={props.buttonClassName || ''}
            variant={ props.buttonVariant || 'contained' }
            color={ props.buttonColor || 'primary' }
            onClick={handleClickOpen}
          >
            {props.buttonLabel || ''}
          </CustomButton>
        )}
      <Dialog
        open={open}
        disableBackdropClick={true}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleAccept} color='primary' variant='contained' autoFocus>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmDialog

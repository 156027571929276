import React, { useContext } from 'react'
import {
  Breadcrumbs as MaterialBreadcrumbs,
  makeStyles,  
} from '@material-ui/core'

import { Link, useLocation } from 'react-router-dom'
import { BreadcrumbsContext } from '../../controls/BreadcrumbsContext'

const useStyles = makeStyles(theme => ({
  root: {
    height: '40px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  link: {
    lineHeight: '40px',
    textDecoration: 'none',
    color: theme.palette.primary.dark,
  },
  breadcrumbs: {
    paddingLeft: '12px',
    textAlign: 'left'
  }
}))

const Breadcrumbs: React.FC = () => {  
  const location = useLocation()
  const classes = useStyles()
  let { items, setBreadcrumbItems } = useContext(BreadcrumbsContext)
  const pathName = location.pathname

  React.useEffect(() => {
    setBreadcrumbItems([]) // Reset breadcrumbs on pathname change
    // eslint-disable-next-line
  }, [pathName])

  return (
    <div className={classes.root}>
      <MaterialBreadcrumbs className={classes.breadcrumbs} aria-label="breadcrumb">
        {items.map((item, index) => (
          <Link className={classes.link} key={index} to={item.location}>
            {item.name}
          </Link>
        ))}
      </MaterialBreadcrumbs>
    </div>
  )
}

export default Breadcrumbs

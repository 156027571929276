import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import {
  useGetInvoicesLazyQuery,  
  useCreateInvoiceMutation,
  Invoice,  
  Mutation,
  GetInvoicesQuery
} from '../../../generated/graphql'
import CustomerInvoiceList from '../../ui/lists/CustomerInvoiceList'
import { useHistory } from 'react-router'
import { useNotification } from '../../controls/NotificationContext'
import { GET_INVOICES_QUERY } from './InvoiceListContainer'

// eslint-disable-next-line
export const CREATE_INVOICE_QUERY = gql`
  mutation createInvoice($id: String!) {
    createCustomerInvoice(id: $id) {
      id
      invoiceDate
      updatedAt      
      invoiceStatus
      invoiceNumber
    	recipientCompanyName
    	recipientFirstName
    	recipientLastName
      subject
      customer {
        id
        firstName
        lastName
        companyName
      }
      invoiceItems {
        amount
        price
        VAT
        item {
          price
        }
      }
    }
  }
`

interface CustomerInvoiceListContainerProps {
  customerId: string
}

const CustomerInvoiceListContainer: React.FC<CustomerInvoiceListContainerProps> = ({customerId}) => {
  const notifications = useNotification()
  const history = useHistory()
  const [invoices, setInvoices] = useState([] as Invoice[])
  const [getInvoices, { loading, refetch }] = useGetInvoicesLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: any) => {
      setInvoices(data.invoices)
    },
    onError: console.log
  })
  var [createInvoice] = useCreateInvoiceMutation({
    onError: (e) => {
      notifications.error('Error creating new invoice')
      console.log(e)
    },
    update( proxy, { data } ) {
      if (!data || !data.createCustomerInvoice) return;      
      try {
        const cache = proxy.readQuery<GetInvoicesQuery>({ query: GET_INVOICES_QUERY })
        if (cache && cache.invoices) {
          proxy.writeQuery({
            query: GET_INVOICES_QUERY,
            data: { invoices: cache.invoices.concat([data.createCustomerInvoice])},
          })
        }
      } catch(e) {
        console.error(e)
      }
    },
    onCompleted: (data: Mutation) => {      
      if (data && data.createCustomerInvoice) {
        let id = data.createCustomerInvoice.id;
        refetch().then(() => {
          history.push(`/customer/${customerId}/invoice/${id}`)
        })
      }
    },
  })

  const onCreateInvoice = () => {
    if (customerId) {
      createInvoice( { variables: { id: customerId }});
    }
  }

  useEffect(() => {
    if (customerId) {
      getInvoices({ variables: { customerId: customerId } })
    }
  }, [customerId, getInvoices])

  let props = {
    customerId,
    loading,
    invoices,
    onCreateInvoice
  }

  return <CustomerInvoiceList {...props} />
}

export default CustomerInvoiceListContainer

import React, { useEffect } from 'react'
import gql from 'graphql-tag'
import { useGetUserLazyQuery } from '../../generated/graphql'
import { useAuth0 } from '../../react-auth0-spa'
import { useHistory, Redirect } from 'react-router-dom'

// eslint-disable-next-line
const USER_EXISTS_QUERY = gql`
  query getUser {
    user {
      id
      authId
    }
  }
`
const ForceCreateAccount: React.FC = () => {
  const { isAuthenticated } = useAuth0()
  const history = useHistory()
  const [getUser, { data }] = useGetUserLazyQuery({
    onError: console.log
  })

  useEffect(() => {
    if (isAuthenticated) {
      getUser()
    }
  }, [isAuthenticated, getUser])

  if (
    history.location.pathname !== '/account' &&
    isAuthenticated &&
    data &&
    data.user == null 
  ) {
    return <Redirect to='/account' />
  } else {
    return <div/>
  }
}

export default ForceCreateAccount

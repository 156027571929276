import React, { useState, useEffect } from 'react'
import {
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Countries from '../../../lib/Countries'
import Grid from '@material-ui/core/Grid'
import { User } from '../../../generated/graphql'
import { BreadcrumbsContext } from '../../controls/BreadcrumbsContext'
import SectionForm from '../controls/SectionForm'
import FormTextField from '../controls/FormTextField'
import CustomFormControl from '../controls/CustomFormControl'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
      textAlign: 'left'
    }
  })
)

interface AccountProps {
  user: Partial<User>
  onUpdateAccount(data: any): Promise<any>
  loading: boolean
}

const AccountForm: React.FC<AccountProps> = ({user, onUpdateAccount, loading}) => {
  const { setBreadcrumbItems } = React.useContext(BreadcrumbsContext)
  const classes = useStyles()
  const [kvkNumber, setKvkNumber] = useState(user.kvkNumber)
  const [vatNumber, setVatNumber] = useState(user.vatNumber)
  const [companyName, setCompanyName] = useState(user.companyName)
  const [firstName, setFirstName] = useState(user.firstName)
  const [lastName, setLastName] = useState(user.lastName)
  const [address, setAddress] = useState(user.address)
  const [postbox, setPostbox] = useState(user.postbox)
  const [postCode, setPostCode] = useState(user.postCode)
  const [city, setCity] = useState(user.city)
  const [country, setCountry] = useState(user.country)
  const [telephone, setTelephone] = useState(user.telephone)
  const [email, setEmail] = useState(user.email)
  const [iban, setIban] = useState(user.iban)
  const [VAT1, setVAT1] = useState((user.VAT1 || 0).toString())
  const [VAT2, setVAT2] = useState((user.VAT2 || 0).toString())
  const [VAT3, setVAT3] = useState((user.VAT3 || 0).toString())
  const [editMode, setEditMode] = useState(false)
  
  React.useEffect(() => {
    setBreadcrumbItems([
      {  name: 'Home', location: '/home' },
      {  name: 'Account', location: '/account' }
    ])
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    loadFields()
    // eslint-disable-next-line
  }, [user])

  const loadFields = () => {
    // eslint-disable-next-line
    setKvkNumber(user.kvkNumber)
    setVatNumber(user.vatNumber)
    setCompanyName(user.companyName)
    setFirstName(user.firstName)
    setLastName(user.lastName)
    setAddress(user.address)
    setPostbox(user.postbox)
    setPostCode(user.postCode)
    setCity(user.city)
    setCountry(user.country)
    setTelephone(user.telephone)
    setEmail(user.email)
    setIban(user.iban)
    setVAT1((user.VAT1 || 0).toString())
    setVAT2((user.VAT2 || 0).toString())
    setVAT3((user.VAT3 || 0).toString())
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    let payload: any = {
      kvkNumber,
      vatNumber,
      companyName,
      firstName,
      lastName,
      address,
      postbox,
      postCode,
      city,
      country,
      telephone,
      email,
      iban,
      VAT1: parseInt(VAT1 || '0'),
      VAT2: parseInt(VAT2 || '0'),
      VAT3: parseInt(VAT3 || '0'),
    };

    onUpdateAccount(payload).then(() => setEditMode(false))
  }

  const onCancelEdit = () => {
    setEditMode(false)
    loadFields()
  }

  return (
    <SectionForm
      title="Account details"
      loading={loading || !user}
      isNew={false}
      editMode={editMode}
      onEdit={() => setEditMode(true)}      
      onCancel={onCancelEdit}
      onSubmit={handleSubmit}
    >
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          required
          label='First Name'
          value={firstName || ''}
          onChange={e => setFirstName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          required
          label='Last name'
          value={lastName || ''}
          onChange={e => setLastName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          required
          label='Company'
          value={companyName || ''}
          onChange={e => setCompanyName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          required
          label='Email'
          value={email || ''}
          onChange={e => setEmail(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          required
          label='Telephone'
          value={telephone || ''}
          onChange={e => setTelephone(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          required
          label='Address'
          value={address || ''}
          onChange={e => setAddress(e.target.value)}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          required
          label='Postal Code'
          value={postCode || ''}
          onChange={e => setPostCode(e.target.value)}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          label='Postbox'
          value={postbox || ''}
          onChange={e => setPostbox(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          required
          label='City'
          value={city || ''}
          onChange={e => setCity(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomFormControl
          readOnly={!editMode}
          variant='outlined'
          className={classes.formControl}
        >
          <InputLabel>Country</InputLabel>
          <Select
            displayEmpty
            value={country || ''}
            onChange={e => setCountry(e.target.value as string)}
          >
            {Countries.map((name, index) => (
              <MenuItem key={index} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </CustomFormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          required
          label='Kvk Number'
          value={kvkNumber || ''}
          onChange={e => setKvkNumber(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          required
          label='VAT Code'
          value={vatNumber || ''}
          onChange={e => setVatNumber(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          required
          label='IBAN'
          value={iban || ''}
          onChange={e => setIban(e.target.value)}
        />
      </Grid>
      <Grid item xs={4} md={2}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          label='VAT 1 (Exempt)'
          value={VAT1 || ''}
          onChange={e => setVAT1(e.target.value)}
        />
      </Grid>
      <Grid item xs={4} md={2}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          label='VAT 2 (Low)'
          value={VAT2 || ''}
          onChange={e => setVAT2(e.target.value)}
        />
      </Grid>
      <Grid item xs={4} md={2}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          label='VAT 3 (High)'
          value={VAT3 || ''}
          onChange={e => setVAT3(e.target.value)}
        />
      </Grid>
    </SectionForm>
  )
}

export default AccountForm

import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  FileCopy as CopyIcon,
 } from '@material-ui/icons'
 import {
  Tooltip,
  IconButton
} from '@material-ui/core'
import { Template, TemplateScope } from '../../../generated/graphql'
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables"
import { useHistory } from 'react-router'
import * as _ from 'lodash'
import { BreadcrumbsContext } from '../../controls/BreadcrumbsContext'
import ConfirmDialog from '../controls/ConfirmDialog'
import { SortedColumn, createColumns, onColumnSortChange, onRowsSelect, defaultTableOptions } from '../../../lib/TableUtils'
import Section from '../controls/Section'

const useStyles = makeStyles(theme =>({
  row: {
    cursor: 'pointer'
  },
  globalScopeRow: {
    '& .MuiTableCell-body': {
      color: theme.palette.text.secondary
    }
  }
}))

interface RowData {
  id: string
  updatedAt: Date
  name: String
  description: String
  scope: TemplateScope
}

function createData(template: Template): RowData {
  return {
    id: template.id,
    updatedAt: template.updatedAt,
    name: template.name || '-',
    description: template.description || '-',
    scope: template.scope,
  }
}

interface TemplateListProps {
  templates: Template[]
  loading: boolean
  onDeleteTemplate(id: string):void
  onCopyTemplate(id: string):void
}

const TemplateList: React.FC<TemplateListProps> = ({
  templates,
  loading,
  onDeleteTemplate,
  onCopyTemplate,
}) => {
  const { setBreadcrumbItems } = React.useContext(BreadcrumbsContext)  
  const [rows, setRows] = React.useState([] as RowData[])
  const [rowsSelected, setRowsSelected] = React.useState([] as number[])
  const [sortedColumn, setSortedColumn] = React.useState({name: '', direction: 'none' } as SortedColumn);
  const classes = useStyles()
  const history = useHistory()

  const columns: MUIDataTableColumnDef[] = createColumns([
    { name: 'name', label: 'Name' },
    { name: 'description', label: 'Description' }
  ], sortedColumn);

  const tableOptions:MUIDataTableOptions = {
    selectableRows: 'single',
    rowsSelected: rowsSelected,
    download: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    onRowClick: (rowData, rowMeta) => {
      let row = rows[rowMeta.dataIndex]
      history.push(`/template/${row.id}`)
    },
    setRowProps: (row, dataIndex) => {      
      let data = rows[dataIndex]
      let classNames = data.scope === TemplateScope.Global ? [classes.row, classes.globalScopeRow].join(' ') : classes.row
      return { className: classNames }
    },
    onColumnSortChange: (column, direction) => onColumnSortChange(column, direction, setSortedColumn),
    customToolbar: () => (
      <React.Fragment>
        <Tooltip title='Add new template'>
          <IconButton onClick={onCreateTemplate}>
            <AddIcon/>
          </IconButton>
        </Tooltip>
      </React.Fragment>
    ),
    onRowsSelect: (current, all) => onRowsSelect(current, all, rowsSelected, setRowsSelected),
    customToolbarSelect: (selectedRows) => {
      let index = (selectedRows as any).data[0].dataIndex;
      let row = rows[index];
      return (
        <div>
          <ConfirmDialog
            customButton={(
            <Tooltip title='Copy template'>
              <IconButton>
                <CopyIcon/>
              </IconButton>
            </Tooltip>
            )}
            title='Copy Template'            
            onAccept={() => handleCopyTemplate(row.id)}
          >
            Do you want to create a new copy of the template{' '}
            <strong>"{row.name}"</strong>?
          </ConfirmDialog>
          {row.scope === TemplateScope.User && (
          <ConfirmDialog
            customButton={(
            <Tooltip title='Delete template'>
              <IconButton>
                <DeleteIcon/>
              </IconButton>
            </Tooltip>
            )}
            title='Delete Template'
            onAccept={() => handleDeleteTemplate(row.id)}
          >
            Are you sure you want to delete the template{' '}
            <strong>"{row.name}"</strong>?
          </ConfirmDialog>
          )}
        </div>
      )
    }
  }

  React.useEffect(() => {
    setBreadcrumbItems([
      {  name: 'Home', location: '/home' },
      {  name: 'Templates', location: '/templates' }
    ])
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (!loading && templates) {
      setRowsSelected([])
      let tableRows = _.sortBy(
        templates.map(x => createData(x)),
        ['name'])
      setRows(tableRows)
    }
  }, [templates, loading])

  const onCreateTemplate = () => {
    history.push('/template/new')
  }

  const handleCopyTemplate = (id: string) => {
    onCopyTemplate(id)
  }

  const handleDeleteTemplate = (id: string) => {
    onDeleteTemplate(id)
  }

  return (
    <Section
      title="Invoice templates"
      infoText="Templates for your invoices: define your own labels and basic layout."
      >

      {loading ? (
        <div>Loading...</div>
      ) : (
        <MUIDataTable
          title=''
          data={rows}
          columns={columns}
          options={{...defaultTableOptions, ...tableOptions}}
        />       
      )}
    </Section>
  )
}

export default TemplateList

import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { Detail, UpdateDetailInput } from '../../../generated/graphql'
import { BreadcrumbsContext } from '../../controls/BreadcrumbsContext'
import SectionForm from '../controls/SectionForm'
import { useHistory } from 'react-router'
import FormTextField from '../controls/FormTextField'

interface DetailFormProps {
  detail: Detail
  onUpdateDetail(data: UpdateDetailInput, id: string | null): Promise<any>
  loading: boolean,
  isNew: boolean,
}

const DetailForm: React.FC<DetailFormProps> = ({ detail, loading, onUpdateDetail, isNew }) => {
  const { setBreadcrumbItems } = React.useContext(BreadcrumbsContext)
  const [name, setName] = useState(detail.name)
  const [description, setDescription] = useState(detail.description)
  const [editMode, setEditMode] = useState(isNew)
  const history = useHistory()

  const detailId = (detail && detail.id) || ''

  React.useEffect(() => {
    let items = [
      {  name: 'Home', location: '/home' },
      {  name: 'Policies', location: '/policies' }
    ]

    if (isNew) {
      items.push({ name: 'New', location: '/policy/new' },)
    } else if (detailId) {
      items.push({ name: detail.name, location: `/policy/${detailId}` })
    }    
    setBreadcrumbItems(items)
    // eslint-disable-next-line
  }, [detailId])
  
  useEffect(() => {
    if (!loading) {
      loadFields()
    }
    // eslint-disable-next-line
  }, [detail, loading])

  const loadFields = () => {
    setName(detail.name)
    setDescription(detail.description)
  }

  const handleSubmit = (event: React.FormEvent) => {    
    event.preventDefault()
    let data = {
      name,
      description,
    }
    
    let p:Promise<any>
    if (isNew && !detail.id) {
      p = onUpdateDetail(data, null)
    } else {
      p = onUpdateDetail(data, detail.id)
    }
    p.then(() => setEditMode(false))
  }

  const onCancelEdit = () => {
    setEditMode(false)
    if (isNew) {
      history.replace('/policies')
    } else {
      loadFields()
    }
  }

  return (
    <SectionForm
      title="Policy details"
      loading={loading || !detail}
      isNew={isNew}
      editMode={editMode}
      onEdit={() => setEditMode(true)}      
      onCancel={onCancelEdit}
      onSubmit={handleSubmit}
    >
      <Grid item xs={12} md={3}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          required
          label='Name'
          value={name || ''}
          onChange={e => setName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <FormTextField
          readOnly={!editMode}
          variant='outlined'
          required
          label='Description'
          value={description || ''}
          onChange={e => setDescription(e.target.value)}
        />
      </Grid>
    </SectionForm>
  )
}

export default DetailForm

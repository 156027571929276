import React, { useEffect } from 'react';
import { useAuth0 } from '../../../react-auth0-spa';
import { useHistory } from 'react-router';

const WelcomePage: React.FC = () => {
  const { isAuthenticated } = useAuth0()
  const history = useHistory()

  useEffect(() => {
    if (isAuthenticated) {
      history.replace('/home')
    }
  }, [isAuthenticated, history])

  return (
    <div style={{ textAlign: 'center'}}>
      <h1>Eco Invoices</h1>
      <p>
        The eco friendly invoicing app        
      </p>
    </div>
  )
}

export default WelcomePage;

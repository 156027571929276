import * as React from 'react'
import { Auth0Provider } from '../../react-auth0-spa'
import configs from '../../auth_config.json'
import { useHistory } from 'react-router-dom'
import Apollo from './Apollo'
import { NotificationProvider } from './NotificationContext'

const config =
  process.env.NODE_ENV === 'production'
    ? configs.production
    : configs.development

const Auth: React.FC = props => {
  const history = useHistory()

  // Redirect callback after Auth0 authentication
  const onRedirectCallback = (appState: any) => {
    let defaultUrl =
      window.location.pathname === '/' ? '/home' : window.location.pathname
    history.push(
      appState && appState.targetUrl ? appState.targetUrl : defaultUrl
    )
  }
  return (
    <NotificationProvider>
      <Auth0Provider
        domain={config.domain}
        client_id={config.clientId}
        auto_login={false}
        redirect_uri={window.location.origin}
        audience={config.audience}
        onRedirectCallback={onRedirectCallback}
      >
        <Apollo>{props.children}</Apollo>
      </Auth0Provider>
    </NotificationProvider>
  )
}

export default Auth

import React from 'react'
import { FormControl, FormControlProps } from '@material-ui/core'
import { withStyles, Theme } from '@material-ui/core/styles'

export interface ICustomFormControlProps {
  readOnly?: boolean
}

export type CustomFormControlProps = ICustomFormControlProps & FormControlProps

export const CustomFormControl: React.FC<CustomFormControlProps> = (props) => {    
  let disabled = props.readOnly
  let required = props.readOnly ? false : props.required
    
  let updatedProps = {
    ...props,
    ...{
      disabled,
      required,
    }
  } as FormControlProps

  const ThemedCustomFormControl = props.readOnly ? withStyles((theme: Theme) => ({
    root: {
      '& label.Mui-disabled': {
        color: theme.palette.text.secondary,
      },
      '& label': {
        backgroundColor: theme.palette.background.paper,
        padding: '0px 4px 0px 4px',
        marginLeft: '-4px',
      },
      '& .MuiSelect-root.Mui-disabled': {
        color: theme.palette.text.primary
      },
      '& .MuiInput-underline:before' : {
        borderBottom: 'none'
      },
      '& fieldset': {
        border: 'none'
      },
      '& .MuiSelect-icon': {
        display: 'none'
      }
    }
  }))(FormControl) : withStyles((theme: Theme) => ({
    root: {
      '& label': {
        backgroundColor: theme.palette.background.paper,
        padding: '0px 4px 0px 4px',
        marginLeft: '-4px',
      },
    }
  }))(FormControl)

  return (
    <ThemedCustomFormControl { ...updatedProps } />
  )
}

export default CustomFormControl

import React, { useEffect, useState } from 'react'
import Iframe from 'santiagorp-react-iframe'
import { makeStyles, Paper } from '@material-ui/core'
import * as Colors from '@material-ui/core/colors'
import { useAuth0 } from '../../../react-auth0-spa'
import Config from '../../../lib/Config'

const widthA4 = 595
const heightA4 = 842
const ratio = 1.2

const useStyles = makeStyles({
  iframe: {
    marginLeft: 'auto',
    marginRight: 'auto',
    border: '0px'
  },
  alignRight: {
    textAlign: 'right'
  },
  invoicePaper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: widthA4 * ratio,
    height: heightA4 * ratio,
    backgroundColor: Colors.yellow[50]
  },
  loading: {
    paddingTop: '66%'
  },
  button: {
    marginLeft: 10
  }
})

interface InvoicePdfPreviewProps {
  id: string
}

const InvoicePdfPreview: React.FC<InvoicePdfPreviewProps> = ({
  id  
}) => {
  const { isAuthenticated, getTokenSilently } = useAuth0()
  const [token, setToken] = useState('')
  const [htmlText, setHtmlText] = useState('')
  const classes = useStyles()

  const previewUrl = `${Config.apiUrl}/invoice/${id}/preview`

  useEffect(() => {
    if (isAuthenticated && !token) {
      getTokenSilently().then((token: string) => {
        setToken(token)
      })
    }
  }, [token, isAuthenticated, getTokenSilently])

  useEffect(() => {
    if (token) {
      var xhr = new XMLHttpRequest()
      xhr.open('GET', previewUrl)
      xhr.onreadystatechange = previewHandler
      xhr.responseType = 'blob'
      xhr.setRequestHeader('Authorization', 'Bearer ' + token)
      xhr.send()
    }
    // eslint-disable-next-line
  }, [token])
  
  function previewHandler(this: XMLHttpRequest) {
    if (this.readyState === this.DONE) {
      if (this.status === 200) {
        let blob = this.response as Blob
        blob.text().then( v => {
          setHtmlText(`data:text/html;charset=utf-8,${v}`)
        })
      } else {
        console.error('Error while getting preview')
      }
    }
  }

  return (
    <Paper elevation={5} className={classes.invoicePaper}>
      {htmlText ? (
        <Iframe
          className={classes.iframe}
          url={previewUrl}
          src={htmlText}
          width='100%'
          height='100%'
          id='myId'
          display='block'
          position='relative'
          sandbox={['allow-same-origin', 'allow-scripts', 'allow-top-navigation']}
        />
      ) : (
        <div className={classes.loading}>Loading...</div>
      )}
    </Paper>
  )
}

export default InvoicePdfPreview

import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { BreadcrumbsContext } from '../../controls/BreadcrumbsContext'
import Section from '../controls/Section'

const useStyles = makeStyles({
  content: {
    margin: '20px 40px',
    textAlign: 'left',
    lineHeight: '2em'
  }
})

const Home: React.FC = () => {
  const classes = useStyles()
  const { setBreadcrumbItems } = React.useContext(BreadcrumbsContext)
  
  React.useEffect(() => {
    setBreadcrumbItems([
      {  name: 'Home', location: '/home' },
    ])
    // eslint-disable-next-line
  }, [])
  
  return (
    <Section title="Home">
      <div className={classes.content}>
        <p>Follow these steps to start sending invoices with Ecoinvoices:</p>

        <ol>
          <li>
            Add new <Link to='/customers'>customers</Link>. This is the first
            step before invoicing them!
          </li>
          <li>
            Add <Link to='/billables'>billables</Link> so you can use them in
            your invoice. A billable is the product or service which you are
            offering to your customers.
          </li>
          <li>
            Add your invoice <Link to='/policies'>policies</Link>. These are
            details or notes attached to each of your invoices. You can reuse
            them between all your invoices!
          </li>
          <li>
            Add <Link to='/templates'>templates</Link>. You can have different
            invoice templates for your customers. In the template you can edit
            the labels from your invoices and its layout elements.
          </li>
          <li>
            Prepare your invoices! Select the desired{' '}
            <Link to='/customers'>customer</Link> and start composing your
            invoice by adding the desired billables and policies to them.
          </li>
        </ol>
      </div>
    </Section>
  )
}

export default Home

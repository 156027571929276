import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  DateTime: any,
};

export type Attachment = {
   __typename?: 'Attachment',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  blob?: Maybe<Scalars['String']>,
  fileName?: Maybe<Scalars['String']>,
  multiPage: Scalars['Boolean'],
  pngPages?: Maybe<Array<Scalars['String']>>,
  size: Scalars['Int'],
  expense: Expense,
  user: User,
};

export type Customer = {
   __typename?: 'Customer',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  companyName?: Maybe<Scalars['String']>,
  vatNumber?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  postbox?: Maybe<Scalars['String']>,
  postCode?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  telephone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  invoices: Array<Invoice>,
  user: User,
};


export type Detail = {
   __typename?: 'Detail',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  name: Scalars['String'],
  description: Scalars['String'],
  tags?: Maybe<Scalars['String']>,
  user: User,
};

export type Expense = {
   __typename?: 'Expense',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  date: Scalars['DateTime'],
  price: Scalars['Float'],
  amount: Scalars['Float'],
  VAT: Scalars['Float'],
  tags?: Maybe<Scalars['String']>,
  name: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  user: User,
  attachments: Array<Attachment>,
};

export type Invoice = {
   __typename?: 'Invoice',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  subject?: Maybe<Scalars['String']>,
  invoiceNumber?: Maybe<Scalars['String']>,
  invoiceDate?: Maybe<Scalars['DateTime']>,
  templateHtml?: Maybe<Scalars['String']>,
  templateData?: Maybe<Scalars['String']>,
  invoiceStatus: InvoiceStatus,
  paidDate?: Maybe<Scalars['DateTime']>,
  senderIban?: Maybe<Scalars['String']>,
  senderCompanyName?: Maybe<Scalars['String']>,
  senderVatNumber?: Maybe<Scalars['String']>,
  senderAddress?: Maybe<Scalars['String']>,
  senderPostbox?: Maybe<Scalars['String']>,
  senderPostCode?: Maybe<Scalars['String']>,
  senderCity?: Maybe<Scalars['String']>,
  senderCountry?: Maybe<Scalars['String']>,
  senderEmail?: Maybe<Scalars['String']>,
  senderTelephone?: Maybe<Scalars['String']>,
  recipientFirstName?: Maybe<Scalars['String']>,
  recipientLastName?: Maybe<Scalars['String']>,
  recipientCompanyName?: Maybe<Scalars['String']>,
  recipientVatNumber?: Maybe<Scalars['String']>,
  recipientAddress?: Maybe<Scalars['String']>,
  recipientPostbox?: Maybe<Scalars['String']>,
  recipientPostCode?: Maybe<Scalars['String']>,
  recipientCity?: Maybe<Scalars['String']>,
  recipientCountry?: Maybe<Scalars['String']>,
  recipientEmail?: Maybe<Scalars['String']>,
  recipientTelephone?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
  user: User,
  customer: Customer,
  template?: Maybe<Template>,
  invoiceItems: Array<InvoiceItem>,
  invoiceDetails: Array<InvoiceDetail>,
};

export type InvoiceDetail = {
   __typename?: 'InvoiceDetail',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  description?: Maybe<Scalars['String']>,
  invoice: Invoice,
  detail?: Maybe<Detail>,
  user: User,
};

export type InvoiceItem = {
   __typename?: 'InvoiceItem',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  price?: Maybe<Scalars['Float']>,
  amount?: Maybe<Scalars['Int']>,
  VAT?: Maybe<Scalars['Float']>,
  description?: Maybe<Scalars['String']>,
  invoice: Invoice,
  item?: Maybe<Item>,
  user: User,
};

export enum InvoiceStatus {
  Draft = 'DRAFT',
  Created = 'CREATED',
  Sent = 'SENT',
  Paid = 'PAID',
  Invalid = 'INVALID'
}

export type Item = {
   __typename?: 'Item',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  name: Scalars['String'],
  description: Scalars['String'],
  price: Scalars['Float'],
  tags?: Maybe<Scalars['String']>,
  user: User,
};

export type Mutation = {
   __typename?: 'Mutation',
  updateUser?: Maybe<User>,
  updateDetail?: Maybe<Detail>,
  deleteDetail?: Maybe<Scalars['String']>,
  updateTemplate?: Maybe<Template>,
  deleteTemplate?: Maybe<Scalars['String']>,
  copyTemplate?: Maybe<Template>,
  updateCustomer?: Maybe<Customer>,
  deleteCustomer?: Maybe<Scalars['String']>,
  updateItem?: Maybe<Item>,
  deleteItem?: Maybe<Scalars['String']>,
  updateExpense?: Maybe<Expense>,
  deleteExpense?: Maybe<Scalars['String']>,
  createAttachment?: Maybe<Attachment>,
  deleteAttachment?: Maybe<Scalars['String']>,
  createCustomerInvoice?: Maybe<Invoice>,
  deleteDraftInvoice?: Maybe<Scalars['String']>,
  updateInvoiceTemplate?: Maybe<Invoice>,
  finalizeInvoice?: Maybe<Invoice>,
  setInvoiceStatus?: Maybe<Invoice>,
  setPaidDate?: Maybe<Invoice>,
  setInvoiceSubject?: Maybe<Invoice>,
  addInvoiceItem?: Maybe<InvoiceItem>,
  updateInvoiceItems?: Maybe<Array<Maybe<InvoiceItem>>>,
  deleteInvoiceItem?: Maybe<Scalars['String']>,
  addInvoiceDetail?: Maybe<InvoiceDetail>,
  deleteInvoiceDetail?: Maybe<Scalars['String']>,
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput
};


export type MutationUpdateDetailArgs = {
  id?: Maybe<Scalars['String']>,
  data: UpdateDetailInput
};


export type MutationDeleteDetailArgs = {
  id: Scalars['String']
};


export type MutationUpdateTemplateArgs = {
  id?: Maybe<Scalars['String']>,
  data: UpdateTemplateInput
};


export type MutationDeleteTemplateArgs = {
  id: Scalars['String']
};


export type MutationCopyTemplateArgs = {
  id: Scalars['String']
};


export type MutationUpdateCustomerArgs = {
  id?: Maybe<Scalars['String']>,
  data: UpdateCustomerInput
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['String']
};


export type MutationUpdateItemArgs = {
  id?: Maybe<Scalars['String']>,
  data: UpdateItemInput
};


export type MutationDeleteItemArgs = {
  id: Scalars['String']
};


export type MutationUpdateExpenseArgs = {
  id?: Maybe<Scalars['String']>,
  data: UpdateExpenseInput
};


export type MutationDeleteExpenseArgs = {
  id: Scalars['String']
};


export type MutationCreateAttachmentArgs = {
  expenseId?: Maybe<Scalars['String']>,
  chunks: Array<Maybe<Scalars['String']>>,
  fileName?: Maybe<Scalars['String']>
};


export type MutationDeleteAttachmentArgs = {
  id?: Maybe<Scalars['String']>
};


export type MutationCreateCustomerInvoiceArgs = {
  id?: Maybe<Scalars['String']>
};


export type MutationDeleteDraftInvoiceArgs = {
  id: Scalars['String']
};


export type MutationUpdateInvoiceTemplateArgs = {
  invoiceId: Scalars['String'],
  templateId: Scalars['String'],
  force?: Maybe<Scalars['Boolean']>
};


export type MutationFinalizeInvoiceArgs = {
  id: Scalars['String']
};


export type MutationSetInvoiceStatusArgs = {
  id: Scalars['String'],
  status: InvoiceStatus
};


export type MutationSetPaidDateArgs = {
  id: Scalars['String'],
  date: Scalars['DateTime']
};


export type MutationSetInvoiceSubjectArgs = {
  id: Scalars['String'],
  subject?: Maybe<Scalars['String']>
};


export type MutationAddInvoiceItemArgs = {
  invoiceId: Scalars['String'],
  itemId: Scalars['String'],
  force?: Maybe<Scalars['Boolean']>
};


export type MutationUpdateInvoiceItemsArgs = {
  invoiceId: Scalars['String'],
  data: Array<UpdateInvoiceItemInput>,
  force?: Maybe<Scalars['Boolean']>
};


export type MutationDeleteInvoiceItemArgs = {
  id: Scalars['String'],
  force?: Maybe<Scalars['Boolean']>
};


export type MutationAddInvoiceDetailArgs = {
  invoiceId: Scalars['String'],
  detailId: Scalars['String'],
  force?: Maybe<Scalars['Boolean']>
};


export type MutationDeleteInvoiceDetailArgs = {
  id: Scalars['String'],
  force?: Maybe<Scalars['Boolean']>
};

export type Query = {
   __typename?: 'Query',
  user?: Maybe<User>,
  customer?: Maybe<Customer>,
  customers: Array<Customer>,
  item?: Maybe<Item>,
  items: Array<Item>,
  detail?: Maybe<Detail>,
  details: Array<Detail>,
  invoice?: Maybe<Invoice>,
  invoices: Array<Invoice>,
  template?: Maybe<Template>,
  templates: Array<Template>,
  expense?: Maybe<Expense>,
  expenses: Array<Expense>,
  attachment?: Maybe<Attachment>,
  attachments: Array<Attachment>,
};


export type QueryCustomerArgs = {
  id: Scalars['String']
};


export type QueryItemArgs = {
  id: Scalars['String']
};


export type QueryDetailArgs = {
  id: Scalars['String']
};


export type QueryInvoiceArgs = {
  id: Scalars['String']
};


export type QueryInvoicesArgs = {
  customerId?: Maybe<Scalars['String']>
};


export type QueryTemplateArgs = {
  id: Scalars['String']
};


export type QueryExpenseArgs = {
  id: Scalars['String']
};


export type QueryAttachmentArgs = {
  id: Scalars['String']
};


export type QueryAttachmentsArgs = {
  expenseId?: Maybe<Scalars['String']>
};

export type Template = {
   __typename?: 'Template',
  id: Scalars['ID'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  html?: Maybe<Scalars['String']>,
  options?: Maybe<Scalars['String']>,
  scope: TemplateScope,
  user: User,
};

export enum TemplateScope {
  User = 'USER',
  Global = 'GLOBAL'
}

export type UpdateCustomerInput = {
  companyName?: Maybe<Scalars['String']>,
  vatNumber?: Maybe<Scalars['String']>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  postbox?: Maybe<Scalars['String']>,
  postCode?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  telephone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
};

export type UpdateDetailInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  tags?: Maybe<Scalars['String']>,
};

export type UpdateExpenseInput = {
  date?: Maybe<Scalars['DateTime']>,
  tags?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  VAT?: Maybe<Scalars['Float']>,
  amount?: Maybe<Scalars['Float']>,
  price?: Maybe<Scalars['Float']>,
};

export type UpdateInvoiceItemInput = {
  id?: Maybe<Scalars['String']>,
  VAT?: Maybe<Scalars['Float']>,
  amount?: Maybe<Scalars['Int']>,
  description?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
};

export type UpdateItemInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Float']>,
  tags?: Maybe<Scalars['String']>,
};

export type UpdateTemplateInput = {
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  options?: Maybe<Scalars['String']>,
};

export type UpdateUserInput = {
  kvkNumber: Scalars['String'],
  vatNumber: Scalars['String'],
  companyName: Scalars['String'],
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  address: Scalars['String'],
  postbox?: Maybe<Scalars['String']>,
  postCode: Scalars['String'],
  city: Scalars['String'],
  country?: Maybe<Scalars['String']>,
  telephone: Scalars['String'],
  email: Scalars['String'],
  iban: Scalars['String'],
  VAT1?: Maybe<Scalars['Float']>,
  VAT2?: Maybe<Scalars['Float']>,
  VAT3?: Maybe<Scalars['Float']>,
};

export type User = {
   __typename?: 'User',
  id: Scalars['ID'],
  authId: Scalars['String'],
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  kvkNumber: Scalars['String'],
  vatNumber: Scalars['String'],
  companyName: Scalars['String'],
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  address: Scalars['String'],
  postbox?: Maybe<Scalars['String']>,
  postCode: Scalars['String'],
  city: Scalars['String'],
  country?: Maybe<Scalars['String']>,
  telephone: Scalars['String'],
  email: Scalars['String'],
  iban: Scalars['String'],
  paymentTime: Scalars['Int'],
  VAT1?: Maybe<Scalars['Float']>,
  VAT2?: Maybe<Scalars['Float']>,
  VAT3?: Maybe<Scalars['Float']>,
  customers: Array<Customer>,
  templates: Array<Template>,
  globalTemplates: Array<Template>,
  invoices: Array<Invoice>,
  items: Array<Item>,
  details: Array<Detail>,
};

export type FinalizeInvoiceMutationVariables = {
  id: Scalars['String']
};


export type FinalizeInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { finalizeInvoice: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'invoiceStatus' | 'invoiceNumber' | 'invoiceDate'>
  )> }
);

export type SetInvoiceStatusMutationVariables = {
  id: Scalars['String'],
  status: InvoiceStatus
};


export type SetInvoiceStatusMutation = (
  { __typename?: 'Mutation' }
  & { setInvoiceStatus: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'invoiceStatus' | 'invoiceNumber'>
  )> }
);

export type GetBasicInvoiceDataQueryVariables = {
  id: Scalars['String']
};


export type GetBasicInvoiceDataQuery = (
  { __typename?: 'Query' }
  & { invoice: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'invoiceStatus' | 'invoiceNumber' | 'paidDate' | 'subject'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'firstName' | 'lastName' | 'companyName'>
    ) }
  )> }
);

export type SetPaidDateMutationVariables = {
  id: Scalars['String'],
  date: Scalars['DateTime']
};


export type SetPaidDateMutation = (
  { __typename?: 'Mutation' }
  & { setPaidDate: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'invoiceStatus' | 'paidDate'>
  )> }
);

export type GetAccountDetailsQueryVariables = {};


export type GetAccountDetailsQuery = (
  { __typename?: 'Query' }
  & { user: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'kvkNumber' | 'vatNumber' | 'companyName' | 'firstName' | 'lastName' | 'address' | 'postbox' | 'postCode' | 'city' | 'country' | 'telephone' | 'email' | 'iban' | 'VAT1' | 'VAT2' | 'VAT3'>
  )> }
);

export type UpdateAccountMutationVariables = {
  data: UpdateUserInput
};


export type UpdateAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'kvkNumber' | 'vatNumber' | 'companyName' | 'firstName' | 'lastName' | 'address' | 'postbox' | 'postCode' | 'city' | 'country' | 'telephone' | 'email' | 'iban' | 'VAT1' | 'VAT2' | 'VAT3'>
  )> }
);

export type GetCustomerQueryVariables = {
  id: Scalars['String']
};


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & { customer: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'companyName' | 'vatNumber' | 'firstName' | 'lastName' | 'address' | 'postbox' | 'postCode' | 'city' | 'country' | 'telephone' | 'email'>
  )> }
);

export type UpdateCustomerMutationVariables = {
  id?: Maybe<Scalars['String']>,
  data: UpdateCustomerInput
};


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'companyName' | 'vatNumber' | 'firstName' | 'lastName' | 'address' | 'postbox' | 'postCode' | 'city' | 'country' | 'telephone' | 'email'>
  )> }
);

export type GetDetailQueryVariables = {
  id: Scalars['String']
};


export type GetDetailQuery = (
  { __typename?: 'Query' }
  & { detail: Maybe<(
    { __typename?: 'Detail' }
    & Pick<Detail, 'id' | 'name' | 'description' | 'tags'>
  )> }
);

export type UpdateDetailMutationVariables = {
  id?: Maybe<Scalars['String']>,
  data: UpdateDetailInput
};


export type UpdateDetailMutation = (
  { __typename?: 'Mutation' }
  & { updateDetail: Maybe<(
    { __typename?: 'Detail' }
    & Pick<Detail, 'id' | 'name' | 'description' | 'tags'>
  )> }
);

export type GetExpenseQueryVariables = {
  id: Scalars['String']
};


export type GetExpenseQuery = (
  { __typename?: 'Query' }
  & { user: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'VAT1' | 'VAT2' | 'VAT3'>
  )>, expense: Maybe<(
    { __typename?: 'Expense' }
    & Pick<Expense, 'id' | 'date' | 'tags' | 'name' | 'description' | 'price' | 'amount' | 'VAT'>
    & { attachments: Array<(
      { __typename?: 'Attachment' }
      & Pick<Attachment, 'id' | 'size'>
    )> }
  )> }
);

export type UpdateExpenseMutationVariables = {
  id?: Maybe<Scalars['String']>,
  data: UpdateExpenseInput
};


export type UpdateExpenseMutation = (
  { __typename?: 'Mutation' }
  & { updateExpense: Maybe<(
    { __typename?: 'Expense' }
    & Pick<Expense, 'id' | 'date' | 'tags' | 'name' | 'description' | 'price' | 'amount' | 'VAT'>
    & { attachments: Array<(
      { __typename?: 'Attachment' }
      & Pick<Attachment, 'id' | 'size'>
    )> }
  )> }
);

export type GetItemQueryVariables = {
  id: Scalars['String']
};


export type GetItemQuery = (
  { __typename?: 'Query' }
  & { item: Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'name' | 'description' | 'price' | 'tags'>
  )> }
);

export type UpdateItemMutationVariables = {
  id?: Maybe<Scalars['String']>,
  data: UpdateItemInput
};


export type UpdateItemMutation = (
  { __typename?: 'Mutation' }
  & { updateItem: Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'name' | 'description' | 'price' | 'tags'>
  )> }
);

export type GetEditInvoiceDataQueryVariables = {
  id: Scalars['String']
};


export type GetEditInvoiceDataQuery = (
  { __typename?: 'Query' }
  & { invoice: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'invoiceStatus' | 'subject'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'firstName' | 'lastName' | 'companyName'>
    ), template: Maybe<(
      { __typename?: 'Template' }
      & Pick<Template, 'id'>
    )>, invoiceItems: Array<(
      { __typename?: 'InvoiceItem' }
      & Pick<InvoiceItem, 'id' | 'description' | 'price' | 'amount' | 'VAT'>
      & { item: Maybe<(
        { __typename?: 'Item' }
        & Pick<Item, 'id' | 'price' | 'description'>
      )> }
    )>, invoiceDetails: Array<(
      { __typename?: 'InvoiceDetail' }
      & Pick<InvoiceDetail, 'id' | 'description'>
      & { detail: Maybe<(
        { __typename?: 'Detail' }
        & Pick<Detail, 'id' | 'description'>
      )> }
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'VAT1' | 'VAT2' | 'VAT3'>
      & { items: Array<(
        { __typename?: 'Item' }
        & Pick<Item, 'id' | 'name' | 'price' | 'description'>
      )>, details: Array<(
        { __typename?: 'Detail' }
        & Pick<Detail, 'id' | 'name' | 'description'>
      )>, templates: Array<(
        { __typename?: 'Template' }
        & Pick<Template, 'id' | 'name'>
      )>, globalTemplates: Array<(
        { __typename?: 'Template' }
        & Pick<Template, 'id' | 'name'>
      )> }
    ) }
  )> }
);

export type AddInvoiceItemMutationVariables = {
  invoiceId: Scalars['String'],
  itemId: Scalars['String'],
  force?: Maybe<Scalars['Boolean']>
};


export type AddInvoiceItemMutation = (
  { __typename?: 'Mutation' }
  & { addInvoiceItem: Maybe<(
    { __typename?: 'InvoiceItem' }
    & Pick<InvoiceItem, 'id' | 'description' | 'price'>
    & { item: Maybe<(
      { __typename?: 'Item' }
      & Pick<Item, 'id' | 'name' | 'description' | 'price'>
    )> }
  )> }
);

export type UpdateInvoiceItemsMutationVariables = {
  invoiceId: Scalars['String'],
  data: Array<UpdateInvoiceItemInput>,
  force?: Maybe<Scalars['Boolean']>
};


export type UpdateInvoiceItemsMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceItems: Maybe<Array<Maybe<(
    { __typename?: 'InvoiceItem' }
    & Pick<InvoiceItem, 'id'>
  )>>> }
);

export type DeleteInvoiceItemMutationVariables = {
  id: Scalars['String'],
  force?: Maybe<Scalars['Boolean']>
};


export type DeleteInvoiceItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteInvoiceItem'>
);

export type AddInvoiceDetailMutationVariables = {
  invoiceId: Scalars['String'],
  detailId: Scalars['String'],
  force?: Maybe<Scalars['Boolean']>
};


export type AddInvoiceDetailMutation = (
  { __typename?: 'Mutation' }
  & { addInvoiceDetail: Maybe<(
    { __typename?: 'InvoiceDetail' }
    & Pick<InvoiceDetail, 'id' | 'description'>
    & { detail: Maybe<(
      { __typename?: 'Detail' }
      & Pick<Detail, 'id' | 'name' | 'description'>
    )> }
  )> }
);

export type DeleteInvoiceDetailMutationVariables = {
  id: Scalars['String'],
  force?: Maybe<Scalars['Boolean']>
};


export type DeleteInvoiceDetailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteInvoiceDetail'>
);

export type UpdateInvoiceTemplateMutationVariables = {
  invoiceId: Scalars['String'],
  templateId: Scalars['String'],
  force?: Maybe<Scalars['Boolean']>
};


export type UpdateInvoiceTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceTemplate: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id'>
  )> }
);

export type SetInvoiceSubjectMutationVariables = {
  id: Scalars['String'],
  subject: Scalars['String']
};


export type SetInvoiceSubjectMutation = (
  { __typename?: 'Mutation' }
  & { setInvoiceSubject: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'subject'>
  )> }
);

export type DeleteDraftInvoiceMutationVariables = {
  id: Scalars['String']
};


export type DeleteDraftInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDraftInvoice'>
);

export type GetTemplateQueryVariables = {
  id: Scalars['String']
};


export type GetTemplateQuery = (
  { __typename?: 'Query' }
  & { template: Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'id' | 'name' | 'description' | 'options' | 'updatedAt' | 'scope'>
  )> }
);

export type UpdateTemplateMutationVariables = {
  id?: Maybe<Scalars['String']>,
  data: UpdateTemplateInput
};


export type UpdateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateTemplate: Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'id' | 'name' | 'description' | 'options' | 'updatedAt' | 'scope'>
  )> }
);

export type GetAttachmentsQueryVariables = {
  expenseId?: Maybe<Scalars['String']>
};


export type GetAttachmentsQuery = (
  { __typename?: 'Query' }
  & { attachments: Array<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'id' | 'createdAt' | 'updatedAt' | 'blob' | 'fileName' | 'size'>
  )> }
);

export type GetAttachmentQueryVariables = {
  id: Scalars['String']
};


export type GetAttachmentQuery = (
  { __typename?: 'Query' }
  & { attachment: Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'id' | 'createdAt' | 'updatedAt' | 'blob' | 'fileName' | 'size' | 'multiPage' | 'pngPages'>
  )> }
);

export type DeleteAttachmentMutationVariables = {
  id: Scalars['String']
};


export type DeleteAttachmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAttachment'>
);

export type CreateAttachmentMutationVariables = {
  expenseId?: Maybe<Scalars['String']>,
  chunks: Array<Maybe<Scalars['String']>>,
  fileName?: Maybe<Scalars['String']>
};


export type CreateAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { createAttachment: Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'id' | 'createdAt' | 'updatedAt' | 'blob' | 'fileName' | 'size' | 'multiPage' | 'pngPages'>
    & { expense: (
      { __typename?: 'Expense' }
      & Pick<Expense, 'id'>
    ) }
  )> }
);

export type CreateInvoiceMutationVariables = {
  id: Scalars['String']
};


export type CreateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createCustomerInvoice: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'invoiceDate' | 'updatedAt' | 'invoiceStatus' | 'invoiceNumber' | 'recipientCompanyName' | 'recipientFirstName' | 'recipientLastName' | 'subject'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'firstName' | 'lastName' | 'companyName'>
    ), invoiceItems: Array<(
      { __typename?: 'InvoiceItem' }
      & Pick<InvoiceItem, 'amount' | 'price' | 'VAT'>
      & { item: Maybe<(
        { __typename?: 'Item' }
        & Pick<Item, 'price'>
      )> }
    )> }
  )> }
);

export type GetCustomersQueryVariables = {};


export type GetCustomersQuery = (
  { __typename?: 'Query' }
  & { customers: Array<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'companyName' | 'firstName' | 'lastName' | 'address' | 'postbox' | 'postCode' | 'city' | 'country' | 'telephone' | 'email'>
  )> }
);

export type DeleteCustomerMutationVariables = {
  id: Scalars['String']
};


export type DeleteCustomerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCustomer'>
);

export type GetDetailsQueryVariables = {};


export type GetDetailsQuery = (
  { __typename?: 'Query' }
  & { details: Array<(
    { __typename?: 'Detail' }
    & Pick<Detail, 'id' | 'name' | 'description' | 'tags'>
  )> }
);

export type DeleteDetailMutationVariables = {
  id: Scalars['String']
};


export type DeleteDetailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDetail'>
);

export type GetExpensesQueryVariables = {};


export type GetExpensesQuery = (
  { __typename?: 'Query' }
  & { expenses: Array<(
    { __typename?: 'Expense' }
    & Pick<Expense, 'id' | 'date' | 'tags' | 'name' | 'description' | 'price' | 'amount' | 'VAT'>
    & { attachments: Array<(
      { __typename?: 'Attachment' }
      & Pick<Attachment, 'id' | 'size'>
    )> }
  )> }
);

export type DeleteExpenseMutationVariables = {
  id: Scalars['String']
};


export type DeleteExpenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteExpense'>
);

export type GetInvoicesQueryVariables = {
  customerId?: Maybe<Scalars['String']>
};


export type GetInvoicesQuery = (
  { __typename?: 'Query' }
  & { invoices: Array<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'invoiceDate' | 'updatedAt' | 'subject' | 'invoiceStatus' | 'paidDate' | 'invoiceNumber' | 'recipientCompanyName' | 'recipientFirstName' | 'recipientLastName'>
    & { customer: (
      { __typename?: 'Customer' }
      & Pick<Customer, 'id' | 'firstName' | 'lastName' | 'companyName'>
    ), invoiceItems: Array<(
      { __typename?: 'InvoiceItem' }
      & Pick<InvoiceItem, 'amount' | 'price' | 'VAT'>
      & { item: Maybe<(
        { __typename?: 'Item' }
        & Pick<Item, 'price'>
      )> }
    )> }
  )> }
);

export type GetItemsQueryVariables = {};


export type GetItemsQuery = (
  { __typename?: 'Query' }
  & { items: Array<(
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'name' | 'description' | 'price' | 'tags'>
  )> }
);

export type DeleteItemMutationVariables = {
  id: Scalars['String']
};


export type DeleteItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteItem'>
);

export type GetTemplatesQueryVariables = {};


export type GetTemplatesQuery = (
  { __typename?: 'Query' }
  & { templates: Array<(
    { __typename?: 'Template' }
    & Pick<Template, 'id' | 'updatedAt' | 'name' | 'description' | 'scope'>
  )> }
);

export type DeleteTemplateMutationVariables = {
  id: Scalars['String']
};


export type DeleteTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTemplate'>
);

export type CopyTemplateMutationVariables = {
  id: Scalars['String']
};


export type CopyTemplateMutation = (
  { __typename?: 'Mutation' }
  & { copyTemplate: Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'id' | 'name' | 'description' | 'options' | 'updatedAt' | 'scope'>
  )> }
);

export type GetUserQueryVariables = {};


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { user: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'authId'>
  )> }
);


export const FinalizeInvoiceDocument = gql`
    mutation finalizeInvoice($id: String!) {
  finalizeInvoice(id: $id) {
    id
    invoiceStatus
    invoiceNumber
    invoiceDate
  }
}
    `;
export type FinalizeInvoiceMutationFn = ApolloReactCommon.MutationFunction<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>;
export type FinalizeInvoiceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>, 'mutation'>;

    export const FinalizeInvoiceComponent = (props: FinalizeInvoiceComponentProps) => (
      <ApolloReactComponents.Mutation<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables> mutation={FinalizeInvoiceDocument} {...props} />
    );
    
export type FinalizeInvoiceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables> | TChildProps;
export function withFinalizeInvoice<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FinalizeInvoiceMutation,
  FinalizeInvoiceMutationVariables,
  FinalizeInvoiceProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables, FinalizeInvoiceProps<TChildProps>>(FinalizeInvoiceDocument, {
      alias: 'finalizeInvoice',
      ...operationOptions
    });
};

/**
 * __useFinalizeInvoiceMutation__
 *
 * To run a mutation, you first call `useFinalizeInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeInvoiceMutation, { data, loading, error }] = useFinalizeInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFinalizeInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>(FinalizeInvoiceDocument, baseOptions);
      }
export type FinalizeInvoiceMutationHookResult = ReturnType<typeof useFinalizeInvoiceMutation>;
export type FinalizeInvoiceMutationResult = ApolloReactCommon.MutationResult<FinalizeInvoiceMutation>;
export type FinalizeInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<FinalizeInvoiceMutation, FinalizeInvoiceMutationVariables>;
export const SetInvoiceStatusDocument = gql`
    mutation setInvoiceStatus($id: String!, $status: InvoiceStatus!) {
  setInvoiceStatus(id: $id, status: $status) {
    id
    invoiceStatus
    invoiceNumber
  }
}
    `;
export type SetInvoiceStatusMutationFn = ApolloReactCommon.MutationFunction<SetInvoiceStatusMutation, SetInvoiceStatusMutationVariables>;
export type SetInvoiceStatusComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetInvoiceStatusMutation, SetInvoiceStatusMutationVariables>, 'mutation'>;

    export const SetInvoiceStatusComponent = (props: SetInvoiceStatusComponentProps) => (
      <ApolloReactComponents.Mutation<SetInvoiceStatusMutation, SetInvoiceStatusMutationVariables> mutation={SetInvoiceStatusDocument} {...props} />
    );
    
export type SetInvoiceStatusProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SetInvoiceStatusMutation, SetInvoiceStatusMutationVariables> | TChildProps;
export function withSetInvoiceStatus<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetInvoiceStatusMutation,
  SetInvoiceStatusMutationVariables,
  SetInvoiceStatusProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SetInvoiceStatusMutation, SetInvoiceStatusMutationVariables, SetInvoiceStatusProps<TChildProps>>(SetInvoiceStatusDocument, {
      alias: 'setInvoiceStatus',
      ...operationOptions
    });
};

/**
 * __useSetInvoiceStatusMutation__
 *
 * To run a mutation, you first call `useSetInvoiceStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInvoiceStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInvoiceStatusMutation, { data, loading, error }] = useSetInvoiceStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetInvoiceStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetInvoiceStatusMutation, SetInvoiceStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<SetInvoiceStatusMutation, SetInvoiceStatusMutationVariables>(SetInvoiceStatusDocument, baseOptions);
      }
export type SetInvoiceStatusMutationHookResult = ReturnType<typeof useSetInvoiceStatusMutation>;
export type SetInvoiceStatusMutationResult = ApolloReactCommon.MutationResult<SetInvoiceStatusMutation>;
export type SetInvoiceStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<SetInvoiceStatusMutation, SetInvoiceStatusMutationVariables>;
export const GetBasicInvoiceDataDocument = gql`
    query getBasicInvoiceData($id: String!) {
  invoice(id: $id) {
    id
    invoiceStatus
    invoiceNumber
    paidDate
    subject
    customer {
      id
      firstName
      lastName
      companyName
    }
  }
}
    `;
export type GetBasicInvoiceDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBasicInvoiceDataQuery, GetBasicInvoiceDataQueryVariables>, 'query'> & ({ variables: GetBasicInvoiceDataQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetBasicInvoiceDataComponent = (props: GetBasicInvoiceDataComponentProps) => (
      <ApolloReactComponents.Query<GetBasicInvoiceDataQuery, GetBasicInvoiceDataQueryVariables> query={GetBasicInvoiceDataDocument} {...props} />
    );
    
export type GetBasicInvoiceDataProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBasicInvoiceDataQuery, GetBasicInvoiceDataQueryVariables> | TChildProps;
export function withGetBasicInvoiceData<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBasicInvoiceDataQuery,
  GetBasicInvoiceDataQueryVariables,
  GetBasicInvoiceDataProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBasicInvoiceDataQuery, GetBasicInvoiceDataQueryVariables, GetBasicInvoiceDataProps<TChildProps>>(GetBasicInvoiceDataDocument, {
      alias: 'getBasicInvoiceData',
      ...operationOptions
    });
};

/**
 * __useGetBasicInvoiceDataQuery__
 *
 * To run a query within a React component, call `useGetBasicInvoiceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasicInvoiceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasicInvoiceDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBasicInvoiceDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBasicInvoiceDataQuery, GetBasicInvoiceDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBasicInvoiceDataQuery, GetBasicInvoiceDataQueryVariables>(GetBasicInvoiceDataDocument, baseOptions);
      }
export function useGetBasicInvoiceDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBasicInvoiceDataQuery, GetBasicInvoiceDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBasicInvoiceDataQuery, GetBasicInvoiceDataQueryVariables>(GetBasicInvoiceDataDocument, baseOptions);
        }
export type GetBasicInvoiceDataQueryHookResult = ReturnType<typeof useGetBasicInvoiceDataQuery>;
export type GetBasicInvoiceDataLazyQueryHookResult = ReturnType<typeof useGetBasicInvoiceDataLazyQuery>;
export type GetBasicInvoiceDataQueryResult = ApolloReactCommon.QueryResult<GetBasicInvoiceDataQuery, GetBasicInvoiceDataQueryVariables>;
export const SetPaidDateDocument = gql`
    mutation setPaidDate($id: String!, $date: DateTime!) {
  setPaidDate(id: $id, date: $date) {
    id
    invoiceStatus
    paidDate
  }
}
    `;
export type SetPaidDateMutationFn = ApolloReactCommon.MutationFunction<SetPaidDateMutation, SetPaidDateMutationVariables>;
export type SetPaidDateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetPaidDateMutation, SetPaidDateMutationVariables>, 'mutation'>;

    export const SetPaidDateComponent = (props: SetPaidDateComponentProps) => (
      <ApolloReactComponents.Mutation<SetPaidDateMutation, SetPaidDateMutationVariables> mutation={SetPaidDateDocument} {...props} />
    );
    
export type SetPaidDateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SetPaidDateMutation, SetPaidDateMutationVariables> | TChildProps;
export function withSetPaidDate<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetPaidDateMutation,
  SetPaidDateMutationVariables,
  SetPaidDateProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SetPaidDateMutation, SetPaidDateMutationVariables, SetPaidDateProps<TChildProps>>(SetPaidDateDocument, {
      alias: 'setPaidDate',
      ...operationOptions
    });
};

/**
 * __useSetPaidDateMutation__
 *
 * To run a mutation, you first call `useSetPaidDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPaidDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPaidDateMutation, { data, loading, error }] = useSetPaidDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useSetPaidDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetPaidDateMutation, SetPaidDateMutationVariables>) {
        return ApolloReactHooks.useMutation<SetPaidDateMutation, SetPaidDateMutationVariables>(SetPaidDateDocument, baseOptions);
      }
export type SetPaidDateMutationHookResult = ReturnType<typeof useSetPaidDateMutation>;
export type SetPaidDateMutationResult = ApolloReactCommon.MutationResult<SetPaidDateMutation>;
export type SetPaidDateMutationOptions = ApolloReactCommon.BaseMutationOptions<SetPaidDateMutation, SetPaidDateMutationVariables>;
export const GetAccountDetailsDocument = gql`
    query getAccountDetails {
  user {
    id
    kvkNumber
    vatNumber
    companyName
    firstName
    lastName
    address
    postbox
    postCode
    city
    country
    telephone
    email
    iban
    VAT1
    VAT2
    VAT3
  }
}
    `;
export type GetAccountDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>, 'query'>;

    export const GetAccountDetailsComponent = (props: GetAccountDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetAccountDetailsQuery, GetAccountDetailsQueryVariables> query={GetAccountDetailsDocument} {...props} />
    );
    
export type GetAccountDetailsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAccountDetailsQuery, GetAccountDetailsQueryVariables> | TChildProps;
export function withGetAccountDetails<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAccountDetailsQuery,
  GetAccountDetailsQueryVariables,
  GetAccountDetailsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAccountDetailsQuery, GetAccountDetailsQueryVariables, GetAccountDetailsProps<TChildProps>>(GetAccountDetailsDocument, {
      alias: 'getAccountDetails',
      ...operationOptions
    });
};

/**
 * __useGetAccountDetailsQuery__
 *
 * To run a query within a React component, call `useGetAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>(GetAccountDetailsDocument, baseOptions);
      }
export function useGetAccountDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>(GetAccountDetailsDocument, baseOptions);
        }
export type GetAccountDetailsQueryHookResult = ReturnType<typeof useGetAccountDetailsQuery>;
export type GetAccountDetailsLazyQueryHookResult = ReturnType<typeof useGetAccountDetailsLazyQuery>;
export type GetAccountDetailsQueryResult = ApolloReactCommon.QueryResult<GetAccountDetailsQuery, GetAccountDetailsQueryVariables>;
export const UpdateAccountDocument = gql`
    mutation UpdateAccount($data: UpdateUserInput!) {
  updateUser(data: $data) {
    id
    kvkNumber
    vatNumber
    companyName
    firstName
    lastName
    address
    postbox
    postCode
    city
    country
    telephone
    email
    iban
    VAT1
    VAT2
    VAT3
  }
}
    `;
export type UpdateAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;
export type UpdateAccountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateAccountMutation, UpdateAccountMutationVariables>, 'mutation'>;

    export const UpdateAccountComponent = (props: UpdateAccountComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateAccountMutation, UpdateAccountMutationVariables> mutation={UpdateAccountDocument} {...props} />
    );
    
export type UpdateAccountProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateAccountMutation, UpdateAccountMutationVariables> | TChildProps;
export function withUpdateAccount<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
  UpdateAccountProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateAccountMutation, UpdateAccountMutationVariables, UpdateAccountProps<TChildProps>>(UpdateAccountDocument, {
      alias: 'updateAccount',
      ...operationOptions
    });
};

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, baseOptions);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = ApolloReactCommon.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const GetCustomerDocument = gql`
    query getCustomer($id: String!) {
  customer(id: $id) {
    id
    companyName
    vatNumber
    firstName
    lastName
    address
    postbox
    postCode
    city
    country
    telephone
    email
  }
}
    `;
export type GetCustomerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCustomerQuery, GetCustomerQueryVariables>, 'query'> & ({ variables: GetCustomerQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCustomerComponent = (props: GetCustomerComponentProps) => (
      <ApolloReactComponents.Query<GetCustomerQuery, GetCustomerQueryVariables> query={GetCustomerDocument} {...props} />
    );
    
export type GetCustomerProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCustomerQuery, GetCustomerQueryVariables> | TChildProps;
export function withGetCustomer<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCustomerQuery,
  GetCustomerQueryVariables,
  GetCustomerProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCustomerQuery, GetCustomerQueryVariables, GetCustomerProps<TChildProps>>(GetCustomerDocument, {
      alias: 'getCustomer',
      ...operationOptions
    });
};

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
      }
export function useGetCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = ApolloReactCommon.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($id: String, $data: UpdateCustomerInput!) {
  updateCustomer(id: $id, data: $data) {
    id
    companyName
    vatNumber
    firstName
    lastName
    address
    postbox
    postCode
    city
    country
    telephone
    email
  }
}
    `;
export type UpdateCustomerMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export type UpdateCustomerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>, 'mutation'>;

    export const UpdateCustomerComponent = (props: UpdateCustomerComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCustomerMutation, UpdateCustomerMutationVariables> mutation={UpdateCustomerDocument} {...props} />
    );
    
export type UpdateCustomerProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateCustomerMutation, UpdateCustomerMutationVariables> | TChildProps;
export function withUpdateCustomer<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables,
  UpdateCustomerProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCustomerMutation, UpdateCustomerMutationVariables, UpdateCustomerProps<TChildProps>>(UpdateCustomerDocument, {
      alias: 'updateCustomer',
      ...operationOptions
    });
};

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, baseOptions);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const GetDetailDocument = gql`
    query getDetail($id: String!) {
  detail(id: $id) {
    id
    name
    description
    tags
  }
}
    `;
export type GetDetailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDetailQuery, GetDetailQueryVariables>, 'query'> & ({ variables: GetDetailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetDetailComponent = (props: GetDetailComponentProps) => (
      <ApolloReactComponents.Query<GetDetailQuery, GetDetailQueryVariables> query={GetDetailDocument} {...props} />
    );
    
export type GetDetailProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetDetailQuery, GetDetailQueryVariables> | TChildProps;
export function withGetDetail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDetailQuery,
  GetDetailQueryVariables,
  GetDetailProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetDetailQuery, GetDetailQueryVariables, GetDetailProps<TChildProps>>(GetDetailDocument, {
      alias: 'getDetail',
      ...operationOptions
    });
};

/**
 * __useGetDetailQuery__
 *
 * To run a query within a React component, call `useGetDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDetailQuery, GetDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDetailQuery, GetDetailQueryVariables>(GetDetailDocument, baseOptions);
      }
export function useGetDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDetailQuery, GetDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDetailQuery, GetDetailQueryVariables>(GetDetailDocument, baseOptions);
        }
export type GetDetailQueryHookResult = ReturnType<typeof useGetDetailQuery>;
export type GetDetailLazyQueryHookResult = ReturnType<typeof useGetDetailLazyQuery>;
export type GetDetailQueryResult = ApolloReactCommon.QueryResult<GetDetailQuery, GetDetailQueryVariables>;
export const UpdateDetailDocument = gql`
    mutation updateDetail($id: String, $data: UpdateDetailInput!) {
  updateDetail(id: $id, data: $data) {
    id
    name
    description
    tags
  }
}
    `;
export type UpdateDetailMutationFn = ApolloReactCommon.MutationFunction<UpdateDetailMutation, UpdateDetailMutationVariables>;
export type UpdateDetailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateDetailMutation, UpdateDetailMutationVariables>, 'mutation'>;

    export const UpdateDetailComponent = (props: UpdateDetailComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateDetailMutation, UpdateDetailMutationVariables> mutation={UpdateDetailDocument} {...props} />
    );
    
export type UpdateDetailProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateDetailMutation, UpdateDetailMutationVariables> | TChildProps;
export function withUpdateDetail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateDetailMutation,
  UpdateDetailMutationVariables,
  UpdateDetailProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateDetailMutation, UpdateDetailMutationVariables, UpdateDetailProps<TChildProps>>(UpdateDetailDocument, {
      alias: 'updateDetail',
      ...operationOptions
    });
};

/**
 * __useUpdateDetailMutation__
 *
 * To run a mutation, you first call `useUpdateDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDetailMutation, { data, loading, error }] = useUpdateDetailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDetailMutation, UpdateDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDetailMutation, UpdateDetailMutationVariables>(UpdateDetailDocument, baseOptions);
      }
export type UpdateDetailMutationHookResult = ReturnType<typeof useUpdateDetailMutation>;
export type UpdateDetailMutationResult = ApolloReactCommon.MutationResult<UpdateDetailMutation>;
export type UpdateDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDetailMutation, UpdateDetailMutationVariables>;
export const GetExpenseDocument = gql`
    query getExpense($id: String!) {
  user {
    id
    VAT1
    VAT2
    VAT3
  }
  expense(id: $id) {
    id
    date
    tags
    name
    description
    price
    amount
    VAT
    attachments {
      id
      size
    }
  }
}
    `;
export type GetExpenseComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetExpenseQuery, GetExpenseQueryVariables>, 'query'> & ({ variables: GetExpenseQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetExpenseComponent = (props: GetExpenseComponentProps) => (
      <ApolloReactComponents.Query<GetExpenseQuery, GetExpenseQueryVariables> query={GetExpenseDocument} {...props} />
    );
    
export type GetExpenseProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetExpenseQuery, GetExpenseQueryVariables> | TChildProps;
export function withGetExpense<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetExpenseQuery,
  GetExpenseQueryVariables,
  GetExpenseProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetExpenseQuery, GetExpenseQueryVariables, GetExpenseProps<TChildProps>>(GetExpenseDocument, {
      alias: 'getExpense',
      ...operationOptions
    });
};

/**
 * __useGetExpenseQuery__
 *
 * To run a query within a React component, call `useGetExpenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExpenseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExpenseQuery, GetExpenseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExpenseQuery, GetExpenseQueryVariables>(GetExpenseDocument, baseOptions);
      }
export function useGetExpenseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExpenseQuery, GetExpenseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExpenseQuery, GetExpenseQueryVariables>(GetExpenseDocument, baseOptions);
        }
export type GetExpenseQueryHookResult = ReturnType<typeof useGetExpenseQuery>;
export type GetExpenseLazyQueryHookResult = ReturnType<typeof useGetExpenseLazyQuery>;
export type GetExpenseQueryResult = ApolloReactCommon.QueryResult<GetExpenseQuery, GetExpenseQueryVariables>;
export const UpdateExpenseDocument = gql`
    mutation updateExpense($id: String, $data: UpdateExpenseInput!) {
  updateExpense(id: $id, data: $data) {
    id
    date
    tags
    name
    description
    price
    amount
    VAT
    attachments {
      id
      size
    }
  }
}
    `;
export type UpdateExpenseMutationFn = ApolloReactCommon.MutationFunction<UpdateExpenseMutation, UpdateExpenseMutationVariables>;
export type UpdateExpenseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateExpenseMutation, UpdateExpenseMutationVariables>, 'mutation'>;

    export const UpdateExpenseComponent = (props: UpdateExpenseComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateExpenseMutation, UpdateExpenseMutationVariables> mutation={UpdateExpenseDocument} {...props} />
    );
    
export type UpdateExpenseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateExpenseMutation, UpdateExpenseMutationVariables> | TChildProps;
export function withUpdateExpense<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateExpenseMutation,
  UpdateExpenseMutationVariables,
  UpdateExpenseProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateExpenseMutation, UpdateExpenseMutationVariables, UpdateExpenseProps<TChildProps>>(UpdateExpenseDocument, {
      alias: 'updateExpense',
      ...operationOptions
    });
};

/**
 * __useUpdateExpenseMutation__
 *
 * To run a mutation, you first call `useUpdateExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpenseMutation, { data, loading, error }] = useUpdateExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateExpenseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateExpenseMutation, UpdateExpenseMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateExpenseMutation, UpdateExpenseMutationVariables>(UpdateExpenseDocument, baseOptions);
      }
export type UpdateExpenseMutationHookResult = ReturnType<typeof useUpdateExpenseMutation>;
export type UpdateExpenseMutationResult = ApolloReactCommon.MutationResult<UpdateExpenseMutation>;
export type UpdateExpenseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateExpenseMutation, UpdateExpenseMutationVariables>;
export const GetItemDocument = gql`
    query getItem($id: String!) {
  item(id: $id) {
    id
    name
    description
    price
    tags
  }
}
    `;
export type GetItemComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetItemQuery, GetItemQueryVariables>, 'query'> & ({ variables: GetItemQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetItemComponent = (props: GetItemComponentProps) => (
      <ApolloReactComponents.Query<GetItemQuery, GetItemQueryVariables> query={GetItemDocument} {...props} />
    );
    
export type GetItemProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetItemQuery, GetItemQueryVariables> | TChildProps;
export function withGetItem<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetItemQuery,
  GetItemQueryVariables,
  GetItemProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetItemQuery, GetItemQueryVariables, GetItemProps<TChildProps>>(GetItemDocument, {
      alias: 'getItem',
      ...operationOptions
    });
};

/**
 * __useGetItemQuery__
 *
 * To run a query within a React component, call `useGetItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, baseOptions);
      }
export function useGetItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, baseOptions);
        }
export type GetItemQueryHookResult = ReturnType<typeof useGetItemQuery>;
export type GetItemLazyQueryHookResult = ReturnType<typeof useGetItemLazyQuery>;
export type GetItemQueryResult = ApolloReactCommon.QueryResult<GetItemQuery, GetItemQueryVariables>;
export const UpdateItemDocument = gql`
    mutation updateItem($id: String, $data: UpdateItemInput!) {
  updateItem(id: $id, data: $data) {
    id
    name
    description
    price
    tags
  }
}
    `;
export type UpdateItemMutationFn = ApolloReactCommon.MutationFunction<UpdateItemMutation, UpdateItemMutationVariables>;
export type UpdateItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateItemMutation, UpdateItemMutationVariables>, 'mutation'>;

    export const UpdateItemComponent = (props: UpdateItemComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateItemMutation, UpdateItemMutationVariables> mutation={UpdateItemDocument} {...props} />
    );
    
export type UpdateItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateItemMutation, UpdateItemMutationVariables> | TChildProps;
export function withUpdateItem<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateItemMutation,
  UpdateItemMutationVariables,
  UpdateItemProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateItemMutation, UpdateItemMutationVariables, UpdateItemProps<TChildProps>>(UpdateItemDocument, {
      alias: 'updateItem',
      ...operationOptions
    });
};

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateItemMutation, UpdateItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(UpdateItemDocument, baseOptions);
      }
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = ApolloReactCommon.MutationResult<UpdateItemMutation>;
export type UpdateItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateItemMutation, UpdateItemMutationVariables>;
export const GetEditInvoiceDataDocument = gql`
    query getEditInvoiceData($id: String!) {
  invoice(id: $id) {
    id
    invoiceStatus
    subject
    customer {
      id
      firstName
      lastName
      companyName
    }
    template {
      id
    }
    invoiceItems {
      id
      description
      price
      amount
      VAT
      item {
        id
        price
        description
      }
    }
    invoiceDetails {
      id
      description
      detail {
        id
        description
      }
    }
    user {
      VAT1
      VAT2
      VAT3
      items {
        id
        name
        price
        description
      }
      details {
        id
        name
        description
      }
      templates {
        id
        name
      }
      globalTemplates {
        id
        name
      }
    }
  }
}
    `;
export type GetEditInvoiceDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetEditInvoiceDataQuery, GetEditInvoiceDataQueryVariables>, 'query'> & ({ variables: GetEditInvoiceDataQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetEditInvoiceDataComponent = (props: GetEditInvoiceDataComponentProps) => (
      <ApolloReactComponents.Query<GetEditInvoiceDataQuery, GetEditInvoiceDataQueryVariables> query={GetEditInvoiceDataDocument} {...props} />
    );
    
export type GetEditInvoiceDataProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetEditInvoiceDataQuery, GetEditInvoiceDataQueryVariables> | TChildProps;
export function withGetEditInvoiceData<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetEditInvoiceDataQuery,
  GetEditInvoiceDataQueryVariables,
  GetEditInvoiceDataProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetEditInvoiceDataQuery, GetEditInvoiceDataQueryVariables, GetEditInvoiceDataProps<TChildProps>>(GetEditInvoiceDataDocument, {
      alias: 'getEditInvoiceData',
      ...operationOptions
    });
};

/**
 * __useGetEditInvoiceDataQuery__
 *
 * To run a query within a React component, call `useGetEditInvoiceDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditInvoiceDataQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditInvoiceDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEditInvoiceDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEditInvoiceDataQuery, GetEditInvoiceDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEditInvoiceDataQuery, GetEditInvoiceDataQueryVariables>(GetEditInvoiceDataDocument, baseOptions);
      }
export function useGetEditInvoiceDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEditInvoiceDataQuery, GetEditInvoiceDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEditInvoiceDataQuery, GetEditInvoiceDataQueryVariables>(GetEditInvoiceDataDocument, baseOptions);
        }
export type GetEditInvoiceDataQueryHookResult = ReturnType<typeof useGetEditInvoiceDataQuery>;
export type GetEditInvoiceDataLazyQueryHookResult = ReturnType<typeof useGetEditInvoiceDataLazyQuery>;
export type GetEditInvoiceDataQueryResult = ApolloReactCommon.QueryResult<GetEditInvoiceDataQuery, GetEditInvoiceDataQueryVariables>;
export const AddInvoiceItemDocument = gql`
    mutation addInvoiceItem($invoiceId: String!, $itemId: String!, $force: Boolean) {
  addInvoiceItem(invoiceId: $invoiceId, itemId: $itemId, force: $force) {
    id
    description
    price
    item {
      id
      name
      description
      price
    }
  }
}
    `;
export type AddInvoiceItemMutationFn = ApolloReactCommon.MutationFunction<AddInvoiceItemMutation, AddInvoiceItemMutationVariables>;
export type AddInvoiceItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddInvoiceItemMutation, AddInvoiceItemMutationVariables>, 'mutation'>;

    export const AddInvoiceItemComponent = (props: AddInvoiceItemComponentProps) => (
      <ApolloReactComponents.Mutation<AddInvoiceItemMutation, AddInvoiceItemMutationVariables> mutation={AddInvoiceItemDocument} {...props} />
    );
    
export type AddInvoiceItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddInvoiceItemMutation, AddInvoiceItemMutationVariables> | TChildProps;
export function withAddInvoiceItem<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddInvoiceItemMutation,
  AddInvoiceItemMutationVariables,
  AddInvoiceItemProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddInvoiceItemMutation, AddInvoiceItemMutationVariables, AddInvoiceItemProps<TChildProps>>(AddInvoiceItemDocument, {
      alias: 'addInvoiceItem',
      ...operationOptions
    });
};

/**
 * __useAddInvoiceItemMutation__
 *
 * To run a mutation, you first call `useAddInvoiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvoiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvoiceItemMutation, { data, loading, error }] = useAddInvoiceItemMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      itemId: // value for 'itemId'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useAddInvoiceItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddInvoiceItemMutation, AddInvoiceItemMutationVariables>) {
        return ApolloReactHooks.useMutation<AddInvoiceItemMutation, AddInvoiceItemMutationVariables>(AddInvoiceItemDocument, baseOptions);
      }
export type AddInvoiceItemMutationHookResult = ReturnType<typeof useAddInvoiceItemMutation>;
export type AddInvoiceItemMutationResult = ApolloReactCommon.MutationResult<AddInvoiceItemMutation>;
export type AddInvoiceItemMutationOptions = ApolloReactCommon.BaseMutationOptions<AddInvoiceItemMutation, AddInvoiceItemMutationVariables>;
export const UpdateInvoiceItemsDocument = gql`
    mutation updateInvoiceItems($invoiceId: String!, $data: [UpdateInvoiceItemInput!]!, $force: Boolean) {
  updateInvoiceItems(invoiceId: $invoiceId, data: $data, force: $force) {
    id
  }
}
    `;
export type UpdateInvoiceItemsMutationFn = ApolloReactCommon.MutationFunction<UpdateInvoiceItemsMutation, UpdateInvoiceItemsMutationVariables>;
export type UpdateInvoiceItemsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateInvoiceItemsMutation, UpdateInvoiceItemsMutationVariables>, 'mutation'>;

    export const UpdateInvoiceItemsComponent = (props: UpdateInvoiceItemsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateInvoiceItemsMutation, UpdateInvoiceItemsMutationVariables> mutation={UpdateInvoiceItemsDocument} {...props} />
    );
    
export type UpdateInvoiceItemsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateInvoiceItemsMutation, UpdateInvoiceItemsMutationVariables> | TChildProps;
export function withUpdateInvoiceItems<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateInvoiceItemsMutation,
  UpdateInvoiceItemsMutationVariables,
  UpdateInvoiceItemsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateInvoiceItemsMutation, UpdateInvoiceItemsMutationVariables, UpdateInvoiceItemsProps<TChildProps>>(UpdateInvoiceItemsDocument, {
      alias: 'updateInvoiceItems',
      ...operationOptions
    });
};

/**
 * __useUpdateInvoiceItemsMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceItemsMutation, { data, loading, error }] = useUpdateInvoiceItemsMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      data: // value for 'data'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useUpdateInvoiceItemsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInvoiceItemsMutation, UpdateInvoiceItemsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInvoiceItemsMutation, UpdateInvoiceItemsMutationVariables>(UpdateInvoiceItemsDocument, baseOptions);
      }
export type UpdateInvoiceItemsMutationHookResult = ReturnType<typeof useUpdateInvoiceItemsMutation>;
export type UpdateInvoiceItemsMutationResult = ApolloReactCommon.MutationResult<UpdateInvoiceItemsMutation>;
export type UpdateInvoiceItemsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInvoiceItemsMutation, UpdateInvoiceItemsMutationVariables>;
export const DeleteInvoiceItemDocument = gql`
    mutation deleteInvoiceItem($id: String!, $force: Boolean) {
  deleteInvoiceItem(id: $id, force: $force)
}
    `;
export type DeleteInvoiceItemMutationFn = ApolloReactCommon.MutationFunction<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables>;
export type DeleteInvoiceItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables>, 'mutation'>;

    export const DeleteInvoiceItemComponent = (props: DeleteInvoiceItemComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables> mutation={DeleteInvoiceItemDocument} {...props} />
    );
    
export type DeleteInvoiceItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables> | TChildProps;
export function withDeleteInvoiceItem<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteInvoiceItemMutation,
  DeleteInvoiceItemMutationVariables,
  DeleteInvoiceItemProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables, DeleteInvoiceItemProps<TChildProps>>(DeleteInvoiceItemDocument, {
      alias: 'deleteInvoiceItem',
      ...operationOptions
    });
};

/**
 * __useDeleteInvoiceItemMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceItemMutation, { data, loading, error }] = useDeleteInvoiceItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useDeleteInvoiceItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables>(DeleteInvoiceItemDocument, baseOptions);
      }
export type DeleteInvoiceItemMutationHookResult = ReturnType<typeof useDeleteInvoiceItemMutation>;
export type DeleteInvoiceItemMutationResult = ApolloReactCommon.MutationResult<DeleteInvoiceItemMutation>;
export type DeleteInvoiceItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInvoiceItemMutation, DeleteInvoiceItemMutationVariables>;
export const AddInvoiceDetailDocument = gql`
    mutation addInvoiceDetail($invoiceId: String!, $detailId: String!, $force: Boolean) {
  addInvoiceDetail(invoiceId: $invoiceId, detailId: $detailId, force: $force) {
    id
    description
    detail {
      id
      name
      description
    }
  }
}
    `;
export type AddInvoiceDetailMutationFn = ApolloReactCommon.MutationFunction<AddInvoiceDetailMutation, AddInvoiceDetailMutationVariables>;
export type AddInvoiceDetailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddInvoiceDetailMutation, AddInvoiceDetailMutationVariables>, 'mutation'>;

    export const AddInvoiceDetailComponent = (props: AddInvoiceDetailComponentProps) => (
      <ApolloReactComponents.Mutation<AddInvoiceDetailMutation, AddInvoiceDetailMutationVariables> mutation={AddInvoiceDetailDocument} {...props} />
    );
    
export type AddInvoiceDetailProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddInvoiceDetailMutation, AddInvoiceDetailMutationVariables> | TChildProps;
export function withAddInvoiceDetail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddInvoiceDetailMutation,
  AddInvoiceDetailMutationVariables,
  AddInvoiceDetailProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddInvoiceDetailMutation, AddInvoiceDetailMutationVariables, AddInvoiceDetailProps<TChildProps>>(AddInvoiceDetailDocument, {
      alias: 'addInvoiceDetail',
      ...operationOptions
    });
};

/**
 * __useAddInvoiceDetailMutation__
 *
 * To run a mutation, you first call `useAddInvoiceDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvoiceDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvoiceDetailMutation, { data, loading, error }] = useAddInvoiceDetailMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      detailId: // value for 'detailId'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useAddInvoiceDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddInvoiceDetailMutation, AddInvoiceDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<AddInvoiceDetailMutation, AddInvoiceDetailMutationVariables>(AddInvoiceDetailDocument, baseOptions);
      }
export type AddInvoiceDetailMutationHookResult = ReturnType<typeof useAddInvoiceDetailMutation>;
export type AddInvoiceDetailMutationResult = ApolloReactCommon.MutationResult<AddInvoiceDetailMutation>;
export type AddInvoiceDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<AddInvoiceDetailMutation, AddInvoiceDetailMutationVariables>;
export const DeleteInvoiceDetailDocument = gql`
    mutation deleteInvoiceDetail($id: String!, $force: Boolean) {
  deleteInvoiceDetail(id: $id, force: $force)
}
    `;
export type DeleteInvoiceDetailMutationFn = ApolloReactCommon.MutationFunction<DeleteInvoiceDetailMutation, DeleteInvoiceDetailMutationVariables>;
export type DeleteInvoiceDetailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteInvoiceDetailMutation, DeleteInvoiceDetailMutationVariables>, 'mutation'>;

    export const DeleteInvoiceDetailComponent = (props: DeleteInvoiceDetailComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteInvoiceDetailMutation, DeleteInvoiceDetailMutationVariables> mutation={DeleteInvoiceDetailDocument} {...props} />
    );
    
export type DeleteInvoiceDetailProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteInvoiceDetailMutation, DeleteInvoiceDetailMutationVariables> | TChildProps;
export function withDeleteInvoiceDetail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteInvoiceDetailMutation,
  DeleteInvoiceDetailMutationVariables,
  DeleteInvoiceDetailProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteInvoiceDetailMutation, DeleteInvoiceDetailMutationVariables, DeleteInvoiceDetailProps<TChildProps>>(DeleteInvoiceDetailDocument, {
      alias: 'deleteInvoiceDetail',
      ...operationOptions
    });
};

/**
 * __useDeleteInvoiceDetailMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceDetailMutation, { data, loading, error }] = useDeleteInvoiceDetailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useDeleteInvoiceDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteInvoiceDetailMutation, DeleteInvoiceDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteInvoiceDetailMutation, DeleteInvoiceDetailMutationVariables>(DeleteInvoiceDetailDocument, baseOptions);
      }
export type DeleteInvoiceDetailMutationHookResult = ReturnType<typeof useDeleteInvoiceDetailMutation>;
export type DeleteInvoiceDetailMutationResult = ApolloReactCommon.MutationResult<DeleteInvoiceDetailMutation>;
export type DeleteInvoiceDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInvoiceDetailMutation, DeleteInvoiceDetailMutationVariables>;
export const UpdateInvoiceTemplateDocument = gql`
    mutation updateInvoiceTemplate($invoiceId: String!, $templateId: String!, $force: Boolean) {
  updateInvoiceTemplate(invoiceId: $invoiceId, templateId: $templateId, force: $force) {
    id
  }
}
    `;
export type UpdateInvoiceTemplateMutationFn = ApolloReactCommon.MutationFunction<UpdateInvoiceTemplateMutation, UpdateInvoiceTemplateMutationVariables>;
export type UpdateInvoiceTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateInvoiceTemplateMutation, UpdateInvoiceTemplateMutationVariables>, 'mutation'>;

    export const UpdateInvoiceTemplateComponent = (props: UpdateInvoiceTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateInvoiceTemplateMutation, UpdateInvoiceTemplateMutationVariables> mutation={UpdateInvoiceTemplateDocument} {...props} />
    );
    
export type UpdateInvoiceTemplateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateInvoiceTemplateMutation, UpdateInvoiceTemplateMutationVariables> | TChildProps;
export function withUpdateInvoiceTemplate<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateInvoiceTemplateMutation,
  UpdateInvoiceTemplateMutationVariables,
  UpdateInvoiceTemplateProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateInvoiceTemplateMutation, UpdateInvoiceTemplateMutationVariables, UpdateInvoiceTemplateProps<TChildProps>>(UpdateInvoiceTemplateDocument, {
      alias: 'updateInvoiceTemplate',
      ...operationOptions
    });
};

/**
 * __useUpdateInvoiceTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceTemplateMutation, { data, loading, error }] = useUpdateInvoiceTemplateMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      templateId: // value for 'templateId'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useUpdateInvoiceTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInvoiceTemplateMutation, UpdateInvoiceTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInvoiceTemplateMutation, UpdateInvoiceTemplateMutationVariables>(UpdateInvoiceTemplateDocument, baseOptions);
      }
export type UpdateInvoiceTemplateMutationHookResult = ReturnType<typeof useUpdateInvoiceTemplateMutation>;
export type UpdateInvoiceTemplateMutationResult = ApolloReactCommon.MutationResult<UpdateInvoiceTemplateMutation>;
export type UpdateInvoiceTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInvoiceTemplateMutation, UpdateInvoiceTemplateMutationVariables>;
export const SetInvoiceSubjectDocument = gql`
    mutation setInvoiceSubject($id: String!, $subject: String!) {
  setInvoiceSubject(id: $id, subject: $subject) {
    id
    subject
  }
}
    `;
export type SetInvoiceSubjectMutationFn = ApolloReactCommon.MutationFunction<SetInvoiceSubjectMutation, SetInvoiceSubjectMutationVariables>;
export type SetInvoiceSubjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetInvoiceSubjectMutation, SetInvoiceSubjectMutationVariables>, 'mutation'>;

    export const SetInvoiceSubjectComponent = (props: SetInvoiceSubjectComponentProps) => (
      <ApolloReactComponents.Mutation<SetInvoiceSubjectMutation, SetInvoiceSubjectMutationVariables> mutation={SetInvoiceSubjectDocument} {...props} />
    );
    
export type SetInvoiceSubjectProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SetInvoiceSubjectMutation, SetInvoiceSubjectMutationVariables> | TChildProps;
export function withSetInvoiceSubject<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetInvoiceSubjectMutation,
  SetInvoiceSubjectMutationVariables,
  SetInvoiceSubjectProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SetInvoiceSubjectMutation, SetInvoiceSubjectMutationVariables, SetInvoiceSubjectProps<TChildProps>>(SetInvoiceSubjectDocument, {
      alias: 'setInvoiceSubject',
      ...operationOptions
    });
};

/**
 * __useSetInvoiceSubjectMutation__
 *
 * To run a mutation, you first call `useSetInvoiceSubjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInvoiceSubjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInvoiceSubjectMutation, { data, loading, error }] = useSetInvoiceSubjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      subject: // value for 'subject'
 *   },
 * });
 */
export function useSetInvoiceSubjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetInvoiceSubjectMutation, SetInvoiceSubjectMutationVariables>) {
        return ApolloReactHooks.useMutation<SetInvoiceSubjectMutation, SetInvoiceSubjectMutationVariables>(SetInvoiceSubjectDocument, baseOptions);
      }
export type SetInvoiceSubjectMutationHookResult = ReturnType<typeof useSetInvoiceSubjectMutation>;
export type SetInvoiceSubjectMutationResult = ApolloReactCommon.MutationResult<SetInvoiceSubjectMutation>;
export type SetInvoiceSubjectMutationOptions = ApolloReactCommon.BaseMutationOptions<SetInvoiceSubjectMutation, SetInvoiceSubjectMutationVariables>;
export const DeleteDraftInvoiceDocument = gql`
    mutation deleteDraftInvoice($id: String!) {
  deleteDraftInvoice(id: $id)
}
    `;
export type DeleteDraftInvoiceMutationFn = ApolloReactCommon.MutationFunction<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>;
export type DeleteDraftInvoiceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>, 'mutation'>;

    export const DeleteDraftInvoiceComponent = (props: DeleteDraftInvoiceComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables> mutation={DeleteDraftInvoiceDocument} {...props} />
    );
    
export type DeleteDraftInvoiceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables> | TChildProps;
export function withDeleteDraftInvoice<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteDraftInvoiceMutation,
  DeleteDraftInvoiceMutationVariables,
  DeleteDraftInvoiceProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables, DeleteDraftInvoiceProps<TChildProps>>(DeleteDraftInvoiceDocument, {
      alias: 'deleteDraftInvoice',
      ...operationOptions
    });
};

/**
 * __useDeleteDraftInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteDraftInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftInvoiceMutation, { data, loading, error }] = useDeleteDraftInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDraftInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>(DeleteDraftInvoiceDocument, baseOptions);
      }
export type DeleteDraftInvoiceMutationHookResult = ReturnType<typeof useDeleteDraftInvoiceMutation>;
export type DeleteDraftInvoiceMutationResult = ApolloReactCommon.MutationResult<DeleteDraftInvoiceMutation>;
export type DeleteDraftInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDraftInvoiceMutation, DeleteDraftInvoiceMutationVariables>;
export const GetTemplateDocument = gql`
    query getTemplate($id: String!) {
  template(id: $id) {
    id
    name
    description
    options
    updatedAt
    scope
  }
}
    `;
export type GetTemplateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTemplateQuery, GetTemplateQueryVariables>, 'query'> & ({ variables: GetTemplateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTemplateComponent = (props: GetTemplateComponentProps) => (
      <ApolloReactComponents.Query<GetTemplateQuery, GetTemplateQueryVariables> query={GetTemplateDocument} {...props} />
    );
    
export type GetTemplateProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTemplateQuery, GetTemplateQueryVariables> | TChildProps;
export function withGetTemplate<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTemplateQuery,
  GetTemplateQueryVariables,
  GetTemplateProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTemplateQuery, GetTemplateQueryVariables, GetTemplateProps<TChildProps>>(GetTemplateDocument, {
      alias: 'getTemplate',
      ...operationOptions
    });
};

/**
 * __useGetTemplateQuery__
 *
 * To run a query within a React component, call `useGetTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTemplateQuery, GetTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTemplateQuery, GetTemplateQueryVariables>(GetTemplateDocument, baseOptions);
      }
export function useGetTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTemplateQuery, GetTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTemplateQuery, GetTemplateQueryVariables>(GetTemplateDocument, baseOptions);
        }
export type GetTemplateQueryHookResult = ReturnType<typeof useGetTemplateQuery>;
export type GetTemplateLazyQueryHookResult = ReturnType<typeof useGetTemplateLazyQuery>;
export type GetTemplateQueryResult = ApolloReactCommon.QueryResult<GetTemplateQuery, GetTemplateQueryVariables>;
export const UpdateTemplateDocument = gql`
    mutation updateTemplate($id: String, $data: UpdateTemplateInput!) {
  updateTemplate(id: $id, data: $data) {
    id
    name
    description
    options
    updatedAt
    scope
  }
}
    `;
export type UpdateTemplateMutationFn = ApolloReactCommon.MutationFunction<UpdateTemplateMutation, UpdateTemplateMutationVariables>;
export type UpdateTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>, 'mutation'>;

    export const UpdateTemplateComponent = (props: UpdateTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateTemplateMutation, UpdateTemplateMutationVariables> mutation={UpdateTemplateDocument} {...props} />
    );
    
export type UpdateTemplateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateTemplateMutation, UpdateTemplateMutationVariables> | TChildProps;
export function withUpdateTemplate<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateTemplateMutation,
  UpdateTemplateMutationVariables,
  UpdateTemplateProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateTemplateMutation, UpdateTemplateMutationVariables, UpdateTemplateProps<TChildProps>>(UpdateTemplateDocument, {
      alias: 'updateTemplate',
      ...operationOptions
    });
};

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateTemplateMutation, UpdateTemplateMutationVariables>(UpdateTemplateDocument, baseOptions);
      }
export type UpdateTemplateMutationHookResult = ReturnType<typeof useUpdateTemplateMutation>;
export type UpdateTemplateMutationResult = ApolloReactCommon.MutationResult<UpdateTemplateMutation>;
export type UpdateTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>;
export const GetAttachmentsDocument = gql`
    query getAttachments($expenseId: String) {
  attachments(expenseId: $expenseId) {
    id
    createdAt
    updatedAt
    blob
    fileName
    size
  }
}
    `;
export type GetAttachmentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAttachmentsQuery, GetAttachmentsQueryVariables>, 'query'>;

    export const GetAttachmentsComponent = (props: GetAttachmentsComponentProps) => (
      <ApolloReactComponents.Query<GetAttachmentsQuery, GetAttachmentsQueryVariables> query={GetAttachmentsDocument} {...props} />
    );
    
export type GetAttachmentsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAttachmentsQuery, GetAttachmentsQueryVariables> | TChildProps;
export function withGetAttachments<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAttachmentsQuery,
  GetAttachmentsQueryVariables,
  GetAttachmentsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAttachmentsQuery, GetAttachmentsQueryVariables, GetAttachmentsProps<TChildProps>>(GetAttachmentsDocument, {
      alias: 'getAttachments',
      ...operationOptions
    });
};

/**
 * __useGetAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentsQuery({
 *   variables: {
 *      expenseId: // value for 'expenseId'
 *   },
 * });
 */
export function useGetAttachmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttachmentsQuery, GetAttachmentsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttachmentsQuery, GetAttachmentsQueryVariables>(GetAttachmentsDocument, baseOptions);
      }
export function useGetAttachmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttachmentsQuery, GetAttachmentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttachmentsQuery, GetAttachmentsQueryVariables>(GetAttachmentsDocument, baseOptions);
        }
export type GetAttachmentsQueryHookResult = ReturnType<typeof useGetAttachmentsQuery>;
export type GetAttachmentsLazyQueryHookResult = ReturnType<typeof useGetAttachmentsLazyQuery>;
export type GetAttachmentsQueryResult = ApolloReactCommon.QueryResult<GetAttachmentsQuery, GetAttachmentsQueryVariables>;
export const GetAttachmentDocument = gql`
    query getAttachment($id: String!) {
  attachment(id: $id) {
    id
    createdAt
    updatedAt
    blob
    fileName
    size
    multiPage
    pngPages
  }
}
    `;
export type GetAttachmentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAttachmentQuery, GetAttachmentQueryVariables>, 'query'> & ({ variables: GetAttachmentQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAttachmentComponent = (props: GetAttachmentComponentProps) => (
      <ApolloReactComponents.Query<GetAttachmentQuery, GetAttachmentQueryVariables> query={GetAttachmentDocument} {...props} />
    );
    
export type GetAttachmentProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetAttachmentQuery, GetAttachmentQueryVariables> | TChildProps;
export function withGetAttachment<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetAttachmentQuery,
  GetAttachmentQueryVariables,
  GetAttachmentProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetAttachmentQuery, GetAttachmentQueryVariables, GetAttachmentProps<TChildProps>>(GetAttachmentDocument, {
      alias: 'getAttachment',
      ...operationOptions
    });
};

/**
 * __useGetAttachmentQuery__
 *
 * To run a query within a React component, call `useGetAttachmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAttachmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAttachmentQuery, GetAttachmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAttachmentQuery, GetAttachmentQueryVariables>(GetAttachmentDocument, baseOptions);
      }
export function useGetAttachmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAttachmentQuery, GetAttachmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAttachmentQuery, GetAttachmentQueryVariables>(GetAttachmentDocument, baseOptions);
        }
export type GetAttachmentQueryHookResult = ReturnType<typeof useGetAttachmentQuery>;
export type GetAttachmentLazyQueryHookResult = ReturnType<typeof useGetAttachmentLazyQuery>;
export type GetAttachmentQueryResult = ApolloReactCommon.QueryResult<GetAttachmentQuery, GetAttachmentQueryVariables>;
export const DeleteAttachmentDocument = gql`
    mutation deleteAttachment($id: String!) {
  deleteAttachment(id: $id)
}
    `;
export type DeleteAttachmentMutationFn = ApolloReactCommon.MutationFunction<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>;
export type DeleteAttachmentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>, 'mutation'>;

    export const DeleteAttachmentComponent = (props: DeleteAttachmentComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAttachmentMutation, DeleteAttachmentMutationVariables> mutation={DeleteAttachmentDocument} {...props} />
    );
    
export type DeleteAttachmentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteAttachmentMutation, DeleteAttachmentMutationVariables> | TChildProps;
export function withDeleteAttachment<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteAttachmentMutation,
  DeleteAttachmentMutationVariables,
  DeleteAttachmentProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteAttachmentMutation, DeleteAttachmentMutationVariables, DeleteAttachmentProps<TChildProps>>(DeleteAttachmentDocument, {
      alias: 'deleteAttachment',
      ...operationOptions
    });
};

/**
 * __useDeleteAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentMutation, { data, loading, error }] = useDeleteAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>(DeleteAttachmentDocument, baseOptions);
      }
export type DeleteAttachmentMutationHookResult = ReturnType<typeof useDeleteAttachmentMutation>;
export type DeleteAttachmentMutationResult = ApolloReactCommon.MutationResult<DeleteAttachmentMutation>;
export type DeleteAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>;
export const CreateAttachmentDocument = gql`
    mutation createAttachment($expenseId: String, $chunks: [String]!, $fileName: String) {
  createAttachment(expenseId: $expenseId, chunks: $chunks, fileName: $fileName) {
    id
    createdAt
    updatedAt
    blob
    fileName
    size
    multiPage
    pngPages
    expense {
      id
    }
  }
}
    `;
export type CreateAttachmentMutationFn = ApolloReactCommon.MutationFunction<CreateAttachmentMutation, CreateAttachmentMutationVariables>;
export type CreateAttachmentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateAttachmentMutation, CreateAttachmentMutationVariables>, 'mutation'>;

    export const CreateAttachmentComponent = (props: CreateAttachmentComponentProps) => (
      <ApolloReactComponents.Mutation<CreateAttachmentMutation, CreateAttachmentMutationVariables> mutation={CreateAttachmentDocument} {...props} />
    );
    
export type CreateAttachmentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateAttachmentMutation, CreateAttachmentMutationVariables> | TChildProps;
export function withCreateAttachment<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateAttachmentMutation,
  CreateAttachmentMutationVariables,
  CreateAttachmentProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateAttachmentMutation, CreateAttachmentMutationVariables, CreateAttachmentProps<TChildProps>>(CreateAttachmentDocument, {
      alias: 'createAttachment',
      ...operationOptions
    });
};

/**
 * __useCreateAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachmentMutation, { data, loading, error }] = useCreateAttachmentMutation({
 *   variables: {
 *      expenseId: // value for 'expenseId'
 *      chunks: // value for 'chunks'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useCreateAttachmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAttachmentMutation, CreateAttachmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAttachmentMutation, CreateAttachmentMutationVariables>(CreateAttachmentDocument, baseOptions);
      }
export type CreateAttachmentMutationHookResult = ReturnType<typeof useCreateAttachmentMutation>;
export type CreateAttachmentMutationResult = ApolloReactCommon.MutationResult<CreateAttachmentMutation>;
export type CreateAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAttachmentMutation, CreateAttachmentMutationVariables>;
export const CreateInvoiceDocument = gql`
    mutation createInvoice($id: String!) {
  createCustomerInvoice(id: $id) {
    id
    invoiceDate
    updatedAt
    invoiceStatus
    invoiceNumber
    recipientCompanyName
    recipientFirstName
    recipientLastName
    subject
    customer {
      id
      firstName
      lastName
      companyName
    }
    invoiceItems {
      amount
      price
      VAT
      item {
        price
      }
    }
  }
}
    `;
export type CreateInvoiceMutationFn = ApolloReactCommon.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export type CreateInvoiceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>, 'mutation'>;

    export const CreateInvoiceComponent = (props: CreateInvoiceComponentProps) => (
      <ApolloReactComponents.Mutation<CreateInvoiceMutation, CreateInvoiceMutationVariables> mutation={CreateInvoiceDocument} {...props} />
    );
    
export type CreateInvoiceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateInvoiceMutation, CreateInvoiceMutationVariables> | TChildProps;
export function withCreateInvoice<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables,
  CreateInvoiceProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateInvoiceMutation, CreateInvoiceMutationVariables, CreateInvoiceProps<TChildProps>>(CreateInvoiceDocument, {
      alias: 'createInvoice',
      ...operationOptions
    });
};

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, baseOptions);
      }
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export const GetCustomersDocument = gql`
    query getCustomers {
  customers {
    id
    companyName
    firstName
    lastName
    address
    postbox
    postCode
    city
    country
    telephone
    email
  }
}
    `;
export type GetCustomersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCustomersQuery, GetCustomersQueryVariables>, 'query'>;

    export const GetCustomersComponent = (props: GetCustomersComponentProps) => (
      <ApolloReactComponents.Query<GetCustomersQuery, GetCustomersQueryVariables> query={GetCustomersDocument} {...props} />
    );
    
export type GetCustomersProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCustomersQuery, GetCustomersQueryVariables> | TChildProps;
export function withGetCustomers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCustomersQuery,
  GetCustomersQueryVariables,
  GetCustomersProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCustomersQuery, GetCustomersQueryVariables, GetCustomersProps<TChildProps>>(GetCustomersDocument, {
      alias: 'getCustomers',
      ...operationOptions
    });
};

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, baseOptions);
      }
export function useGetCustomersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, baseOptions);
        }
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>;
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>;
export type GetCustomersQueryResult = ApolloReactCommon.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>;
export const DeleteCustomerDocument = gql`
    mutation deleteCustomer($id: String!) {
  deleteCustomer(id: $id)
}
    `;
export type DeleteCustomerMutationFn = ApolloReactCommon.MutationFunction<DeleteCustomerMutation, DeleteCustomerMutationVariables>;
export type DeleteCustomerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>, 'mutation'>;

    export const DeleteCustomerComponent = (props: DeleteCustomerComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCustomerMutation, DeleteCustomerMutationVariables> mutation={DeleteCustomerDocument} {...props} />
    );
    
export type DeleteCustomerProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteCustomerMutation, DeleteCustomerMutationVariables> | TChildProps;
export function withDeleteCustomer<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteCustomerMutation,
  DeleteCustomerMutationVariables,
  DeleteCustomerProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteCustomerMutation, DeleteCustomerMutationVariables, DeleteCustomerProps<TChildProps>>(DeleteCustomerDocument, {
      alias: 'deleteCustomer',
      ...operationOptions
    });
};

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(DeleteCustomerDocument, baseOptions);
      }
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>;
export type DeleteCustomerMutationResult = ApolloReactCommon.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>;
export const GetDetailsDocument = gql`
    query getDetails {
  details {
    id
    name
    description
    tags
  }
}
    `;
export type GetDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDetailsQuery, GetDetailsQueryVariables>, 'query'>;

    export const GetDetailsComponent = (props: GetDetailsComponentProps) => (
      <ApolloReactComponents.Query<GetDetailsQuery, GetDetailsQueryVariables> query={GetDetailsDocument} {...props} />
    );
    
export type GetDetailsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetDetailsQuery, GetDetailsQueryVariables> | TChildProps;
export function withGetDetails<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDetailsQuery,
  GetDetailsQueryVariables,
  GetDetailsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetDetailsQuery, GetDetailsQueryVariables, GetDetailsProps<TChildProps>>(GetDetailsDocument, {
      alias: 'getDetails',
      ...operationOptions
    });
};

/**
 * __useGetDetailsQuery__
 *
 * To run a query within a React component, call `useGetDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDetailsQuery, GetDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDetailsQuery, GetDetailsQueryVariables>(GetDetailsDocument, baseOptions);
      }
export function useGetDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDetailsQuery, GetDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDetailsQuery, GetDetailsQueryVariables>(GetDetailsDocument, baseOptions);
        }
export type GetDetailsQueryHookResult = ReturnType<typeof useGetDetailsQuery>;
export type GetDetailsLazyQueryHookResult = ReturnType<typeof useGetDetailsLazyQuery>;
export type GetDetailsQueryResult = ApolloReactCommon.QueryResult<GetDetailsQuery, GetDetailsQueryVariables>;
export const DeleteDetailDocument = gql`
    mutation deleteDetail($id: String!) {
  deleteDetail(id: $id)
}
    `;
export type DeleteDetailMutationFn = ApolloReactCommon.MutationFunction<DeleteDetailMutation, DeleteDetailMutationVariables>;
export type DeleteDetailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteDetailMutation, DeleteDetailMutationVariables>, 'mutation'>;

    export const DeleteDetailComponent = (props: DeleteDetailComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteDetailMutation, DeleteDetailMutationVariables> mutation={DeleteDetailDocument} {...props} />
    );
    
export type DeleteDetailProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteDetailMutation, DeleteDetailMutationVariables> | TChildProps;
export function withDeleteDetail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteDetailMutation,
  DeleteDetailMutationVariables,
  DeleteDetailProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteDetailMutation, DeleteDetailMutationVariables, DeleteDetailProps<TChildProps>>(DeleteDetailDocument, {
      alias: 'deleteDetail',
      ...operationOptions
    });
};

/**
 * __useDeleteDetailMutation__
 *
 * To run a mutation, you first call `useDeleteDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDetailMutation, { data, loading, error }] = useDeleteDetailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDetailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDetailMutation, DeleteDetailMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDetailMutation, DeleteDetailMutationVariables>(DeleteDetailDocument, baseOptions);
      }
export type DeleteDetailMutationHookResult = ReturnType<typeof useDeleteDetailMutation>;
export type DeleteDetailMutationResult = ApolloReactCommon.MutationResult<DeleteDetailMutation>;
export type DeleteDetailMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDetailMutation, DeleteDetailMutationVariables>;
export const GetExpensesDocument = gql`
    query getExpenses {
  expenses {
    id
    date
    tags
    name
    description
    price
    amount
    VAT
    attachments {
      id
      size
    }
  }
}
    `;
export type GetExpensesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetExpensesQuery, GetExpensesQueryVariables>, 'query'>;

    export const GetExpensesComponent = (props: GetExpensesComponentProps) => (
      <ApolloReactComponents.Query<GetExpensesQuery, GetExpensesQueryVariables> query={GetExpensesDocument} {...props} />
    );
    
export type GetExpensesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetExpensesQuery, GetExpensesQueryVariables> | TChildProps;
export function withGetExpenses<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetExpensesQuery,
  GetExpensesQueryVariables,
  GetExpensesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetExpensesQuery, GetExpensesQueryVariables, GetExpensesProps<TChildProps>>(GetExpensesDocument, {
      alias: 'getExpenses',
      ...operationOptions
    });
};

/**
 * __useGetExpensesQuery__
 *
 * To run a query within a React component, call `useGetExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpensesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExpensesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExpensesQuery, GetExpensesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExpensesQuery, GetExpensesQueryVariables>(GetExpensesDocument, baseOptions);
      }
export function useGetExpensesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExpensesQuery, GetExpensesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExpensesQuery, GetExpensesQueryVariables>(GetExpensesDocument, baseOptions);
        }
export type GetExpensesQueryHookResult = ReturnType<typeof useGetExpensesQuery>;
export type GetExpensesLazyQueryHookResult = ReturnType<typeof useGetExpensesLazyQuery>;
export type GetExpensesQueryResult = ApolloReactCommon.QueryResult<GetExpensesQuery, GetExpensesQueryVariables>;
export const DeleteExpenseDocument = gql`
    mutation deleteExpense($id: String!) {
  deleteExpense(id: $id)
}
    `;
export type DeleteExpenseMutationFn = ApolloReactCommon.MutationFunction<DeleteExpenseMutation, DeleteExpenseMutationVariables>;
export type DeleteExpenseComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteExpenseMutation, DeleteExpenseMutationVariables>, 'mutation'>;

    export const DeleteExpenseComponent = (props: DeleteExpenseComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteExpenseMutation, DeleteExpenseMutationVariables> mutation={DeleteExpenseDocument} {...props} />
    );
    
export type DeleteExpenseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteExpenseMutation, DeleteExpenseMutationVariables> | TChildProps;
export function withDeleteExpense<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteExpenseMutation,
  DeleteExpenseMutationVariables,
  DeleteExpenseProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteExpenseMutation, DeleteExpenseMutationVariables, DeleteExpenseProps<TChildProps>>(DeleteExpenseDocument, {
      alias: 'deleteExpense',
      ...operationOptions
    });
};

/**
 * __useDeleteExpenseMutation__
 *
 * To run a mutation, you first call `useDeleteExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseMutation, { data, loading, error }] = useDeleteExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExpenseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteExpenseMutation, DeleteExpenseMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteExpenseMutation, DeleteExpenseMutationVariables>(DeleteExpenseDocument, baseOptions);
      }
export type DeleteExpenseMutationHookResult = ReturnType<typeof useDeleteExpenseMutation>;
export type DeleteExpenseMutationResult = ApolloReactCommon.MutationResult<DeleteExpenseMutation>;
export type DeleteExpenseMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteExpenseMutation, DeleteExpenseMutationVariables>;
export const GetInvoicesDocument = gql`
    query getInvoices($customerId: String) {
  invoices(customerId: $customerId) {
    id
    invoiceDate
    updatedAt
    subject
    invoiceStatus
    paidDate
    invoiceNumber
    recipientCompanyName
    recipientFirstName
    recipientLastName
    customer {
      id
      firstName
      lastName
      companyName
    }
    invoiceItems {
      amount
      price
      VAT
      item {
        price
      }
    }
  }
}
    `;
export type GetInvoicesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInvoicesQuery, GetInvoicesQueryVariables>, 'query'>;

    export const GetInvoicesComponent = (props: GetInvoicesComponentProps) => (
      <ApolloReactComponents.Query<GetInvoicesQuery, GetInvoicesQueryVariables> query={GetInvoicesDocument} {...props} />
    );
    
export type GetInvoicesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInvoicesQuery, GetInvoicesQueryVariables> | TChildProps;
export function withGetInvoices<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInvoicesQuery,
  GetInvoicesQueryVariables,
  GetInvoicesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInvoicesQuery, GetInvoicesQueryVariables, GetInvoicesProps<TChildProps>>(GetInvoicesDocument, {
      alias: 'getInvoices',
      ...operationOptions
    });
};

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetInvoicesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, baseOptions);
      }
export function useGetInvoicesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, baseOptions);
        }
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesQueryResult = ApolloReactCommon.QueryResult<GetInvoicesQuery, GetInvoicesQueryVariables>;
export const GetItemsDocument = gql`
    query getItems {
  items {
    id
    name
    description
    price
    tags
  }
}
    `;
export type GetItemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetItemsQuery, GetItemsQueryVariables>, 'query'>;

    export const GetItemsComponent = (props: GetItemsComponentProps) => (
      <ApolloReactComponents.Query<GetItemsQuery, GetItemsQueryVariables> query={GetItemsDocument} {...props} />
    );
    
export type GetItemsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetItemsQuery, GetItemsQueryVariables> | TChildProps;
export function withGetItems<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetItemsQuery,
  GetItemsQueryVariables,
  GetItemsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetItemsQuery, GetItemsQueryVariables, GetItemsProps<TChildProps>>(GetItemsDocument, {
      alias: 'getItems',
      ...operationOptions
    });
};

/**
 * __useGetItemsQuery__
 *
 * To run a query within a React component, call `useGetItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemsQuery, GetItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemsQuery, GetItemsQueryVariables>(GetItemsDocument, baseOptions);
      }
export function useGetItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemsQuery, GetItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemsQuery, GetItemsQueryVariables>(GetItemsDocument, baseOptions);
        }
export type GetItemsQueryHookResult = ReturnType<typeof useGetItemsQuery>;
export type GetItemsLazyQueryHookResult = ReturnType<typeof useGetItemsLazyQuery>;
export type GetItemsQueryResult = ApolloReactCommon.QueryResult<GetItemsQuery, GetItemsQueryVariables>;
export const DeleteItemDocument = gql`
    mutation deleteItem($id: String!) {
  deleteItem(id: $id)
}
    `;
export type DeleteItemMutationFn = ApolloReactCommon.MutationFunction<DeleteItemMutation, DeleteItemMutationVariables>;
export type DeleteItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteItemMutation, DeleteItemMutationVariables>, 'mutation'>;

    export const DeleteItemComponent = (props: DeleteItemComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteItemMutation, DeleteItemMutationVariables> mutation={DeleteItemDocument} {...props} />
    );
    
export type DeleteItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteItemMutation, DeleteItemMutationVariables> | TChildProps;
export function withDeleteItem<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteItemMutation,
  DeleteItemMutationVariables,
  DeleteItemProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteItemMutation, DeleteItemMutationVariables, DeleteItemProps<TChildProps>>(DeleteItemDocument, {
      alias: 'deleteItem',
      ...operationOptions
    });
};

/**
 * __useDeleteItemMutation__
 *
 * To run a mutation, you first call `useDeleteItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemMutation, { data, loading, error }] = useDeleteItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteItemMutation, DeleteItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteItemMutation, DeleteItemMutationVariables>(DeleteItemDocument, baseOptions);
      }
export type DeleteItemMutationHookResult = ReturnType<typeof useDeleteItemMutation>;
export type DeleteItemMutationResult = ApolloReactCommon.MutationResult<DeleteItemMutation>;
export type DeleteItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteItemMutation, DeleteItemMutationVariables>;
export const GetTemplatesDocument = gql`
    query getTemplates {
  templates {
    id
    updatedAt
    name
    description
    scope
  }
}
    `;
export type GetTemplatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTemplatesQuery, GetTemplatesQueryVariables>, 'query'>;

    export const GetTemplatesComponent = (props: GetTemplatesComponentProps) => (
      <ApolloReactComponents.Query<GetTemplatesQuery, GetTemplatesQueryVariables> query={GetTemplatesDocument} {...props} />
    );
    
export type GetTemplatesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetTemplatesQuery, GetTemplatesQueryVariables> | TChildProps;
export function withGetTemplates<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTemplatesQuery,
  GetTemplatesQueryVariables,
  GetTemplatesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetTemplatesQuery, GetTemplatesQueryVariables, GetTemplatesProps<TChildProps>>(GetTemplatesDocument, {
      alias: 'getTemplates',
      ...operationOptions
    });
};

/**
 * __useGetTemplatesQuery__
 *
 * To run a query within a React component, call `useGetTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, baseOptions);
      }
export function useGetTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, baseOptions);
        }
export type GetTemplatesQueryHookResult = ReturnType<typeof useGetTemplatesQuery>;
export type GetTemplatesLazyQueryHookResult = ReturnType<typeof useGetTemplatesLazyQuery>;
export type GetTemplatesQueryResult = ApolloReactCommon.QueryResult<GetTemplatesQuery, GetTemplatesQueryVariables>;
export const DeleteTemplateDocument = gql`
    mutation deleteTemplate($id: String!) {
  deleteTemplate(id: $id)
}
    `;
export type DeleteTemplateMutationFn = ApolloReactCommon.MutationFunction<DeleteTemplateMutation, DeleteTemplateMutationVariables>;
export type DeleteTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>, 'mutation'>;

    export const DeleteTemplateComponent = (props: DeleteTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteTemplateMutation, DeleteTemplateMutationVariables> mutation={DeleteTemplateDocument} {...props} />
    );
    
export type DeleteTemplateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteTemplateMutation, DeleteTemplateMutationVariables> | TChildProps;
export function withDeleteTemplate<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteTemplateMutation,
  DeleteTemplateMutationVariables,
  DeleteTemplateProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteTemplateMutation, DeleteTemplateMutationVariables, DeleteTemplateProps<TChildProps>>(DeleteTemplateDocument, {
      alias: 'deleteTemplate',
      ...operationOptions
    });
};

/**
 * __useDeleteTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMutation, { data, loading, error }] = useDeleteTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTemplateMutation, DeleteTemplateMutationVariables>(DeleteTemplateDocument, baseOptions);
      }
export type DeleteTemplateMutationHookResult = ReturnType<typeof useDeleteTemplateMutation>;
export type DeleteTemplateMutationResult = ApolloReactCommon.MutationResult<DeleteTemplateMutation>;
export type DeleteTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>;
export const CopyTemplateDocument = gql`
    mutation copyTemplate($id: String!) {
  copyTemplate(id: $id) {
    id
    name
    description
    options
    updatedAt
    scope
  }
}
    `;
export type CopyTemplateMutationFn = ApolloReactCommon.MutationFunction<CopyTemplateMutation, CopyTemplateMutationVariables>;
export type CopyTemplateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CopyTemplateMutation, CopyTemplateMutationVariables>, 'mutation'>;

    export const CopyTemplateComponent = (props: CopyTemplateComponentProps) => (
      <ApolloReactComponents.Mutation<CopyTemplateMutation, CopyTemplateMutationVariables> mutation={CopyTemplateDocument} {...props} />
    );
    
export type CopyTemplateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CopyTemplateMutation, CopyTemplateMutationVariables> | TChildProps;
export function withCopyTemplate<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CopyTemplateMutation,
  CopyTemplateMutationVariables,
  CopyTemplateProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CopyTemplateMutation, CopyTemplateMutationVariables, CopyTemplateProps<TChildProps>>(CopyTemplateDocument, {
      alias: 'copyTemplate',
      ...operationOptions
    });
};

/**
 * __useCopyTemplateMutation__
 *
 * To run a mutation, you first call `useCopyTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTemplateMutation, { data, loading, error }] = useCopyTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCopyTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CopyTemplateMutation, CopyTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CopyTemplateMutation, CopyTemplateMutationVariables>(CopyTemplateDocument, baseOptions);
      }
export type CopyTemplateMutationHookResult = ReturnType<typeof useCopyTemplateMutation>;
export type CopyTemplateMutationResult = ApolloReactCommon.MutationResult<CopyTemplateMutation>;
export type CopyTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CopyTemplateMutation, CopyTemplateMutationVariables>;
export const GetUserDocument = gql`
    query getUser {
  user {
    id
    authId
  }
}
    `;
export type GetUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserQuery, GetUserQueryVariables>, 'query'>;

    export const GetUserComponent = (props: GetUserComponentProps) => (
      <ApolloReactComponents.Query<GetUserQuery, GetUserQueryVariables> query={GetUserDocument} {...props} />
    );
    
export type GetUserProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUserQuery, GetUserQueryVariables> | TChildProps;
export function withGetUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUserQuery,
  GetUserQueryVariables,
  GetUserProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUserQuery, GetUserQueryVariables, GetUserProps<TChildProps>>(GetUserDocument, {
      alias: 'getUser',
      ...operationOptions
    });
};

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import DetailForm from '../../ui/forms/DetailForm'
import {
  Detail,
  UpdateDetailInput,
  useGetDetailLazyQuery,
  useUpdateDetailMutation,
  GetDetailsQuery,
  UpdateDetailMutation,
} from '../../../generated/graphql'
import { useParams, useHistory } from 'react-router'
import { GET_DETAILS_QUERY } from '../lists/DetailListContainer'
import { useNotification } from '../../controls/NotificationContext'
import { deletePrepareInvoiceDetailsCache } from '../../../lib/ApolloHelper'

// eslint-disable-next-line
export const GET_DETAIL_QUERY = gql`
  query getDetail($id: String!) {
    detail(id: $id) {
      id
      name
      description
      tags
    }
  }
`
// eslint-disable-next-line
const UPDATE_DETAIL_MUTATION = gql`
  mutation updateDetail($id: String, $data: UpdateDetailInput!) {
    updateDetail(id: $id, data: $data) {
      id
      name
      description
      tags
    }
  }
`
const DetailFormContainer: React.FC = () => {
  const notifications = useNotification()
  const history = useHistory()
  const { id } = useParams<{id: string}>()
  const [detail, setDetail] = useState({} as Detail)
  const [updateDetail] = useUpdateDetailMutation({
    onError: console.log,
    update( proxy, { data } ) {
      if (id != null || !data || !data.updateDetail) return;
      const cache = proxy.readQuery<GetDetailsQuery>({ query: GET_DETAILS_QUERY })
      try {
        // Invalidate GetEditInvoiceDataQuery Cache
        deletePrepareInvoiceDetailsCache(proxy)
        
        if (cache && cache.details) {
          proxy.writeQuery({
            query: GET_DETAILS_QUERY,
            data: { details: cache.details.concat([data.updateDetail])},
          })
        }
      } catch {}
    },
    onCompleted: (data: UpdateDetailMutation) => {
      if (data && data.updateDetail) {
        notifications.success('Policy data saved')
        let x = data.updateDetail as Detail
        if (!id) {
          history.push(`/policy/${x.id}`)
        } else {
          setDetail(x)
        }
      }
    },
  })
  const [getDetail, { loading }] = useGetDetailLazyQuery({
    onCompleted: (data: any) => {
      setDetail(data.detail)
    },
    onError: console.log
  })  
  const onUpdateDetail = (data: UpdateDetailInput, id: string | null): Promise<any> => {
    return updateDetail({
      variables: {
        id,
        data: data
      }
    })
  }

  useEffect(() => {
    if (id) {
      getDetail({
        variables: {
          id
        }
      })
    }
  }, [id, getDetail])

  let props = {
    detail,
    loading,
    onUpdateDetail,
    isNew: id == null,
  }

  return <DetailForm {...props} />
}

export default DetailFormContainer

import * as React from 'react'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
 } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables"
import {
  Tooltip,
  IconButton
} from '@material-ui/core'
import { Detail } from '../../../generated/graphql'
import { useHistory } from 'react-router'
import * as _ from 'lodash'
import { BreadcrumbsContext } from '../../controls/BreadcrumbsContext'
import ConfirmDialog from '../controls/ConfirmDialog'
import { SortedColumn, createColumns, onColumnSortChange, onRowsSelect, defaultTableOptions } from '../../../lib/TableUtils'
import Section from '../controls/Section'

const useStyles = makeStyles({
  row: {
    cursor: 'pointer'
  },
})

interface RowData {
  id: string,
  name: string
  description: string
}

function createData(
  id: string,
  name: string,
  description: string,
): RowData {
  return {
    id,
    name,
    description,
  }
}

interface DetailListProps {
  details: Detail[]
  loading: boolean,
  onDeleteDetail(id: string): void
}

const DetailList: React.FC<DetailListProps> = ({ details, loading, onDeleteDetail }) => {
  const { setBreadcrumbItems } = React.useContext(BreadcrumbsContext)
  const [rows, setRows] = React.useState([] as RowData[])
  const [rowsSelected, setRowsSelected] = React.useState([] as number[])
  const [sortedColumn, setSortedColumn] = React.useState({name: '', direction: 'none' } as SortedColumn);
  const classes = useStyles()
  const history = useHistory()
  
  const columns: MUIDataTableColumnDef[] = createColumns([
    { name: 'name', label: 'Name' },
    { name: 'description', label: 'Description' }
  ], sortedColumn);

  const tableOptions:MUIDataTableOptions = {
    selectableRows: 'single',
    rowsSelected: rowsSelected,
    download: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    onRowClick: (rowData, rowMeta) => {
      let row = rows[rowMeta.dataIndex]
      history.push(`/policy/${row.id}`)
    },
    setRowProps: (row) => ({ className: classes.row }),
    onColumnSortChange: (column, direction) => onColumnSortChange(column, direction, setSortedColumn),
    customToolbar: () => (
      <React.Fragment>
        <Tooltip title='Add new policy'>
          <IconButton onClick={onCreateDetail}>
            <AddIcon/>
          </IconButton>
        </Tooltip>
      </React.Fragment>
    ),
    onRowsSelect: (current, all) => onRowsSelect(current, all, rowsSelected, setRowsSelected),
    customToolbarSelect: (selectedRows) => {
      let index = (selectedRows as any).data[0].dataIndex;
      let row = rows[index];
      return (
        <React.Fragment>          
          <ConfirmDialog
            customButton={(
            <Tooltip title='Delete policy'>
              <IconButton>
                <DeleteIcon/>
              </IconButton>
            </Tooltip>
            )}
            title='Delete Policy'
            onAccept={() => handleDeleteDetail(row.id)}
          >
            Are you sure you want to delete the policy{' '}
            <strong>"{row.name}"</strong>?
          </ConfirmDialog>
        </React.Fragment>
      )
    }
  }

  React.useEffect(() => {
    setBreadcrumbItems([
      {  name: 'Home', location: '/home' },
      {  name: 'Policies', location: '/policies' }
    ])
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (!loading && details) {
      setRowsSelected([])
      let tableRows =_.sortBy(details.map(x =>
        createData(x.id, x.name, x.description)
      ), ['name'])
      setRows(tableRows);
    }
  }, [details, loading])

  const onCreateDetail = () => {
    history.push('/policy/new')
  }

  const handleDeleteDetail = (id: string) => {
    onDeleteDetail(id)
  }

  return (
    <Section
      title="Policies"
      infoText="Invoice details, notes and rules to be added to your invoices."
    >      
      { loading || !details ? (
        <div>Loading...</div>
      ) : (
        <MUIDataTable
          title=''
          data={rows}
          columns={columns}
          options={{...defaultTableOptions, ...tableOptions}}
        />
      )}
    </Section>
  )
}

export default DetailList

import { Button } from '@material-ui/core'
import * as Colors from '@material-ui/core/colors'
import React, { useRef } from 'react'
import { withStyles, makeStyles, Theme, Color } from '@material-ui/core'


const useStyles = makeStyles({
  button: {
    display: 'inline-flex'
  },
})

interface FileDialog {
  customButton?: React.ReactNode
  customButtonColor?: Color
  label?: string
  buttonClassName?: string
  disabled?: boolean
  buttonVariant?: 'text' | 'outlined' | 'contained'
  buttonColor?: 'inherit' | 'primary' | 'secondary' | 'default'
  onArrayBufferReaded?: (fileName: string, size: number, data: ArrayBuffer) => void,
  accept: string
}

const FileDialog: React.FC<FileDialog> = props => {
  const inputRef = useRef<HTMLInputElement>(null)
  const classes = useStyles()
  
  const handleClickOpen = () => {
    if (props.disabled)
      return;

    inputRef.current?.click()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      let file = event.target.files[0]
      let reader = new FileReader()
      reader.onload = function(event) {
        if (props.onArrayBufferReaded) {
          let arrayBuffer = event.target?.result as ArrayBuffer
          props.onArrayBufferReaded(file.name, file.size, arrayBuffer)
        }
      }
      
      reader.onerror = function(event) {
          console.error("File could not be read! Code " + event.target?.error?.code)
      };
    
      if (props.onArrayBufferReaded) {
        reader.readAsArrayBuffer(file)
      }
    }
  }

  const resetValue = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (event.currentTarget.files?.length) {
      event.currentTarget.value = ''
    }
  }

  var CustomButton:any = Button;
  if (props.customButtonColor) {
    let color = props.customButtonColor || Colors.grey
    CustomButton = withStyles((theme: Theme) => ({
      root: {
        color: theme.palette.getContrastText(color[500]),
        backgroundColor: color[500],
        '&:hover': {
          backgroundColor: color[700],
        },
      },
    }))(Button)
  }

  return (
    <div className={classes.button}>
        { props.customButton ? (
          <span onClick={handleClickOpen}>
            {props.customButton}
          </span>
        ) : (
          <CustomButton
            disabled={props.disabled || false }
            className={props.buttonClassName || ''}
            variant={ props.buttonVariant || 'contained' }
            color={ props.buttonColor || 'primary' }
            onClick={handleClickOpen}
          >
            {props.label || ''}
          </CustomButton>
        )}
      <input
        style={{display: 'none'}}
        type="file"
        ref={inputRef}
        onChange={handleChange}
        onClick={resetValue}
        accept={props.accept} />
    </div>
  )
}

export default FileDialog;
import React, { useState } from 'react'
import gql from 'graphql-tag'
import { 
  useGetExpensesQuery,
  useDeleteExpenseMutation,
  Expense,
  Mutation,
} from '../../../generated/graphql'
import ExpenseList from '../../ui/lists/ExpenseList'
import { useNotification } from '../../controls/NotificationContext'

// eslint-disable-next-line
export const GET_EXPENSES_QUERY = gql`
  query getExpenses {
    expenses {
      id
      date
      tags
      name
      description
      price
      amount
      VAT
      attachments {
        id
        size
      }
    }
  }
`

// eslint-disable-next-line
const DELETE_EXPENSE_MUTATION = gql`
  mutation deleteExpense($id: String!) {
    deleteExpense(id: $id)
  }
`

const ExpenseListContainer: React.FC = () => {
  const notifications = useNotification()
  const [expenses, setExpenses] = useState([] as Expense[])
  const { loading, refetch } = useGetExpensesQuery({
    onCompleted: (data: any) => {
      setExpenses(data.expenses)
    },
    onError: console.log
  })

  const [deleteExpense] = useDeleteExpenseMutation({
    onError: console.log,
    onCompleted: (data: Mutation) => {
      notifications.success('Expense deleted')
      refetch().then(res => {
        if (res && res.data) {
          setExpenses(res.data.expenses as Expense[])
        }
      })
    }
  })

  
  const onDeleteExpense = (id: string) => {
    deleteExpense({
      variables: { id }
    })
  }

  let props = {
    expenses,
    loading,
    onDeleteExpense
  }

  return <ExpenseList {...props} />
}

export default ExpenseListContainer

import React from 'react';
import { useHistory } from 'react-router-dom'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'

import {
  Home as HomeIcon,
  ShoppingCart as ShoppingCartIcon,
  People as PeopleIcon,
  Policy as PolicyIcon,
  DeveloperMode as DeveloperModeIcon,
  Description as DescriptionIcon,
  Settings as SettingsIcon,
  CreditCard as CreditCardIcon,
} from '@material-ui/icons'

const Menu: React.FC = () => {
  let history = useHistory()
  return (
    <div>
      <List>
        <ListItem button onClick={() => history.push('/home')}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary='Home' />
        </ListItem>
        <ListItem button onClick={() => history.push('/invoices')}>
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary='Invoices' />
        </ListItem>
        <ListItem button onClick={() => history.push('/customers')}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary='Customers' />
        </ListItem>
        <ListItem button onClick={() => history.push('/billables')}>
          <ListItemIcon>
            <ShoppingCartIcon/>
          </ListItemIcon>
          <ListItemText primary='Billables' />
        </ListItem>
        <ListItem button onClick={() => history.push('/expenses')}>
          <ListItemIcon>
            <CreditCardIcon />
          </ListItemIcon>
          <ListItemText primary='Expenses' />
        </ListItem>
        <ListItem button onClick={() => history.push('/policies')}>
          <ListItemIcon>
            <PolicyIcon />
          </ListItemIcon>
          <ListItemText primary='Policies' />
        </ListItem>
        <ListItem button onClick={() => history.push('/templates')}>
          <ListItemIcon>
            <DeveloperModeIcon />
          </ListItemIcon>
          <ListItemText primary='Templates' />
        </ListItem>
        <ListItem button onClick={() => history.push('/account')}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary='My Account' />
        </ListItem>
      </List>
    </div>
  )
}

export default Menu

import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import CustomerForm from '../../ui/forms/CustomerForm'
import {
  Customer,
  UpdateCustomerInput,
  useGetCustomerLazyQuery,
  useUpdateCustomerMutation,
  GetCustomersQuery
} from '../../../generated/graphql'
import { useParams, useHistory } from 'react-router'
import { useNotification } from '../../controls/NotificationContext'
import { GET_CUSTOMERS_QUERY } from '../lists/CustomerListContainer'

// eslint-disable-next-line
export const GET_CUSTOMER_QUERY = gql`
  query getCustomer($id: String!) {
    customer(id: $id) {
      id
      companyName
      vatNumber
      firstName
      lastName
      address
      postbox
      postCode
      city
      country
      telephone
      email
    }
  }
`
// eslint-disable-next-line
const UPDATE_CUSTOMER_MUTATION = gql`
  mutation updateCustomer($id: String, $data: UpdateCustomerInput!) {
    updateCustomer(id: $id, data: $data) {
      id
      companyName
      vatNumber
      firstName
      lastName
      address
      postbox
      postCode
      city
      country
      telephone
      email
    }
  }
`
const CustomerFormContainer: React.FC = () => {
  const notifications = useNotification()
  const history = useHistory()
  const { customerId } = useParams<{customerId: string}>()
  const [customer, setCustomer] = useState({ country: 'Netherlands' } as Customer)
  const [updateCustomer] = useUpdateCustomerMutation({
    onError: console.log,
    onCompleted: (data) => {
      if (data && data.updateCustomer) {        
        notifications.success('Customer data saved')
        let x = data.updateCustomer as Customer        
        if (!customerId) {
          history.push(`/customer/${x.id}`)
        } else {
          setCustomer(x)
        }
      }
    },
    update( proxy, { data } ) {
      if (customerId || !data || !data.updateCustomer) return;
      try {
        const cache = proxy.readQuery<GetCustomersQuery>({ query: GET_CUSTOMERS_QUERY })
        if (cache && cache.customers) {
          proxy.writeQuery({
            query: GET_CUSTOMERS_QUERY,
            data: { customers: cache.customers.concat([data.updateCustomer])},
          })
        }
      } catch {}
    }
  })

  const [getCustomer, { loading }] = useGetCustomerLazyQuery({
    onCompleted: (data: any) => {
      setCustomer(data.customer)
    },
    onError: console.log
  })
  const onUpdateCustomer = (data: UpdateCustomerInput, id: string | null): Promise<any> => {
    return updateCustomer({
      variables: {
        id,
        data: data
      }
    })
  }

  useEffect(() => {
    if (customerId) {
      getCustomer({ variables: { id: customerId } })
    }
  }, [customerId, getCustomer])

  let props = {
    customer,
    loading: loading,
    onUpdateCustomer,
    isNew: customerId == null,
  }

  return <CustomerForm {...props} />
}

export default CustomerFormContainer

import { IconButton, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import * as _ from 'lodash'
import moment from 'moment'
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables"
import * as React from 'react'
import { useHistory } from 'react-router'
import { Invoice, InvoiceStatus } from '../../../generated/graphql'
import { calculateTotal } from '../../../lib/invoiceHelpers'
import { SortedColumn, createColumns, onColumnSortChange } from '../../../lib/TableUtils'
import { InvoiceStatusIcon } from '../controls/StatusIcon'
import Section from '../controls/Section'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  row: {
    cursor: 'pointer'
  },
})

interface RowData {
  id: string
  invoiceNumber: string
  invoiceDate: Date
  paidDate?: Date
  status: InvoiceStatus
  amount: number
}

function createData(invoice: Invoice): RowData {
  let amount = calculateTotal(invoice)
  return {
    id: invoice.id,
    invoiceNumber: invoice.invoiceNumber || '-',
    invoiceDate: invoice.invoiceDate || null,
    paidDate: invoice.paidDate,
    status: invoice.invoiceStatus,
    amount
  }
}

interface CustomerInvoiceListProps {
  customerId: string | undefined
  invoices: Invoice[]
  loading: boolean,
  onCreateInvoice(): void,
}

const CustomerInvoiceList: React.FC<CustomerInvoiceListProps> = ({
  customerId,
  invoices,
  loading,
  onCreateInvoice
}) => {
  const [rows, setRows] = React.useState([] as RowData[])
  const [sortedColumn, setSortedColumn] = React.useState({name: '', direction: 'none' } as SortedColumn);
  const classes = useStyles()
  const history = useHistory()

  React.useEffect(() => {
    if (!loading && invoices) {
      let groups = _.partition(invoices, x => !x.invoiceNumber)
      let all = groups[0].concat(_.sortBy(groups[1], ['invoiceNumber']).reverse())
      let tableRows = all.map(x => createData(x))
      setRows(tableRows)
    }
  }, [invoices, loading])

  const columns: MUIDataTableColumnDef[] = createColumns([
    { name: 'invoiceNumber', label: 'Invoice Number' },
    {
      name: 'invoiceDate',
      label: 'Invoice date',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? moment(value).format('LL') : '-'
        },
      }
    },
    {
      name: 'paidDate',
      label: 'Payment date',
      options: {
        customBodyRender: (value) => value ? `${moment(value).format('LL')}` : '-',
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: (value) => (<InvoiceStatusIcon status={value} />)
      }
    },
    {
      name: 'amount',
      label: 'Amount',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({align: 'right'}),
        customBodyRender: (value) => `${value.toFixed(2)} €`,
      },
    }
  ], sortedColumn)

  const tableOptions:MUIDataTableOptions = {
    selectableRows: 'none',
    download: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    onRowClick: (rowData, rowMeta) => {
      let row = rows[rowMeta.dataIndex];
      history.push(`/customer/${customerId}/invoice/${row.id}`)
    },
    setRowProps: (row) => ({ className: classes.row }),
    onColumnSortChange: (column, direction) => onColumnSortChange(column, direction, setSortedColumn),
    customToolbar: () => (
      <React.Fragment>
        <Tooltip title='Prepare new invoice'>
          <IconButton onClick={onPrepareInvoice}>
            <AddIcon/>
          </IconButton>
        </Tooltip>
      </React.Fragment>
    )
  }

  const onPrepareInvoice = () => {
    onCreateInvoice()
  }

  return (
    <Section title="Invoices">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <MUIDataTable
            title=''
            data={rows}
            columns={columns}
            options={tableOptions}
          />
        </div>
      )}
    </Section>
  )
}

export default CustomerInvoiceList

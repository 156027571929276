import React, { useState, useEffect } from 'react'
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { useAuth0 } from '../../react-auth0-spa'
import Config from '../../lib/Config'

// GraphQL API url
const httpLink = createHttpLink({
  uri: Config.apiUrl
})

// Build auth link for GraphQL API calls
function getAuthLink(token: string) {
  return setContext((_, { headers }) => {
    let authorization = token ? `Bearer ${token}` : ''
    return {
      headers: {
        ...headers,
        authorization
      }
    }
  })
}

const Apollo: React.FC = props => {
  const { loading, isAuthenticated, getTokenSilently } = useAuth0()
  const [token, setToken] = useState('')
  const [apolloClient, setApolloClient] = useState(null as any)

  useEffect(() => {
    if (isAuthenticated) {
      getTokenSilently().then((token: string) => {
        setToken(token)
      })
    }
  }, [isAuthenticated, getTokenSilently])

  useEffect(() => {
    if (token) {
      let ac = new ApolloClient({
        link: getAuthLink(token).concat(httpLink),
        cache: new InMemoryCache()
      })
      setApolloClient(ac)
    }
  }, [token])

  if (apolloClient != null) {
    return (
      <ApolloProvider client={apolloClient}>
        {props.children}
      </ApolloProvider>
    )
  } else if (!loading && !isAuthenticated) {
    return (
      <div>
        {props.children}
      </div>
    )
  } else {
    return (
      <div>Loading...</div>
    )
  }
}

export default Apollo

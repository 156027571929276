import React, { useState } from 'react'
import { useNotification } from '../../controls/NotificationContext';
import gql from 'graphql-tag'
import { useGetAccountDetailsQuery, useUpdateAccountMutation } from '../../../generated/graphql'
import AccountForm from '../../ui/forms/AccountForm'

// eslint-disable-next-line
export const GET_USER_QUERY = gql`
  query getAccountDetails {
    user {
      id
      kvkNumber
      vatNumber
      companyName
      firstName
      lastName
      address
      postbox
      postCode
      city
      country
      telephone
      email
      iban
      VAT1
      VAT2
      VAT3
    }
  }
`

// eslint-disable-next-line
const UPDATE_ACCOUT_MUTATION = gql`
  mutation UpdateAccount($data: UpdateUserInput!) {
  updateUser(data: $data) {
    id
    kvkNumber
    vatNumber
    companyName
    firstName
    lastName
    address
    postbox
    postCode
    city
    country
    telephone
    email
    iban
    VAT1
    VAT2
    VAT3
  }
}
`

const AccountFormContainer: React.FC = () => {
  const notifications = useNotification()
  const [updateAccount] = useUpdateAccountMutation({
    onError: console.log,
    onCompleted: () => {
      notifications.success('Account data saved')
    }
  });
  const [user, setUser] = useState({})

  // Load from DB
  var { loading } = useGetAccountDetailsQuery({
    onCompleted: (data: any) => {
      if (data.user) {
        setUser(data.user)
      }
    },
    onError: console.log
  })

  // Save to DB
  const onUpdateAccount = (data: any): Promise<any> => {    
    return updateAccount({
      variables: {
        data: data
      }
    })
  }

  let props = {
      user,
      loading,
      onUpdateAccount,      
  }

  return (
    <AccountForm {...props}/>
  )
}

export default AccountFormContainer

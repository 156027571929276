import React from 'react'

const AboutPage: React.FC = () => {
  return (
    <div>
      <h1>About</h1>
      <p>
        Ecoinvoices is your eco invoicing app: enviromental friendly and
        economical.
      </p>
      <h2>Conditions and terms of use</h2>
      <h4>This software is in alpha stage of development.</h4>
      <p>
        This software is provided "as is", without warranty of any kind, express
        or implied, including but not limited to the warranties of
        merchantability, fitness for a particular purpose and noninfringement.
        In no event shall the authors or copyright holders be liable for any
        claim, damages or other liability, whether in an action of contract,
        tort or otherwise, arising from, out of or in connection with the
        software or the use or other dealings in the software.
      </p>
    </div>
  )
}

export default AboutPage

import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { useAuth0 } from '../../../react-auth0-spa'
import Config from '../../../lib/Config'

interface InvoicePdfDownloadArgs {
  id: string
  label?: string
  buttonVariant?: 'text' | 'outlined' | 'contained'
  buttonColor?: 'inherit' | 'primary' | 'secondary' | 'default'
  onDownloadCompleted?(): void
  fileName?: string
}

const InvoicePdfDownload: React.FC<InvoicePdfDownloadArgs> = ({
  id,
  label,
  buttonVariant,
  buttonColor,
  onDownloadCompleted,
  fileName,
}) => {
  const { isAuthenticated, getTokenSilently } = useAuth0()
  const [token, setToken] = useState('')
  const [pdf, setPdf] = useState('')
  const [pdfRequested, setPdfRequested] = useState(false)
  const downloadLink = React.createRef<HTMLAnchorElement>()

  let pdfUrl = `${Config.apiUrl}/invoice/${id}/pdf`

  useEffect(() => {
    if (isAuthenticated && !token) {
      getTokenSilently().then((token: string) => {
        setToken(token)
      })
    }
  }, [token, isAuthenticated, getTokenSilently])

  useEffect(() => {
    if (pdf) {
      activateDownload()
    }
    // eslint-disable-next-line
  }, [pdf])

  const activateDownload = () => {
    if (downloadLink.current) {      
      downloadLink.current.click()
      if (onDownloadCompleted != null) {
        onDownloadCompleted()
      }
    }
  }

  function pdfHandler(this: XMLHttpRequest) {
    if (this.readyState === this.DONE) {
      if (this.status === 200) {
        let data = URL.createObjectURL(this.response)
        setPdf(data)
      } else {
        console.error('Error while getting Pdf')
      }
      setPdfRequested(false)
    }
  }

  const onInvoiceDownload = () => {
    if (pdf) {
      activateDownload()
    } else {
      var xhr = new XMLHttpRequest()
      xhr.open('GET', pdfUrl)
      xhr.onreadystatechange = pdfHandler
      xhr.responseType = 'blob'
      xhr.setRequestHeader('Authorization', 'Bearer ' + token)
      xhr.send()
      setPdfRequested(true)
    }
  }

  return (
    <div style={{ display: 'inline-flex' }}>
      <a
        style={{ display: 'none' }}
        href={pdf}
        download={fileName || null}
        ref={downloadLink}
        rel='noopener noreferrer'
        target='_blank'
      >
        Download pdf
      </a>

      <Button
        color={buttonColor || 'primary'}
        variant={buttonVariant || 'outlined'}
        disabled={!token || pdfRequested}
        onClick={() => onInvoiceDownload()}
      >
        {label || 'Download'}
      </Button>
    </div>
  )
}

export default InvoicePdfDownload

import React, { useState, useEffect, useContext } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  makeStyles,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  TextField,
  Grid
} from '@material-ui/core'
import {
  Invoice,
  Item,
  Detail,
  UpdateInvoiceItemInput,
  Template,
  InvoiceStatus
} from '../../../generated/graphql'
import ConfirmDialog from '../controls/ConfirmDialog'
import * as _ from 'lodash'
import { getDescendantProp } from '../../../lib/ObjectHelper'
import { useHistory } from 'react-router'
import { BreadcrumbsContext } from '../../controls/BreadcrumbsContext'
import * as Colors from '@material-ui/core/colors'
import Section from '../controls/Section'

const useStyles = makeStyles({
  root: {
    '& .MuiTextField-root': {
      width: '100%'
    }
  },
  table: {
    minWidth: 650
  },
  actionBar: {
    textAlign: 'right'
  },
  subSection: {
    marginTop: '2em'
  },
  formControl: {
    minWidth: 160,
  },
  actionButton: {
    verticalAlign: 'bottom',
    marginLeft: 10
  },
  saveSubjectButton: {
    width: '80px',
    height: '42px',
    marginLeft: '0px',
  },
  amountCol: {
    width: 100
  },
  vatCol: {
    width: 80
  },
  alignRight: {
    textAlign: 'right'
  },
  templateSelect: {
    width: '100%',
    textAlign: 'center'
  },
  formButtons: {
    textAlign: 'right'
  },
  deleteButton: {
    marginRight: 10
  },
})

interface PrepareInvoiceFormProps {
  loading: boolean
  invoice: Invoice
  fromCustomerMenu: boolean
  customerId?: string
  forceEdit: boolean
  onAddInvoiceItem(invoiceId: string, itemid: string): void
  onAddInvoiceDetail(invoiceId: string, itemid: string): void
  onDeleteInvoiceItem(id: string): void
  onDeleteInvoiceDetail(id: string): void
  onDeleteDraftInvoice(id: string): void
  onSaveItemChanges(data: UpdateInvoiceItemInput[]): void
  onSetTemplate(templateId: string): void
  onSetSubject(subject: string): void
}

interface TempValue {
  name: string
  id: string
  value: any
}

const PrepareInvoiceForm: React.FC<PrepareInvoiceFormProps> = ({
  invoice,
  loading,
  fromCustomerMenu,
  customerId,
  forceEdit,
  onAddInvoiceItem,
  onAddInvoiceDetail,
  onDeleteInvoiceItem,
  onDeleteInvoiceDetail,
  onDeleteDraftInvoice,
  onSaveItemChanges,
  onSetTemplate,
  onSetSubject
}) => {
  const { setBreadcrumbItems } = useContext(BreadcrumbsContext)
  const classes = useStyles()
  const history = useHistory()
  const [itemId, setItemId] = useState('')
  const [detailId, setDetailId] = useState('')
  const [selectedTableItemId, setSelectedTableItemId] = useState('')
  const [selectedTableDetailId, setSelectedTableDetailId] = useState('')
  const [hasChanges, setHasChanges] = useState(false)
  const [tempValues, setTempValues] = useState([] as TempValue[])
  const [templateId, setTemplateId] = useState('')
  const [subject, setSubject] = useState('')
  const [subTotal, setSubTotal] = useState(0)
  const [total, setTotal] = useState(0)
  const [availableItems, setAvailableItems] = useState([] as Item[])
  const [availableDetails, setAvailableDetails] = useState([] as Detail[])

  var availableTemplates: Template[] = []
  var vats = [0]

  useEffect(() => {
    let items = [{ name: 'Home', location: '/home' }]
    let customerId = (invoice && invoice.customer && invoice.customer.id) || ''
    let invoiceId = (invoice && invoice.id) || ''

    if (!invoice || !invoice.id) return

    let prefix = ''
    if (fromCustomerMenu) {
      items.push({ name: 'Customers', location: '/customers' })
      let c = invoice.customer
      let name =
        `${c.firstName || ''} ${c.lastName || ''}`.trim() ||
        c.companyName ||
        'Unnamed customer'
      items.push({
        name,
        location: `/customer/${customerId}`
      })
      prefix = `/customer/${customerId}`
    } else {
      items.push({ name: 'Invoices', location: '/invoices' })
    }

    items.push({ name: forceEdit ? 'Modify invoice' : 'New invoice', location: `${prefix}/invoice/${invoiceId}` })

    setBreadcrumbItems(items)

    const getTemplateId = () => {
      let id = getDescendantProp(invoice, 'template.id')
      if (id) return id

      let templates = getDescendantProp(invoice, 'user.templates')
      if (!templates) return ''

      return templates[0].id
    }

    const invoiceItemIds = invoice.invoiceItems
      .map(x => x.item && x.item.id)
      .filter(x => !!x) as string[]
    const invoiceDetailIds = invoice.invoiceDetails
      .map(x => x.detail && x.detail.id)
      .filter(x => !!x) as string[]

    setAvailableItems(
      invoice.user.items.filter(x => !invoiceItemIds.includes(x.id))
    )
    setAvailableDetails(
      invoice.user.details.filter(x => !invoiceDetailIds.includes(x.id))
    )

    setSubject(invoice.subject || '')
    setSelectedTableItemId('')
    setSelectedTableDetailId('')
    setItemId('')
    setDetailId('')
    setSubTotal(getSubTotal(invoice))
    setTotal(getTotal(invoice))
    setTemplateId(getTemplateId())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice])

  const setField = (id: string, name: string, value: any) => {
    let newFields = [...tempValues]
    let o: TempValue | undefined = _.find(newFields, { id, name })
    if (o) {
      o.value = value
    } else {
      o = {
        id,
        name,
        value
      }
      newFields.push(o)
    }
    setTempValues(newFields)
  }

  const getField = (id: string, name: string) => {
    let o: TempValue | undefined = _.find(tempValues, { id, name })
    return o ? o.value : null
  }

  const setItemAmount = (id: string, value: string) => {
    setHasChanges(true)
    setField(id, 'amount', value)
  }

  const setItemDescription = (id: string, value: string) => {
    setHasChanges(true)
    setField(id, 'description', value)
  }

  const setItemPrice = (id: string, value: string) => {
    setHasChanges(true)
    setField(id, 'price', value)
  }

  const setItemVAT = (id: string, value: number) => {
    setHasChanges(true)
    setField(id, 'VAT', value)
  }

  const updateTemplateId = (id: string) => {
    onSetTemplate(id)
    setTemplateId(id)
  }

  const onAddItem = () => {
    if (itemId) {
      onAddInvoiceItem(invoice.id, itemId)
    }
  }

  const onDeleteItem = () => {
    onDeleteInvoiceItem(selectedTableItemId)
  }

  const handleSaveSubject = () => {
    onSetSubject(subject)
  }

  const onAddDetail = () => {
    if (detailId) {
      onAddInvoiceDetail(invoice.id, detailId)
    }
  }

  const onDeleteDetail = () => {
    onDeleteInvoiceDetail(selectedTableDetailId)
  }

  const toggleSelectedDetail = (id: string) => {
    if (selectedTableDetailId === id) {
      setSelectedTableDetailId('')
    } else {
      setSelectedTableDetailId(id)
    }
  }

  const toggleSelectedItem = (id: string) => {
    if (selectedTableItemId === id) {
      setSelectedTableItemId('')
    } else {
      setSelectedTableItemId(id)
    }
  }

  const getSubTotal = (invoice: Invoice) => {
    if (invoice && invoice.invoiceItems) {
      return invoice.invoiceItems.reduce((prev, x) => {
        let tempAmount = getField(x.id, 'amount')
        let amount = tempAmount != null ? tempAmount : x.amount || 1
        let price = x.price || (x.item ? x.item.price : 0)
        return price * amount + prev
      }, 0)
    } else {
      return 0
    }
  }

  const getTotal = (invoice: Invoice) => {
    if (invoice && invoice.invoiceItems) {
      return invoice.invoiceItems.reduce((prev, x) => {
        let tempAmount = getField(x.id, 'amount')
        let tempVAT = getField(x.id, 'VAT')
        let amount = tempAmount != null ? tempAmount : x.amount || 1
        let VAT = tempVAT != null ? tempVAT : x.VAT || 0
        let price = x.price || (x.item ? x.item.price : 0)
        let subtotal = price * amount
        return subtotal + (subtotal * VAT) / 100 + prev
      }, 0)
    } else {
      return 0
    }
  }

  if (!loading) {
    vats = [
      invoice.user.VAT1 || 0,
      invoice.user.VAT2 || 0,
      invoice.user.VAT3 || 0
    ]
    availableTemplates = [
      ...invoice.user.globalTemplates,
      ...invoice.user.templates
    ]
  }

  const handleSaveItemChanges = () => {
    let toUpdate: UpdateInvoiceItemInput[] = []
    tempValues.forEach(x => {
      let o: UpdateInvoiceItemInput | undefined = _.find(toUpdate, { id: x.id })
      if (!o) {
        o = { id: x.id } as UpdateInvoiceItemInput
        toUpdate.push(o)
      }

      if (x.name === 'amount') {
        o.amount = Number(x.value)
      } else if (x.name === 'VAT') {
        o.VAT = Number(x.value)
      } else if (x.name === 'description') {
        o.description = x.value
      } else if (x.name === 'price') {
        let price = Number(x.value)
        o.price = isNaN(price) ? 0 : price
      }
    })
    onSaveItemChanges(toUpdate)
    setTempValues([])
    setHasChanges(false)
  }

  const handleReviewInvoice = () => {
    let prefix = fromCustomerMenu ? `/customer/${customerId}` : ''
    let action = forceEdit ? 'view' : 'review'
    let url = `${prefix}/invoice/${invoice.id}/${action}`
    history.push(url)
  }

  let recipientName = invoice && invoice.customer ?
    `${invoice.customer.firstName || ''} ${invoice.customer.lastName || ''}`.trim() ||
    invoice.customer.companyName ||
    '' : ''

  let actionText = invoice.invoiceStatus === InvoiceStatus.Draft ?
    'Prepare' : `Modify ${invoice.invoiceStatus.toLowerCase()}`

  let canDelete = invoice.invoiceStatus === InvoiceStatus.Draft

  return (
    <div>
      <Section
        title={`${actionText} invoice`}
        subtitle={recipientName ? `for ${recipientName}` : ''}
      >
      { loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <br />
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={7}>
                <TextField
                  variant='outlined'
                  label='Subject'
                  placeholder={recipientName}
                  value={subject || ''}
                  onChange={e => setSubject(e.target.value)}
                />
              </Grid>
              <Grid item xs={4} md={2} style={{textAlign: 'left', paddingLeft: '0px'}}>
                <Button
                  className={classes.saveSubjectButton}
                  color='primary'
                  variant='contained'
                  disabled={!subject || subject === invoice.subject}
                  onClick={() => handleSaveSubject()}
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl
                  variant='outlined'                  
                  className={classes.templateSelect}
                >
                  <InputLabel>Invoice Template</InputLabel>
                  <Select
                    labelWidth={124}
                    value={templateId}
                    required
                    onChange={e => updateTemplateId(e.target.value as string)}
                  >
                    {availableTemplates.map((t, index) => {
                      return (
                        <MenuItem key={index} value={t.id}>
                          {t.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <br />
        </div>
      )}
      </Section>
    { !loading && (
      <div>
        <br/>
        <Section title="Billables" infoText="Your invoiceable items or services.">
          <div className={classes.actionBar}>
            <FormControl className={classes.formControl}>
              <InputLabel>Billable</InputLabel>
              <Select
                value={itemId}
                onChange={e => setItemId(e.target.value as string)}
              >
                {availableItems.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name || item.description} ({item.price} €)
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              className={classes.actionButton}
              color='primary'
              variant='contained'
              disabled={!itemId}
              onClick={onAddItem}
            >
              Add
            </Button>
            <Button
              disabled={!selectedTableItemId}
              className={classes.actionButton}
              color='primary'
              variant='contained'
              onClick={onDeleteItem}
            >
              Remove
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell padding='checkbox'></TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell align='right'>Price</TableCell>
                  <TableCell align='right'>Num. items</TableCell>
                  <TableCell align='right'>VAT</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoice.invoiceItems.map((invItem, index) => {
                  let tempVAT = getField(invItem.id, 'VAT')
                  let tempAmount = getField(invItem.id, 'amount')
                  let tempDescription = getField(invItem.id, 'description')
                  let tempPrice = getField(invItem.id, 'price')
                  let vat = tempVAT != null ? tempVAT : invItem.VAT || vats[0]
                  let description =
                    tempDescription != null
                      ? tempDescription
                      : invItem.description ||
                        (invItem.item ? invItem.item.description : '')
                  let price =
                    tempPrice != null
                      ? tempPrice
                      : invItem.price || (invItem.item ? invItem.item.price : 0)
                  let amount =
                    tempAmount != null ? tempAmount : invItem.amount || '1'

                  return (
                    <TableRow key={index}>
                      <TableCell padding='checkbox'>
                        <Checkbox
                          checked={selectedTableItemId === invItem.id}
                          onClick={() => toggleSelectedItem(invItem.id)}
                        />
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        <TextField
                          fullWidth
                          value={description}
                          required
                          onChange={e =>
                            setItemDescription(invItem.id, e.target.value)
                          }
                        />
                      </TableCell>
                      <TableCell align='right' component='th' scope='row'>
                        <TextField
                          inputProps={{
                            style: { textAlign: 'right', width: '80px' }
                          }}
                          value={price}
                          required
                          onChange={e =>
                            setItemPrice(invItem.id, e.target.value)
                          }
                        />
                      </TableCell>
                      <TableCell className={classes.amountCol}>
                        <TextField
                          inputProps={{ style: { textAlign: 'center' } }}
                          value={amount}
                          required
                          onChange={e =>
                            setItemAmount(invItem.id, e.target.value)
                          }
                        />
                      </TableCell>
                      <TableCell className={classes.vatCol}>
                        <FormControl className={classes.alignRight}>
                          <Select
                            value={vat || vats[0]}
                            required
                            onChange={e =>
                              setItemVAT(invItem.id, Number(e.target.value))
                            }
                          >
                            {vats.map((v, index) => {
                              let vat = v || 0
                              return (
                                <MenuItem key={index} value={vat}>
                                  {vat} %
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  )
                })}
                <TableRow>
                  <TableCell padding='checkbox'></TableCell>
                  <TableCell component='th' scope='row'>
                    <strong>SUBTOTAL</strong>
                  </TableCell>
                  <TableCell align='right'>
                    <strong>{subTotal.toFixed(2)} €</strong>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell padding='checkbox'></TableCell>
                  <TableCell component='th' scope='row'>
                    <strong>TOTAL</strong>
                  </TableCell>
                  <TableCell align='right'>
                    <strong>{total.toFixed(2)} €</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Section>

        <br/>
        <Section
          title="Policies"
          infoText="Additional invoice details, notes and rules."
        >
          <div className={classes.actionBar}>
            <FormControl className={classes.formControl}>
              <InputLabel>Policy</InputLabel>
              <Select
                value={detailId}
                onChange={e => setDetailId(e.target.value as string)}
              >
                {availableDetails.map((detail, index) => (
                  <MenuItem key={index} value={detail.id}>
                    {detail.name || detail.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              className={classes.actionButton}
              color='primary'
              variant='contained'
              disabled={!detailId}
              onClick={onAddDetail}
            >
              Add
            </Button>
            <Button
              disabled={!selectedTableDetailId}
              className={classes.actionButton}
              color='primary'
              variant='contained'
              onClick={onDeleteDetail}
            >
              Remove
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell padding='checkbox'></TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoice.invoiceDetails.map((invDetail, index) => {
                  let description =
                    getField(invDetail.id, 'description') ||
                    invDetail.description ||
                    (invDetail.detail ? invDetail.detail.description : '')
                  return (
                    <TableRow key={index}>
                      <TableCell padding='checkbox'>
                        <Checkbox
                          checked={selectedTableDetailId === invDetail.id}
                          onClick={() => toggleSelectedDetail(invDetail.id)}
                        />
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {description}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Section>
      
        <br/>
        <div className={classes.formButtons}>
          { canDelete && (
          <ConfirmDialog
            buttonLabel='Delete'
            buttonClassName={classes.deleteButton}
            customButtonColor={Colors.red}
            title='Delete draft invoice?'
            onAccept={() => onDeleteDraftInvoice(invoice.id)}
          >
            Are you sure you want delete the current invoice?
          </ConfirmDialog>
          )}
          {hasChanges ? (
            <Button
              className={classes.actionButton}
              color='primary'
              variant='contained'
              onClick={handleSaveItemChanges}
            >
              Save
            </Button>
          ) : (
            <Button
              className={classes.actionButton}
              color='primary'
              variant='contained'
              onClick={() => handleReviewInvoice()}
            >
              { forceEdit ? 'View' : 'Review'}
            </Button>
          )}
        </div>
      </div>
    )}
    </div>
  )
}

export default PrepareInvoiceForm

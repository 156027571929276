import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import ConfirmDialog from './controls/ConfirmDialog'
import InvoicePdfPreview from './controls/InvoicePdfPreview'
import InvoicePdfDownload from './controls/InvoicePdfDownload'
import { BreadcrumbsContext } from '../controls/BreadcrumbsContext'
import { Invoice } from '../../generated/graphql'
import Section from './controls/Section'

const useStyles = makeStyles({
  button: {
    marginLeft: 10
  },
  actionBar: {
    marginTop: '2em',
    textAlign: 'center',
  }
})

interface ReviewInvoiceProps {
  id: string
  customerId?: string
  fromCustomerMenu: boolean
  invoice: Invoice
  loading: boolean
  onFinalizeInvoice(): void
}

const ReviewInvoice: React.FC<ReviewInvoiceProps> = ({
  id,
  customerId,
  fromCustomerMenu,
  onFinalizeInvoice,
  invoice,
}) => {
  const classes = useStyles()
  const { setBreadcrumbItems } = React.useContext(BreadcrumbsContext)
  const [fileName, setFileName] = useState('')

  React.useEffect(() => {    
    let items = [{ name: 'Home', location: '/home' }]
    let prefix = ''

    if (fromCustomerMenu) {
      items.push({ name: 'Customers', location: '/customers' })
      let c = invoice.customer
      let name =
        `${c.firstName || ''} ${c.lastName || ''}`.trim() ||
        c.companyName ||
        'Unnamed customer'
      items.push({
        name,
        location: `/customer/${customerId}`
      })
      prefix = `/customer/${customerId}`
    } else {
      items.push({ name: 'Invoices', location: '/invoices' })
    }

    if (invoice.id) {
      items.push({ name: 'New invoice', location: `/invoice/${id}`})
      items.push({ name: 'Review', location: `${prefix}/invoice/${id}/review` })
      let c = invoice.customer
      let name =
        `${c.firstName || ''} ${c.lastName || ''}`.trim() ||
        c.companyName ||
        'Unnamed customer'
      setFileName(`DRAFT Invoice - ${name}.pdf`)
    }
        
    setBreadcrumbItems(items)
    // eslint-disable-next-line
  }, [invoice])

  const confirmFinalize = () => {
    onFinalizeInvoice()
  }

  return (
    <Section title="Review Invoice">
      <br/>
      <InvoicePdfPreview id={id} />
      <div className={classes.actionBar}>
        <InvoicePdfDownload id={id} label="Download Draft" fileName={fileName} />
        <ConfirmDialog
          buttonLabel='Continue'
          buttonClassName={classes.button}
          title='Generate invoice?'
          onAccept={() => confirmFinalize()}
        >
          Are you sure you want to generate the current invoice? After the
          invoice is generated, you will not be able to edit its details.
        </ConfirmDialog>
      </div>
    </Section>
  )
}

export default ReviewInvoice

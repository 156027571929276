import React from 'react';
import { useAuth0 } from '../../../react-auth0-spa'
import { Button } from '@material-ui/core'

const LoginButton: React.FC = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()

  return (
    <span>
      {isAuthenticated && (
        <Button color='default' onClick={() => logout()}>
          Log out
        </Button>
      )}

      {!isAuthenticated && (
        <Button color='default' onClick={() => loginWithRedirect()}>
          Log in
        </Button>
      )}
    </span>
  )
}

export default LoginButton

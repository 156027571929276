import React, { useState, Fragment } from 'react'
import {
  makeStyles,
  Button,
  IconButton,
  Grid,
  TextField,
  Tooltip,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import EditIcon from '@material-ui/icons/Edit'
import InvoicePdfPreview from './controls/InvoicePdfPreview'
import InvoicePdfDownload from './controls/InvoicePdfDownload'
import { BreadcrumbsContext } from '../controls/BreadcrumbsContext'
import { Invoice, InvoiceStatus } from '../../generated/graphql'
import { InvoiceStatusIcon } from './controls/StatusIcon'
import Section from './controls/Section'
import moment from 'moment'
import ConfirmDialog from './controls/ConfirmDialog'
import { useHistory } from 'react-router'

const useStyles = makeStyles({
  root: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  actionBar: {
    marginTop: '2em',
    textAlign: 'center',
  },
  details: {
    textAlign: 'left',
    width: 595 * 1.2,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  saveSubjectButton: {
    width: '80px',
    height: '42px',
    marginLeft: '0px',
  },
  saveDateButton: {
    width: '80px',
    height: '42px',
    marginTop: '12px',
    marginLeft: '10px',
  },
  invoice: {
    marginTop: '2em',
  },
  statusIconWrapper: {
    position: 'relative',
    margin: 'auto',
    width: '80%',
  },
  status: {
    fontSize: '36px',
    marginLeft: '4px',
  },
  editButton: {
    display: 'inline-flex',
    marginTop: '-26px',
    verticalAlign: 'middle',
  },
})

interface ViewInvoiceProps {
  loading: boolean
  invoice: Invoice
  id: string
  customerId?: string
  fromCustomerMenu: boolean
  onDownloadCompleted(): void
  onSetPaidDate(id: string, date: Date): void
  onSetInvoiceSubject(id: string, subject: string): void
}

const ViewInvoice: React.FC<ViewInvoiceProps> = ({
  id,
  customerId,
  fromCustomerMenu,
  onDownloadCompleted,
  onSetPaidDate,
  onSetInvoiceSubject,
  invoice,
}) => {
  const classes = useStyles()
  const { setBreadcrumbItems } = React.useContext(BreadcrumbsContext)
  const [fileName, setFileName] = useState('')
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null)
  const [subject, setSubject] = useState('')
  const history = useHistory()

  React.useEffect(() => {
    let items = [{ name: 'Home', location: '/home' }]
    let prefix = ''
    if (fromCustomerMenu) {
      items.push({ name: 'Customers', location: '/customers' })
      let c = invoice.customer
      let name =
        `${c.firstName || ''} ${c.lastName || ''}`.trim() ||
        c.companyName ||
        'Unnamed customer'
      items.push({
        name,
        location: `/customer/${customerId}`
      })
      prefix = `/customer/${customerId}`
    } else {
      items.push({ name: 'Invoices', location: '/invoices' })
    }

    if (invoice.id) {
      items.push({ name: 'View', location: `${prefix}/invoice/${id}/view` })
      let c = invoice.customer
      let name =
        `${c.firstName || ''} ${c.lastName || ''}`.trim() ||
        c.companyName ||
        'Unnamed customer'
      setFileName(`${invoice.invoiceNumber} - ${name}.pdf`)
      setSubject(invoice.subject || '')
    }
    setBreadcrumbItems(items)

    if (invoice.paidDate) {
      setSelectedDate(invoice.paidDate)
    }
    // eslint-disable-next-line
  }, [invoice])

  const handleDateChange = (value: any) => {
    let mom = moment(value)
    let date = mom.toDate()
    setSelectedDate(date)
  }

  const handleSubmitPaidDate = () => {
    if (invoice.id && selectedDate) {
      onSetPaidDate(invoice.id, selectedDate)
    }
  }

  const handleSaveSubject = () => {
    if (invoice.id) {
      onSetInvoiceSubject(invoice.id, subject)
    }
  }

  const onForceEdit = (id: string) => {
    let prefix = fromCustomerMenu ? `/customer/${customerId}` : ''
    let url = `${prefix}/invoice/${id}?forceEdit=true`
    history.push(url)
  }

  let subjectPlaceHolder =
    invoice && invoice.customer
      ? `${invoice.customer.firstName || ''} ${
          invoice.customer.lastName || ''
        }`.trim() ||
        invoice.customer.companyName ||
        ''
      : ''


  let additionalStatus = ''
  if (invoice.invoiceStatus === 'PAID') {
    additionalStatus = ' and paid'
  } else if (invoice.invoiceStatus === 'SENT') {
    additionalStatus = ' and sent'
  }

  const widgets = (
    <Fragment>
      <div className={classes.editButton}>
      <ConfirmDialog
          buttonLabel='Edit'
          customButton={(
            <Tooltip title='Force edit invoice'>
              <IconButton>
                <EditIcon/>
              </IconButton>
            </Tooltip>
            )}
          title={`Edit ${invoice.invoiceStatus.toLowerCase()} invoice`}
          onAccept={() => onForceEdit(invoice.id)}
          >
            The current invoice is already created{additionalStatus}.
            Are you sure you want edit this invoice?
        </ConfirmDialog>
        </div>
      <InvoiceStatusIcon
        className={classes.status}
        status={invoice.invoiceStatus || ''}
      />
    </Fragment>
  )

  return (
    <Section title='View Invoice' widgets={widgets}>
      <br />
      <div className={classes.root}>
        <div className={classes.details}>
          <Grid container spacing={3}>
            <Grid item xs={9} md={5}>
              <TextField
                variant='outlined'
                label='Subject'
                placeholder={subjectPlaceHolder}
                value={subject || ''}
                onChange={(e) => setSubject(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={3}
              md={2}
              style={{ textAlign: 'left', paddingLeft: '0px' }}
            >
              <Button
                className={classes.saveSubjectButton}
                color='primary'
                variant='contained'
                disabled={!subject || subject === invoice.subject}
                onClick={() => handleSaveSubject()}
              >
                Save
              </Button>
            </Grid>
            {[InvoiceStatus.Paid, InvoiceStatus.Sent].includes(
              invoice.invoiceStatus
            ) && (
              <Grid container>
                <Grid item xs={8}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{ marginTop: '12px', width: '100%' }}
                      inputVariant='outlined'
                      margin='normal'
                      label='Payment received date'
                      format='LL'
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{ textAlign: 'left', paddingLeft: '0px' }}
                >
                  <Button
                    className={classes.saveDateButton}
                    color='primary'
                    variant='contained'
                    disabled={
                      !selectedDate || selectedDate === invoice.paidDate
                    }
                    onClick={handleSubmitPaidDate}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
        <div className={classes.invoice}>
          <InvoicePdfPreview id={id} />
        </div>
        <div className={classes.actionBar}>
          <InvoicePdfDownload
            id={id}
            onDownloadCompleted={onDownloadCompleted}
            buttonVariant='contained'
            fileName={fileName}
          />
        </div>
      </div>
    </Section>
  )
}

export default ViewInvoice

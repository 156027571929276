import { createMuiTheme } from '@material-ui/core/styles'
import { PaletteOptions } from '@material-ui/core/styles/createPalette'
import { TypographyOptions } from '@material-ui/core/styles/createTypography'
import { colors } from '@material-ui/core'
import { Overrides } from '@material-ui/core/styles/overrides'

const palette: PaletteOptions = {
  primary: {
    main: '#388e3c',
  },
  secondary: {
    main: '#303f9f',
  },
  background: {
    default: colors.grey[200]
  }
}

const typography: TypographyOptions = {
  fontFamily: '"Oxygen", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: 14
}

const dataTableOverrides = {
  MUIDataTableBodyCell: {
    root: {
      padding: '10px 16px'
    }
  }
}

const overrides: Overrides = {
  MuiOutlinedInput: {    
    input: {
      padding: '14px 12px 10px 12px'
    }
  },
  MuiInputLabel: {
    outlined: {
      transform: "translate(14px, 14px) scale(1)"
    }
  },
  MuiButton: {
    root: {
      minWidth: '80px',
    }
  },  
  MuiTooltip: {
    tooltip: {
      fontSize: '14px'
    }    
  },
}

const theme = createMuiTheme({
  palette,
  typography,
  overrides: { ...overrides, ...dataTableOverrides} as Overrides
})

export default theme

import { Invoice } from "../generated/graphql";

export function calculateTotal(invoice: Invoice) {
  let total = 0;
  invoice.invoiceItems.forEach( x => {
    let price = x.price || (x.item ? x.item.price : 0)
    let sum = price * (x.amount || 1)
    let vat = sum * (x.VAT || 0) / 100
    total += sum + vat
  })
  return total
}
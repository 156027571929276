import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { 
  useGetAttachmentsLazyQuery,
  useDeleteAttachmentMutation,
  useCreateAttachmentMutation,
  Mutation,
  CreateAttachmentMutation,
  Attachment,
  GetAttachmentsQuery,
  GetExpensesQuery,
  useGetAttachmentLazyQuery,
} from '../../../generated/graphql'
import AttachmentList from '../../ui/lists/AttachmentList'
import { useNotification } from '../../controls/NotificationContext'
import { GET_EXPENSES_QUERY } from './ExpenseListContainer'

const CHUNK_SIZE = 1024 * 128;

function chunkSubstr(str: string, size: number) {
  const numChunks = Math.ceil(str.length / size)
  const chunks = new Array(numChunks)

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size)
  }

  return chunks
}

// eslint-disable-next-line
export const GET_ATTACHMENTS_QUERY = gql`
  query getAttachments($expenseId: String) {    
    attachments(expenseId: $expenseId) {
      id
      createdAt
      updatedAt
      blob
      fileName
      size
    }
  }
`

// eslint-disable-next-line
export const GET_ATTACHMENT_QUERY = gql`
  query getAttachment($id: String!) {    
    attachment(id: $id) {
      id
      createdAt
      updatedAt
      blob
      fileName
      size
      multiPage
      pngPages
    }
  }
`

// eslint-disable-next-line
const DELETE_ATTACHMENT_MUTATION = gql`
  mutation deleteAttachment($id: String!) {
    deleteAttachment(id: $id)
  }
`

// eslint-disable-next-line
const CREATE_ATTACHMENT_MUTATION = gql`
  mutation createAttachment($expenseId: String, $chunks: [String]!, $fileName: String) {
    createAttachment(expenseId: $expenseId, chunks: $chunks, fileName: $fileName) {
      id
      createdAt
      updatedAt
      blob
      fileName
      size
      multiPage
      pngPages
      expense {
        id
      }
    }
  }
`

interface AttachmentListContainerProps {
  expenseId: string
}

const ExpenseListContainer: React.FC<AttachmentListContainerProps> = ({
  expenseId
}) => {
  const notifications = useNotification()
  const [attachments, setAttachments] = useState([] as Attachment[])
  const [selectedAttachment, setSelectedAttachment] = useState(null as Attachment|null)
  const [uploading, setUploading] = useState(false)
  const [getAttachment, attachmentQuery] = useGetAttachmentLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: any) => {
      setSelectedAttachment(data.attachment as Attachment)
    },
    onError: console.error
  })
  const [getAttachments, { loading, refetch }] = useGetAttachmentsLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: any) => {     
      setAttachments(parseAttachments(data.attachments as Attachment[]))
    },
    onError: console.error
  })

  const parseAttachments = (attachments:Attachment[]): Attachment[] => {
    return attachments.map(x => {
      let tokens = (x.fileName || '').split('.')
      if (!x.fileName || tokens.length === 0)
        return x;
      let extension = tokens[tokens.length - 1]
      let regex = new RegExp(`\\.${extension}$`)
      let fileName = x.fileName.replace(regex, '.png')
      return {
        ...x,
        fileName
      }
    })
  }

  const [createAttachment] = useCreateAttachmentMutation({
    onError: (err) => {
      notifications.error(`ERROR: ${err.message}`)
      setUploading(false)
    },
    update( proxy, { data } ) {
      if (!data || !data.createAttachment) return;
      try {
        const attachmentsCache = proxy.readQuery<GetAttachmentsQuery>({ query: GET_ATTACHMENTS_QUERY, variables: { expenseId } })
        if (attachmentsCache && attachmentsCache.attachments) {
          proxy.writeQuery({
            query: GET_ATTACHMENTS_QUERY,
            data: { attachments: attachmentsCache.attachments.concat([data.createAttachment])},
          })
        }
        const expensesCache = proxy.readQuery<GetExpensesQuery>({ query: GET_EXPENSES_QUERY })
        if (expensesCache && expensesCache.expenses) {
          proxy.writeQuery({
            query: GET_EXPENSES_QUERY,
            data: { expenses: expensesCache.expenses.map(x => {              
              if (x.id === expenseId && data.createAttachment != null) {
                let id = data.createAttachment.id
                let size = data.createAttachment.size
                x.attachments = [...x.attachments, {id, size}]
              }
              return x
            })},
          })
        }        
      } catch {
      }
    },
    onCompleted: (data: CreateAttachmentMutation) => {
      setUploading(false)
      if (data && data.createAttachment) {
        notifications.success('Attachment saved')
        refresh()
      }
    }
  })

  const [deleteAttachment] = useDeleteAttachmentMutation({
    onError: console.log,
    update(proxy, {data} ) {
      if (!data || !data.deleteAttachment) return;
      try {
        const expensesCache = proxy.readQuery<GetExpensesQuery>({ query: GET_EXPENSES_QUERY })
        if (expensesCache && expensesCache.expenses) {
          proxy.writeQuery({
            query: GET_EXPENSES_QUERY,
            data: { expenses: expensesCache.expenses.map(x => {              
              if (x.id === expenseId && data.deleteAttachment != null) {
                x.attachments = x.attachments.filter(x => x.id !== data.deleteAttachment)
              }
              return x
            })},
          })
        }        
      } catch {
      }
    },
    onCompleted: (data: Mutation) => {
      notifications.success('Attachment deleted')
      refresh()
    }
  })
  
  const refresh = () => {
    refetch().then(res => {
      if (res && res.data) {
        setAttachments(parseAttachments(res.data.attachments as Attachment[]))
      }
    })
  }

  const onDeleteAttachment = (id: string) => {
    deleteAttachment({
      variables: { id }
    })
  }

  const onCreateAttachment = ( expenseId: string, blob: string, fileName: string): Promise<any> => {
    setUploading(true)
    let chunks = chunkSubstr(blob, CHUNK_SIZE)
    return createAttachment({
      variables: { expenseId, chunks, fileName }
    })
  }

  const onGetSelectedAttachment = (id: string) => {    
    getAttachment({variables: { id }})    
  }

  useEffect(() => {
    if (expenseId) {
      getAttachments({ variables: { expenseId } })
    }
  }, [expenseId, getAttachments])

  let props = {
    expenseId,
    attachments,
    selectedAttachment,
    selectedAttachmentLoading: attachmentQuery.loading,
    loading,
    uploading,
    onGetSelectedAttachment,
    onDeleteAttachment,
    onCreateAttachment
  }

  return <AttachmentList {...props} />
}

export default ExpenseListContainer

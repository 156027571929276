import React from 'react';
import Footer from './Footer'
import { Container, Grid, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  appBarSpacer: theme.mixins.toolbar,
  breadcrumbsSpacer: {
    width: '100%',
    height: '40px',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  }  
}))

const MainContainer: React.FC<any> = props => {
  const classes = useStyles()

  return (    
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <div className={classes.breadcrumbsSpacer} />
      <Container maxWidth='lg' className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{marginTop: 0}}>
            <Paper className={classes.paper}>{props.children}</Paper>
          </Grid>
        </Grid>
        <Footer />
      </Container>
    </main>    
  )
}

export default MainContainer

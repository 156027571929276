import { IconButton, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons'
import * as _ from 'lodash'
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables"
import * as React from 'react'
import { useHistory } from 'react-router'
import { Item } from '../../../generated/graphql'
import { BreadcrumbsContext } from '../../controls/BreadcrumbsContext'
import ConfirmDialog from '../controls/ConfirmDialog'
import { SortedColumn, createColumns, onColumnSortChange, onRowsSelect, defaultTableOptions } from '../../../lib/TableUtils'
import Section from '../controls/Section'

const useStyles = makeStyles({  
  row: {
    cursor: 'pointer'
  },
})

interface RowData {
  id: string
  name: string
  description: string
  price: number
}

function createData(
  id: string,
  name: string,
  description: string,
  price: number
): RowData {
  return {
    id,
    name,
    description,
    price
  }
}

interface ItemListProps {
  items: Item[]
  loading: boolean
  onDeleteItem(id: string): void
}

const ItemList: React.FC<ItemListProps> = ({ items, loading, onDeleteItem }) => {
  const { setBreadcrumbItems } = React.useContext(BreadcrumbsContext)
  const [rows, setRows] = React.useState([] as RowData[])
  const [rowsSelected, setRowsSelected] = React.useState([] as number[])
  const [sortedColumn, setSortedColumn] = React.useState({name: '', direction: 'none' } as SortedColumn);
  const classes = useStyles()
  const history = useHistory()

  const columns: MUIDataTableColumnDef[] = createColumns([
    { name: 'name', label: 'Name' },
    { name: 'description', label: 'Description' },
    {
      name: 'price',
      label: 'Price',
      options: {
        setCellProps: () => ({align: 'right'}),
        customBodyRender: (value) => `${value.toFixed(2)} €`,
      }
    }
  ], sortedColumn)

  const tableOptions:MUIDataTableOptions = {
    selectableRows: 'single',
    rowsSelected: rowsSelected,
    download: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    onRowClick: (rowData, rowMeta) => {
      let row = rows[rowMeta.dataIndex]
      history.push(`/billable/${row.id}`)
    },
    setRowProps: (row) => ({ className: classes.row }),
    onColumnSortChange: (column, direction) => onColumnSortChange(column, direction, setSortedColumn),
    customToolbar: () => (
      <React.Fragment>
        <Tooltip title='Add new billable'>
          <IconButton onClick={onCreateItem}>
            <AddIcon/>
          </IconButton>
        </Tooltip>
      </React.Fragment>
    ),
    onRowsSelect: (current, all) => onRowsSelect(current, all, rowsSelected, setRowsSelected),    
    customToolbarSelect: (selectedRows) => {
      let index = (selectedRows as any).data[0].dataIndex;
      let row = rows[index];
      return (
        <React.Fragment>          
          <ConfirmDialog
            customButton={(
            <Tooltip title='Delete billable'>
              <IconButton>
                <DeleteIcon/>
              </IconButton>
            </Tooltip>
            )}
            title='Delete Billable'
            onAccept={() => handleDeleteItem(row.id)}
          >
            Are you sure you want to delete the billable{' '}
            <strong>"{row.name}"</strong>?
          </ConfirmDialog>
        </React.Fragment>
      )
    }
  }
  

  React.useEffect(() => {
    setBreadcrumbItems([
      { name: 'Home', location: '/home' },
      { name: 'Billables', location: '/billables' }
    ])
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (!loading && items) {
      setRowsSelected([])
      let tableRows = _.sortBy(
        items.map(x => createData(x.id, x.name, x.description, x.price)),
        ['name']
      )
      setRows(tableRows)
    }
  }, [items, loading])

  const onCreateItem = () => {
    history.push('/billable/new')
  }

  const handleDeleteItem = (id: string) => {
    onDeleteItem(id)
  }

  return (
    <Section
      title="Billables"
      infoText="Invoiceable items or services to be added in your invoices."
    >
      {loading || !items ? (
        <div>Loading...</div>
      ) : (
        <MUIDataTable
          title=''
          data={rows}
          columns={columns}
          options={{...defaultTableOptions, ...tableOptions}}
        />
      )}
    </Section>
  )
}

export default ItemList

import React, { useState } from 'react'
import { useParams } from 'react-router'
import ViewInvoice from '../ui/ViewInvoice'
import gql from 'graphql-tag'
import {
  Invoice,
  useSetInvoiceStatusMutation,
  useSetPaidDateMutation,
  Mutation,
  InvoiceStatus,
  GetInvoicesQuery,
  useGetBasicInvoiceDataQuery,
  useSetInvoiceSubjectMutation
} from '../../generated/graphql'
import { useNotification } from '../controls/NotificationContext'
import { GET_INVOICES_QUERY } from './lists/InvoiceListContainer'

// eslint-disable-next-line
const UPDATE_INVOICE_STATUS_MUTATION = gql`
  mutation setInvoiceStatus($id: String!, $status: InvoiceStatus!) {
    setInvoiceStatus(id: $id, status: $status) {
      id
      invoiceStatus
      invoiceNumber
    }
  }
`

// eslint-disable-next-line
export const GET_INVOICE_CUSTOMER_QUERY = gql`
  query getBasicInvoiceData($id: String!) {
    invoice(id: $id) {
      id      
      invoiceStatus
      invoiceNumber
      paidDate
      subject
      customer {
        id
        firstName
        lastName
        companyName
      }
    }
  }
`

// eslint-disable-next-line
const SET_PAID_DATE_MUTATION = gql`
  mutation setPaidDate($id: String!, $date: DateTime!) {
    setPaidDate(id: $id, date: $date) {
      id
      invoiceStatus
      paidDate      
    }
  }
`

const ViewInvoiceContainer: React.FC = () => {
  const { id, customerId } = useParams()
  const notifications = useNotification()
  const [setInvoiceStatus] = useSetInvoiceStatusMutation({
    onError: console.log,
    onCompleted: (data: Mutation) => {
      if (data && data.setInvoiceStatus) {
        notifications.success('Invoice tagged as Sent')
      }
    },
    update(proxy, { data }) {
      if (!data || !data.setInvoiceStatus) return
      invoice.invoiceNumber = data.setInvoiceStatus.invoiceNumber;
      invoice.invoiceStatus = data.setInvoiceStatus.invoiceStatus;
      setInvoice(invoice)      
      try {
        const cache = proxy.readQuery<GetInvoicesQuery>({
          query: GET_INVOICES_QUERY
        })
        if (cache && cache.invoices) {          
          proxy.writeQuery({
            query: GET_INVOICES_QUERY,
            data: {
              invoices: cache.invoices.map(x => {
                if (x.id === id && data.setInvoiceStatus) {
                  x.invoiceStatus = data.setInvoiceStatus.invoiceStatus
                  x.invoiceNumber = data.setInvoiceStatus.invoiceNumber                                    
                }
                return x
              })
            }
          })
        }
      } catch {
      } finally {
        let updatedInvoice = {...invoice, ...data.setInvoiceStatus } as Invoice;
        setInvoice(updatedInvoice)
      }
    }
  })
  const [setPaidDate] = useSetPaidDateMutation({
    onError: console.log,
    onCompleted: (data: Mutation) => {
      if (data && data.setPaidDate) {
        notifications.success('Invoice tagged as Paid')
      }
    },
    update(proxy, { data }) {
      if (!data || !data.setPaidDate) return
      try {
        const cache = proxy.readQuery<GetInvoicesQuery>({
          query: GET_INVOICES_QUERY
        })
        if (cache && cache.invoices) {
          proxy.writeQuery({
            query: GET_INVOICES_QUERY,
            data: {
              invoices: cache.invoices.map(x => {
                if (x.id === id && data.setPaidDate) {
                  x.invoiceStatus = data.setPaidDate.invoiceStatus
                  x.paidDate = data.setPaidDate.paidDate
                }
                return x
              })
            }
          })
        }        
      } catch {
      } finally {
        let updatedInvoice = {...invoice, ...data.setPaidDate } as Invoice;
        setInvoice(updatedInvoice)
      }
    }
  })
  var [setInvoiceSubject] = useSetInvoiceSubjectMutation({
    onError: console.log,
    onCompleted: (data: Mutation) => {
      notifications.success('Subject updated')
      let updateInvoice = {
        ...invoice,
        subject: data.setInvoiceSubject?.subject || ''
      }      
      setInvoice(updateInvoice)
    }
  })
  const [invoice, setInvoice] = useState({} as Invoice)
  var { loading } = useGetBasicInvoiceDataQuery({
    variables: { id: id || '' },
    onCompleted: (data: any) => {
      setInvoice(data.invoice)
    },
    onError: console.log
  })

  const handleDownloadCompleted = () => {
    if (id) {
      setInvoiceStatus({ variables: { id, status: InvoiceStatus.Sent } })
    }
  }

  const handleSetPaidDate = (id: string, date: Date) => {
    if (id && date) {
      setPaidDate({ variables: { id, date } })
    }
  }

  const handleSetInvoiceSubject = (id: string, subject: string) => {
    if (id) {
      setInvoiceSubject({variables: {id, subject }})
    }
  }

  const fromCustomerMenu = !!customerId

  let props = {
    invoice,
    fromCustomerMenu,
    loading,
    id: id || '',
    customerId,
    onDownloadCompleted: handleDownloadCompleted,
    onSetPaidDate: handleSetPaidDate,
    onSetInvoiceSubject: handleSetInvoiceSubject,
  }

  return (loading || !invoice?.id) ? <div>Loading...</div> : <ViewInvoice {...props} />
}

export default ViewInvoiceContainer

import React, { useState } from 'react'
import gql from 'graphql-tag'
import {
  Template,
  useGetTemplatesQuery,
  useDeleteTemplateMutation,
  useCopyTemplateMutation,
  Mutation,
  
} from '../../../generated/graphql'
import TemplateList from '../../ui/lists/TemplateList'
import { useNotification } from '../../controls/NotificationContext'

// eslint-disable-next-line
export const GET_TEMPLATES_QUERY = gql`
  query getTemplates {
    templates {
      id
      updatedAt
      name
      description
      scope
    }
  }
`

// eslint-disable-next-line
const DELETE_TEMPLATE_MUTATION = gql`
  mutation deleteTemplate($id: String!) {
    deleteTemplate(id: $id)
  }
`

// eslint-disable-next-line
const COPY_TEMPLATE_MUTATION = gql`
  mutation copyTemplate($id: String!) {
    copyTemplate(id: $id) {
      id
      name
      description
      options
      updatedAt
      scope
    }
  }
`

const TemplateListContainer: React.FC = () => {
  const notifications = useNotification()
  const [templates, setTemplates] = useState([] as Template[])
  
  const { loading, refetch } = useGetTemplatesQuery({
    onCompleted: (data: any) => { 
      setTemplates(data.templates)
    },
    onError: console.log
  })

  const [deleteTemplate] = useDeleteTemplateMutation({
    onError: (err) => notifications.error(err.message),
    onCompleted: (data: Mutation) => {
      notifications.success('Template deleted')
      refetch().then( res => {
        if (res && res.data) {
          setTemplates(res.data.templates as Template[])
        }
      })
    }
  })

  const [copyTemplate] = useCopyTemplateMutation({
    onError: (err) => notifications.error(err.message),
    onCompleted: (data: Mutation) => {
      notifications.success('Template copied')
      refetch().then( res => {
        if (res && res.data) {
          setTemplates(res.data.templates as Template[])
        }
      })
    }
  })
  
  const onDeleteTemplate = (id: string) => {
    deleteTemplate({
      variables: { id }
    })
  }

  const onCopyTemplate = (id: string) => {
    copyTemplate({
      variables: { id }
    })
  }

  let props = {
    loading,
    templates,
    onDeleteTemplate,
    onCopyTemplate,
  }
  
  return <TemplateList {...props} />
}

export default TemplateListContainer

import { makeStyles } from '@material-ui/core/styles'
import * as _ from 'lodash'
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables"
import * as React from 'react'
import { useHistory } from 'react-router'
import { Invoice, InvoiceStatus } from '../../../generated/graphql'
import { calculateTotal } from '../../../lib/invoiceHelpers'
import { BreadcrumbsContext } from '../../controls/BreadcrumbsContext'
import { SortedColumn, createColumns, onColumnSortChange, defaultTableOptions } from '../../../lib/TableUtils'
import { InvoiceStatusIcon } from '../controls/StatusIcon'
import Section from '../controls/Section'
import moment from 'moment'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  row: {
    cursor: 'pointer'
  },
})

interface RowData {
  id: string
  invoiceNumber: string
  invoiceDate?: Date
  paidDate?: Date
  fullName: string
  status: InvoiceStatus
  amount: number
}

function createData(invoice: Invoice): RowData {
  let amount = calculateTotal(invoice)
  let isDraft = invoice.invoiceStatus === InvoiceStatus.Draft
  let fullName = invoice.subject || (isDraft
    ? `${invoice.customer.firstName || ''} ${invoice.customer.lastName || ''}`.trim() || invoice.customer.companyName || ''
    : `${invoice.recipientFirstName || ''} ${invoice.recipientLastName || ''}`.trim() || invoice.recipientCompanyName || '')

  return {
    id: invoice.id,
    invoiceNumber: invoice.invoiceNumber || '-',
    invoiceDate: invoice.invoiceDate,
    paidDate: invoice.paidDate,
    fullName: fullName,
    status: invoice.invoiceStatus,
    amount,
  }
}

interface InvoicesProps {
  invoices: Invoice[]
  loading: boolean
}

const Invoices: React.FC<InvoicesProps> = ({ invoices, loading }) => {
  const { setBreadcrumbItems } = React.useContext(BreadcrumbsContext)
  const [rows, setRows] = React.useState([] as RowData[])
  const [sortedColumn, setSortedColumn] = React.useState({name: '', direction: 'none' } as SortedColumn);
  const classes = useStyles()
  const history = useHistory()

  React.useEffect(() => {
    setBreadcrumbItems([
      {  name: 'Home', location: '/home' },
      {  name: 'Invoices', location: '/invoices' }
    ])
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (!loading && invoices) {
      let groups = _.partition(invoices, x => !x.invoiceNumber)
      let all = groups[0].concat(_.sortBy(groups[1], ['invoiceNumber']).reverse())
      let tableRows = all.map(x => createData(x))
      setRows(tableRows)
    }
  }, [invoices, loading])

  const columns: MUIDataTableColumnDef[] = createColumns([
    { name: 'invoiceNumber', label: 'Invoice Number' },
    {
      name: 'invoiceDate',
      label: 'Invoice date',
      options: {
        customBodyRender: (value) => value ? `${moment(value).format('LL')}` : '-',
      }
    },
    {
      name: 'paidDate',
      label: 'Payment date',
      options: {
        customBodyRender: (value) => value ? `${moment(value).format('LL')}` : '-',
      }
    },
    { name: 'fullName', label: 'Name' },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: (value) => (<InvoiceStatusIcon status={value} />)
      }
    },
    {
      name: 'amount',
      label: 'Amount',
      options: {
        setCellProps: () => ({align: 'right'}),
        customBodyRender: (value) => `${value.toFixed(2)} €`,
      },
    }
  ], sortedColumn);

  const tableOptions:MUIDataTableOptions = {
    selectableRows: 'none',
    download: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    onRowClick: (rowData, rowMeta) => {
      let row = rows[rowMeta.dataIndex];
      let url = row.status === InvoiceStatus.Draft ? `/invoice/${row.id}` : `/invoice/${row.id}/view`
      history.push(url)
    },
    setRowProps: (row) => ({ className: classes.row }),
    onColumnSortChange: (column, direction) => onColumnSortChange(column, direction, setSortedColumn),
  }
  
  return (
    <Section title="Invoices">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <MUIDataTable
          title=''
          data={rows}
          columns={columns}
          options={ {...defaultTableOptions, ...tableOptions} }
        />            
      )}
    </Section>
  )
}

export default Invoices

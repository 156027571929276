import React, { useState } from 'react'
import gql from 'graphql-tag'
import {
  useGetDetailsQuery,
  useDeleteDetailMutation,
  Detail,
  Mutation,
} from '../../../generated/graphql'
import DetailList from '../../ui/lists/DetailList'
import { useNotification } from '../../controls/NotificationContext'

// eslint-disable-next-line
export const GET_DETAILS_QUERY = gql`
  query getDetails {
    details {
      id
      name
      description
      tags
    }
  }
`

// eslint-disable-next-line
const DELETE_DETAIL_MUTATION = gql`
  mutation deleteDetail($id: String!) {
    deleteDetail(id: $id)
  }
`

const DetailListContainer: React.FC = () => {
  const notifications = useNotification()
  const [details, setDetails] = useState([] as Detail[])
  const { loading, refetch } = useGetDetailsQuery({
    onCompleted: (data: any) => {
      setDetails(data.details)
    },
    onError: console.log
  })

  const [deleteInvoiceDetail] = useDeleteDetailMutation({
    onError: console.log,
    onCompleted: (data: Mutation) => {
      notifications.success('Policy deleted')
      refetch().then(res => {
        if (res && res.data) {
          setDetails(res.data.details as Detail[])
        }
      })
    }
  })

  const onDeleteDetail = (id: string) => {
    deleteInvoiceDetail({
      variables: { id }
    })
  }

  let props = {
    details,
    loading,
    onDeleteDetail
  }

  return <DetailList {...props} />
}

export default DetailListContainer

import React, { useState } from 'react';

export interface BreadcrumbItem {
  name: string
  location: string
}

interface BreadcrumbsContextProps {
  items: Array<BreadcrumbItem>
  setBreadcrumbItems(items: Array<BreadcrumbItem>): void
}

export const BreadcrumbsContext = React.createContext({} as BreadcrumbsContextProps);

export const BreadcrumbsProvider: React.FC = ( { children }) => {
  const [items, setItems] = useState([] as Array<BreadcrumbItem>)

  return (
    <BreadcrumbsContext.Provider value={ {
      items,
      setBreadcrumbItems: items => setItems(items)
    }}>
      { children }
    </BreadcrumbsContext.Provider>
  )
}

export const BreadcrumbsConsumer = BreadcrumbsContext.Consumer;
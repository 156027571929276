import { AppBar, Button, CircularProgress, Dialog, Grid, IconButton, Slide, Theme, Toolbar, Tooltip, Typography } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions';
import Pagination from '@material-ui/lab/Pagination'
import { makeStyles } from '@material-ui/core/styles'
import { Add as AddIcon, Close as CloseIcon, Delete as DeleteIcon } from '@material-ui/icons'
import moment from 'moment'
import * as React from 'react'
import { Attachment } from '../../../generated/graphql'
import { arrayBufferToBase64 } from '../../../lib/Utils'
import ConfirmDialog from '../controls/ConfirmDialog'
import FileDialog from '../controls/FileDialog'
import Section from '../controls/Section'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1)
  },
  thumbnailWrapper: {
    width: '100%',
    bottom: '0px',
    display: 'block',
    minHeight: '240px',
    textAlign: 'center',
    position: 'relative',
  },
  thumbnail: {
    cursor: 'pointer',
    maxHeight: '240px',    
    maxWidth: '200px',
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    border: 'solid 1px lightgray',
  },
  addButton: {
    marginTop: '-6px'
  },
  fileName: {
    marginTop: '1em',
    marginBottom: 10,
    textAlign: 'center',
    fontStyle: 'italic',
  },
  deleteButton: {
    textAlign: 'center'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  previewContainer: {
    height: '100vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
  },
  previewImage: {
    width: 1200,
    maxWidth: '95%',
    marginTop: '1em',
    marginBottom: '2em',
    border: 'solid 1px lightgray',
  },
  noAttachments: {
    width: '100%',
    textAlign: 'center',
    margin: '1em 0'
  },
  uploadingProgress: {
    height: '240px',
    width: '180px',
    marginLeft: 'auto',
    marginRight: 'auto',
    border: 'solid 1px lightgray',
    paddingTop: '90px',
  },
  paginationContainer: {
    backgroundColor: theme.palette.background.default,
    textAlign: 'center'    
  },
  paginationRoot: {    
    width: '350px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > *': {      
      marginTop: theme.spacing(2),
    },
  }
}))

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AttachmentListProps {
  expenseId: string
  attachments: Attachment[]
  selectedAttachment: Attachment|null
  loading: boolean
  uploading: boolean
  onGetSelectedAttachment(id:string): void
  onDeleteAttachment(id: string): void
  onCreateAttachment(expenseId: string, blob: string, fileName: string): void
}

const AttachmentList: React.FC<AttachmentListProps> = ({
  expenseId,
  attachments,
  selectedAttachment,
  loading,
  uploading,
  onGetSelectedAttachment,
  onDeleteAttachment,
  onCreateAttachment,
}) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [preview, setPreview] = React.useState(undefined as Attachment | undefined)
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1)
  const [previewImage, setPreviewImage] = React.useState(null as string | null | undefined)
  const downloadLink = React.createRef<HTMLAnchorElement>()

  const handleClickOpen = (attachment: Attachment) => {
    onGetSelectedAttachment(attachment.id)
    setCurrentPageNumber(1)
    setPreviewImage(attachment.blob)
    setPreview(attachment)
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteAttachment = (id: string) => {
    onDeleteAttachment(id)
  }

  const handleFileReaded = (fileName: string, size: number, data: ArrayBuffer) => {
    let base64Blob = arrayBufferToBase64(data)
    onCreateAttachment(expenseId, base64Blob, fileName)
  }

  const handleDownload = () => {
    downloadLink?.current?.click()
  }

  const handleChangePage = (event: object, page: number) => {    
    setPage(page)
  }

  const setPage = (page: number) => {
    if(selectedAttachment?.pngPages) {
      setCurrentPageNumber(page)
      setPreviewImage(selectedAttachment.pngPages[page - 1])      
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if(selectedAttachment?.pngPages) {
      switch (event.key) {
        case 'ArrowRight':
          if (currentPageNumber - 1 < selectedAttachment.pngPages.length - 1) {
            setPage(currentPageNumber + 1)
          }
          break;
        case 'ArrowLeft':
          if (currentPageNumber - 1 > 0) {
            setPage(currentPageNumber - 1)
          }
          break;
        default:
          break;
      }
    }
  }

  const widgets = (
    <React.Fragment>
      <Tooltip title='Add attachment'>
        <div className={classes.addButton}>
          <FileDialog
            disabled={uploading}
            customButton={
              <IconButton disabled={uploading}>
                <AddIcon />
              </IconButton>}
            label="Browse"
            accept="image/png, image/jpeg, application/pdf"
            onArrayBufferReaded={handleFileReaded}
          />
        </div>
      </Tooltip>
    </React.Fragment>
  )

  return (
    <div onKeyDown={handleKeyDown}>
      <Section
        title="Attachments"
        infoText="Photos/scanned bills for this expense"
        widgets={widgets}>
        {loading || !attachments ? (
          <div>Loading...</div>
        ) : (
            <React.Fragment>
              <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition as any}>
                <AppBar position='absolute' className={classes.appBar}>
                  <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                      <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                      {preview?.fileName}
                    </Typography>
                    <a
                      style={{ display: 'none' }}
                      href={`data:image/png;base64,${previewImage}`}
                      download={preview?.fileName}
                      ref={downloadLink}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Download pdf
                  </a>
                    <Button autoFocus color="inherit" onClick={handleDownload}>
                      Download
                  </Button>
                  </Toolbar>
                </AppBar>
                { selectedAttachment && selectedAttachment.pngPages && (
                  <div className={classes.paginationContainer}>
                    <div className={classes.paginationRoot}>
                      <Pagination
                        count={selectedAttachment.pngPages.length}
                        page={currentPageNumber}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChangePage}
                        />
                    </div>
                  </div>
                )}
                <div className={classes.previewContainer}>
                  <img
                    className={classes.previewImage}
                    src={`data:image/png;base64,${previewImage}`} alt={preview?.fileName || ''}
                  />
                </div>
              </Dialog>
              <Grid container>
                {!uploading && !attachments.length ? (
                  <div className={classes.noAttachments}>
                    No attachments found.
                  </div>
                ) : attachments.map((x, index) => {
                  let dateStr = moment(x.updatedAt).format('LLL')
                  let size = `${(x.size / 1024).toFixed(2)} KB`
                  return (
                    <Grid key={index} item xs={12} md={4}>
                      <div className={classes.fileName}>{x.fileName} ({size})</div>
                      <div className={classes.thumbnailWrapper}>
                        <img
                          onClick={() => handleClickOpen(x)}
                          className={classes.thumbnail}
                          src={`data:image/png;base64,${x.blob}`} alt={`Attachment ${index}`}
                        />
                      </div>
                      <div className={classes.deleteButton}>
                        <ConfirmDialog
                          customButton={(
                            <Tooltip title='Delete attachment'>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                startIcon={<DeleteIcon />}
                              >
                                Delete
                            </Button>
                            </Tooltip>
                          )}
                          title='Delete Attachment'
                          onAccept={() => handleDeleteAttachment(x.id)}
                        >
                          Are you sure you want to delete the attachment{' '}
                          <strong>{x.fileName}</strong> from{' '}
                          <strong>{dateStr}</strong>?
                      </ConfirmDialog>
                      </div>
                    </Grid>
                  )
                }
                )}
                {uploading && (
                  <Grid item xs={12} md={4}>
                    <div className={classes.fileName}>Uploading...</div>
                    <div className={classes.thumbnailWrapper}>
                      <div className={classes.uploadingProgress}>
                        <CircularProgress />
                        </div>
                    </div>
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          )}
      </Section>
    </div>
  )
}

export default AttachmentList

import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router'
import ReviewInvoice from '../ui/ReviewInvoice'
import gql from 'graphql-tag'
import {
  useFinalizeInvoiceMutation,
  Mutation,
  useGetBasicInvoiceDataQuery,
  Invoice
} from '../../generated/graphql'
import { useNotification } from '../controls/NotificationContext'

// eslint-disable-next-line
const FINALIZE_INVOICE_MUTATION = gql`
  mutation finalizeInvoice($id: String!) {
    finalizeInvoice(id: $id) {
      id
      invoiceStatus
      invoiceNumber
      invoiceDate
    }
  }
`

const ReviewInvoiceContainer: React.FC = () => {
  const history = useHistory()
  const notifications = useNotification()
  const { id, customerId } = useParams()
  const fromCustomerMenu = !!customerId

  var [finalizesInvoice] = useFinalizeInvoiceMutation({
    onError: console.log,
    onCompleted: (data: Mutation) => {      
      notifications.success('Invoice finalized')
      let prefix = fromCustomerMenu ? `/customer/${customerId}` : ''
      history.push(`${prefix}/invoice/${id}/view`)
    }
  })
  const [invoice, setInvoice] = useState({} as Invoice)
  var { loading } = useGetBasicInvoiceDataQuery({
    variables: { id: id || '' },
    onCompleted: (data: any) => {
      setInvoice(data.invoice)
    },
    onError: console.log
  })

  const onFinalizeInvoice = () => {
    if (id) {
      finalizesInvoice({ variables: { id } })
    }
  }

  let props = {
    customerId,
    fromCustomerMenu,
    loading,
    invoice,
    id: id || '',
    onFinalizeInvoice
  }
  return (loading || !invoice?.id) ? <div>Loading...</div> : <ReviewInvoice {...props} />
}

export default ReviewInvoiceContainer

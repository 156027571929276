import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import ExpenseForm from '../../ui/forms/ExpenseForm'
import {
  Expense,
  UpdateExpenseInput,
  useGetExpenseLazyQuery,
  useUpdateExpenseMutation,
  GetExpensesQuery,
  UpdateExpenseMutation,
} from '../../../generated/graphql'
import { useParams, useHistory } from 'react-router'
import { useNotification } from '../../controls/NotificationContext'
import { GET_EXPENSES_QUERY } from '../lists/ExpenseListContainer'

// eslint-disable-next-line
export const GET_EXPENSE_QUERY = gql`
  query getExpense($id: String!) {
    user {
      id
      VAT1
      VAT2
      VAT3      
    },
    expense(id: $id) {
      id
      date
      tags
      name
      description
      price
      amount
      VAT
      attachments {
        id
        size
      }
    }
  }
`
// eslint-disable-next-line
const UPDATE_ITEM_MUTATION = gql`
  mutation updateExpense($id: String, $data: UpdateExpenseInput!) {
    updateExpense(id: $id, data: $data) {
      id
      date
      tags
      name
      description
      price
      amount
      VAT
      attachments {
        id
        size        
      }
    }
  }
`
const ItemDetailsContainer: React.FC = () => {
  const notifications = useNotification()
  const history = useHistory()
  const { id } = useParams<{id: string}>()
  const [expense, setExpense] = useState({} as Expense)
  const [vatList, setVatList] = useState([] as number[]);
  const [updateExpense] = useUpdateExpenseMutation({
    onError: console.log,
    update( proxy, { data } ) {
      if (id != null || !data || !data.updateExpense) return;
      try {
        const cache = proxy.readQuery<GetExpensesQuery>({ query: GET_EXPENSES_QUERY })
        if (cache && cache.expenses) {
          proxy.writeQuery({
            query: GET_EXPENSES_QUERY,
            data: { expenses: cache.expenses.concat([data.updateExpense])},
          })
        }
      } catch {}
    },
    onCompleted: (data: UpdateExpenseMutation) => {
      if (data && data.updateExpense) {
        notifications.success('Expense data saved')
        let x = data.updateExpense as Expense
        if (!id) {
          history.push(`/expense/${x.id}`)
        } else {
          setExpense(x)
        }
      }
    }
  })
  const [getExpense, { loading }] = useGetExpenseLazyQuery({
    onCompleted: (data: any) => {
      if (data.expense) {
        setExpense(data.expense)
      }      
      if (data.user) {
        let { VAT1, VAT2, VAT3 } = data.user;
        setVatList([VAT1, VAT2, VAT3])
      }      
    },
    onError: console.log
  })
  const onUpdateExpense = (data: UpdateExpenseInput, id: string | null): Promise<any> => {
    return updateExpense({
      variables: {
        id,
        data: data
      }
    })
  }

  useEffect(() => {
    getExpense({variables: { id: id || ""}})
  }, [id, getExpense])

  let props = {
    expense,
    vatList,
    loading,
    onUpdateExpense,
    isNew: id == null,
  }

  return <ExpenseForm {...props} />
}

export default ItemDetailsContainer

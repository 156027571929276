import React, { useState } from 'react'
import gql from 'graphql-tag'
import {
  Invoice,
  useGetInvoicesQuery,  
} from '../../../generated/graphql'
import InvoiceList from '../../ui/lists/InvoiceList'

// eslint-disable-next-line
export const GET_INVOICES_QUERY = gql`
  query getInvoices($customerId: String) {
    invoices(customerId: $customerId) {
      id
      invoiceDate
      updatedAt
      subject
      invoiceStatus
      paidDate
      invoiceNumber
    	recipientCompanyName
    	recipientFirstName
    	recipientLastName
      customer {
        id
        firstName
        lastName
        companyName
      }
      invoiceItems {
        amount
        price
        VAT
        item {
          price
        }
      }
    }
  }
`

const InvoiceListContainer: React.FC = () => {
  const [invoices, setInvoices] = useState([] as Invoice[])
  
  const { loading } = useGetInvoicesQuery({
    onCompleted: (data: any) => {
      setInvoices(data.invoices)
    },
    onError: console.log
  })
  
  let props = {
    loading,
    invoices,
  }

  return <InvoiceList {...props} />
}

export default InvoiceListContainer

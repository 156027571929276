import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { Template, UpdateTemplateInput, TemplateScope } from '../../../generated/graphql'
import { BreadcrumbsContext } from '../../controls/BreadcrumbsContext'
import SectionForm from '../controls/SectionForm'
import { useHistory } from 'react-router'
import FormTextField from '../controls/FormTextField'

interface TemplateProps {
  template: Template
  onUpdateTemplate(data: UpdateTemplateInput, id: string | null): Promise<any>
  loading: boolean,
  isNew: boolean,
}

const TemplateForm: React.FC<TemplateProps> = ({ template, loading, onUpdateTemplate, isNew }) => {
  const { setBreadcrumbItems } = React.useContext(BreadcrumbsContext)
  const [name, setName] = useState(template.name)
  const [description, setDescription] = useState(template.description)
  const [titleLabel, setTitleLabel] = useState('Invoice')
  const [subtitleLabel, setSubtitleLabel] = useState('')
  const [billedToLabel, setBilledToLabel] = useState('Billed to:')
  const [invoiceNumberLabel, setInvoiceNumberLabel] = useState('Invoice number')
  const [invoiceDateLabel, setInvoiceDateLabel] = useState('Invoice date')
  const [descriptionLabel, setDescriptionLabel] = useState('Description')
  const [numItemsLabel, setNumItemsLabel] = useState('Number')
  const [unitPriceLabel, setUnitPriceLabel] = useState('Unit')
  const [sumPriceLabel, setSumPriceLabel] = useState('Price')
  const [VATNumberLabel, setVATNumberLabel] = useState('VAT')
  const [KvkNumberLabel, setKvkNumberLabel] = useState('Kvk')
  const [VATLabel, setVATLabel] = useState('VAT')
  const [vatAmountLabel, setVatAmountLabel] = useState('Amount')
  const [totalLabel, setTotalLabel] = useState('Total')
  const [subtotalLabel, setSubtotalLabel] = useState('Subtotal')
  const [exemptLabel, setExemptLabel] = useState('Exempt')
  const [toTheNameOfLabel, setToTheNameOfLabel] = useState('FTAO')
  const [statingInvoiceNumberLabel, setStatingInvoiceNumberLabel] = useState('stating the invoice number.')
  const [editMode, setEditMode] = useState(isNew)
  const history = useHistory()

  const templateId = (template && template.id) || ''
  
  React.useEffect(() => {
    let items = [
      {  name: 'Home', location: '/home' },
      {  name: 'Templates', location: '/templates' }
    ]

    if (isNew) {
      items.push({ name: 'New', location: '/template/new' },)
    } else if (templateId) {
      items.push({ name: template.name || 'Edit', location: `/template/${templateId}` })
    }

    setBreadcrumbItems(items)
    // eslint-disable-next-line
  }, [templateId])
  
  useEffect(() => {
    if (!loading && template.id) {
      loadFields()
    }
    // eslint-disable-next-line
  }, [template, loading])

  const loadFields = () => {
    let options:any = JSON.parse(template.options || '{}')
    setName(template.name)
    setDescription(template.description)
    setTitleLabel(options.labels.title || '')
    setSubtitleLabel(options.labels.subtitle || '')
    setBilledToLabel(options.labels.billedTo || '')
    setInvoiceNumberLabel(options.labels.invoiceNumber || '')
    setInvoiceDateLabel(options.labels.invoiceDate || '')
    setDescriptionLabel(options.labels.description || '')
    setNumItemsLabel(options.labels.numItems || '')
    setUnitPriceLabel(options.labels.unitPrice || '')
    setSumPriceLabel(options.labels.sumPrice || '')
    setVATNumberLabel(options.labels.VATNumber || '')
    setKvkNumberLabel(options.labels.KvkNumber || '')
    setVATLabel(options.labels.VAT || '')
    setVatAmountLabel(options.labels.vatAmount || '')
    setTotalLabel(options.labels.total || '')
    setSubtotalLabel(options.labels.subtotal || '')
    setExemptLabel(options.labels.exempt || '')
    setToTheNameOfLabel(options.labels.toTheNameOf || '')
    setStatingInvoiceNumberLabel(options.labels.statingInvoiceNumber || '')
  }

  const buildTemplateOptions = () => {
    let options = template == null ? {} :JSON.parse(template.options || '{}')
    options.labels = {
      title: titleLabel || '',
      subtitle: subtitleLabel || '',
      billedTo: billedToLabel || '',
      invoiceNumber: invoiceNumberLabel || '',
      invoiceDate: invoiceDateLabel || '',
      description: descriptionLabel || '',
      numItems: numItemsLabel || '',
      unitPrice: unitPriceLabel || '',
      sumPrice: sumPriceLabel || '',
      VATNumber: VATNumberLabel || '',
      KvkNumber: KvkNumberLabel || '',
      VAT: VATLabel || '',
      vatAmount: vatAmountLabel || '',
      total: totalLabel || '',
      subtotal: subtotalLabel || '',
      exempt: exemptLabel || '',
      toTheNameOf: toTheNameOfLabel || '',
      statingInvoiceNumber: statingInvoiceNumberLabel || '',
    }
    return JSON.stringify(options)
  }

  const handleSubmit = (event: React.FormEvent) => {    
    event.preventDefault()
    let data = {
      name,
      description,
      options: buildTemplateOptions()
    }

    let p:Promise<any>
    if (isNew && !template.id) {
      p = onUpdateTemplate(data, null)
    } else {
      p = onUpdateTemplate(data, template.id)
    }
    p.then( () => setEditMode(false))
  }

  const onCancelEdit = () => {
    setEditMode(false)
    if (isNew) {
      history.replace('/templates')
    } else {
      loadFields()
    }
  }

  let disabled = template.scope === TemplateScope.Global

  return (
    <SectionForm
      title="Invoice Template"
      loading={loading || !template}
      disableEdit={!isNew && template.scope !== TemplateScope.User}
      isNew={isNew}
      editMode={editMode}
      onEdit={() => setEditMode(true)}      
      onCancel={onCancelEdit}
      onSubmit={handleSubmit}
    >
      <Grid item xs={12} md={3}>
        <FormTextField
          readOnly={!editMode}
          required
          disabled={disabled}
          label='Name'
          variant='outlined'
          value={name || ''}
          onChange={e => setName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <FormTextField
          readOnly={!editMode}
          required
          disabled={disabled}
          label='Description'
          variant='outlined'
          value={description || ''}
          onChange={e => setDescription(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <h2>Invoice labels</h2>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='Title'
          variant='outlined'
          value={titleLabel || ''}
          onChange={e => setTitleLabel(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='Subtitle'
          variant='outlined'
          value={subtitleLabel || ''}
          onChange={e => setSubtitleLabel(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='Kvk number'
          variant='outlined'
          value={KvkNumberLabel || ''}
          onChange={e => setKvkNumberLabel(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='VAT number'
          variant='outlined'
          value={VATNumberLabel || ''}
          onChange={e => setVATNumberLabel(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='Billed to'
          variant='outlined'
          value={billedToLabel || ''}
          onChange={e => setBilledToLabel(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='Invoice number'
          variant='outlined'
          value={invoiceNumberLabel || ''}
          onChange={e => setInvoiceNumberLabel(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='Invoice Date'
          variant='outlined'
          value={invoiceDateLabel || ''}
          onChange={e => setInvoiceDateLabel(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='Description'
          variant='outlined'
          value={descriptionLabel || ''}
          onChange={e => setDescriptionLabel(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='Number of items'
          variant='outlined'
          value={numItemsLabel || ''}
          onChange={e => setNumItemsLabel(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='Unit price'
          variant='outlined'
          value={unitPriceLabel || ''}
          onChange={e => setUnitPriceLabel(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='Sum price'
          variant='outlined'
          value={sumPriceLabel || ''}
          onChange={e => setSumPriceLabel(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='VAT'
          variant='outlined'
          value={VATLabel || ''}
          onChange={e => setVATLabel(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='VAT Amount'
          variant='outlined'
          value={vatAmountLabel || ''}
          onChange={e => setVatAmountLabel(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='Total'
          variant='outlined'
          value={totalLabel || ''}
          onChange={e => setTotalLabel(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='Subtotal'
          variant='outlined'
          value={subtotalLabel || ''}
          onChange={e => setSubtotalLabel(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='Exempt'
          variant='outlined'
          value={exemptLabel || ''}
          onChange={e => setExemptLabel(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='For the attention of (FTAO)'
          variant='outlined'
          value={toTheNameOfLabel || ''}
          onChange={e => setToTheNameOfLabel(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          readOnly={!editMode}
          disabled={disabled}
          label='Stating invoice number'
          variant='outlined'
          value={statingInvoiceNumberLabel || ''}
          onChange={e => setStatingInvoiceNumberLabel(e.target.value)}
        />
      </Grid>             
    </SectionForm>
  )
}

export default TemplateForm

import React from 'react'
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers'
import { withStyles, Theme } from '@material-ui/core/styles'

export interface CustomKeyboardDatePickerProps {
  readOnly?: boolean
}

export type FormKeyboardDatePickerProps = CustomKeyboardDatePickerProps & KeyboardDatePickerProps

export const FormKeyboardDatePicker: React.FC<FormKeyboardDatePickerProps> = (props) => {    
  let disabled = props.readOnly
  let required = props.readOnly ? false : props.required
    
  let updatedProps = {
    ...props,
    ...{
      disabled,
      required,
    }
  } as KeyboardDatePickerProps

  const ThemedKeyboardDatePicker = props.readOnly ? withStyles(({palette}: Theme) => ({
    root: {
      '& label.Mui-disabled': {
        color: palette.text.secondary
      },
      '& input.Mui-disabled': {
        color: palette.text.primary
      },
      '& .MuiInput-underline:before' : {
        borderBottom: 'none'
      },
      '& fieldset': {
        border: 'none'
      },
      '& button': {
        display: 'none'
      },
    }
  }))(KeyboardDatePicker) : KeyboardDatePicker

  return (
    <ThemedKeyboardDatePicker { ...updatedProps } />
  )
}

export default FormKeyboardDatePicker

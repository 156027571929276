import React from 'react'
import './App.css'
import { useAuth0 } from './react-auth0-spa'
import CustomTheme from './lib/CustomTheme'
import Header from './components/ui/controls/Header'
import MainContainer from './components/ui/controls/MainContainer'
import {
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom'
import PrivateRoute from './components/controls/PrivateRoute'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Home from './components/ui/pages/Home'
import AboutPage from './components/ui/pages/AboutPage'
import AccountFormContainer from './components/containers/forms/AccountFormContainer'
import InvoiceListContainer from './components/containers/lists/InvoiceListContainer'
import CustomerListContainer from './components/containers/lists/CustomerListContainer'
import CustomerFormContainer from './components/containers/forms/CustomerFormContainer'
import ItemListContainer from './components/containers/lists/ItemListContainer'
import ExpenseListContainer from './components/containers/lists/ExpenseListContainer'
import ExpenseFormContainer from './components/containers/forms/ExpenseFormContainer'
import TemplateListContainer from './components/containers/lists/TemplateListContainer'
import TemplateFormContainer from './components/containers/forms/TemplateFormContainer'
import ItemFormContainer from './components/containers/forms/ItemFormContainer'
import DetailListContainer from './components/containers/lists/DetailListContainer'
import DetailFormContainer from './components/containers/forms/DetailFormContainer'
import PrepareInvoiceFormContainer from './components/containers/forms/PrepareInvoiceFormContainer'
import ReviewInvoiceContainer from './components/containers/ReviewInvoiceContainer'
import ViewInvoiceContainer from './components/containers/ViewInvoiceContainer'
import WelcomePage from './components/ui/pages/WelcomePage'
import ForceCreateAccount from './components/controls/ForceCreateAccount'
import { BreadcrumbsProvider } from './components/controls/BreadcrumbsContext'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  }
}))

const App: React.FC<RouteComponentProps> = props => {
  const { isAuthenticated } = useAuth0()
  const classes = useStyles()  
  let loadingAccount = false

  return (
    <ThemeProvider theme={CustomTheme}>
      <BreadcrumbsProvider>
        <div className='App'>
          <div className={classes.root}>
            <Header showDrawer={isAuthenticated} />
            <ForceCreateAccount />
            <MainContainer>
              {loadingAccount ? (
                <div>Loading...</div>
              ) : (
                <Switch>
                  <PrivateRoute path='/home' component={Home} />
                  <PrivateRoute path='/account' component={AccountFormContainer} />
                  <PrivateRoute path='/customers' component={CustomerListContainer} />
                  <PrivateRoute path='/customer/new' component={CustomerFormContainer} />
                  <PrivateRoute path='/customer/:customerId/invoice/:id/view' component={ViewInvoiceContainer} />
                  <PrivateRoute path='/customer/:customerId/invoice/:id/review' component={ReviewInvoiceContainer} />
                  <PrivateRoute path='/customer/:customerId/invoice/:id' component={PrepareInvoiceFormContainer} />
                  <PrivateRoute path='/customer/:customerId' component={CustomerFormContainer} />
                  <PrivateRoute path='/billables' component={ItemListContainer} />
                  <PrivateRoute path='/billable/new' component={ItemFormContainer} />
                  <PrivateRoute path='/billable/:id' component={ItemFormContainer} />
                  <PrivateRoute path='/expenses' component={ExpenseListContainer} />
                  <PrivateRoute path='/expense/new' component={ExpenseFormContainer} />
                  <PrivateRoute path='/expense/:id' component={ExpenseFormContainer} />
                  <PrivateRoute path='/invoices' component={InvoiceListContainer} />
                  <PrivateRoute path='/invoice/:id/view' component={ViewInvoiceContainer} />
                  <PrivateRoute path='/invoice/:id/review' component={ReviewInvoiceContainer} />
                  <PrivateRoute path='/invoice/:id' component={PrepareInvoiceFormContainer} />
                  <PrivateRoute path='/policies' component={DetailListContainer} />
                  <PrivateRoute path='/policy/new' component={DetailFormContainer} />
                  <PrivateRoute path='/policy/:id' component={DetailFormContainer} />
                  <PrivateRoute path='/templates' component={TemplateListContainer} />
                  <PrivateRoute path='/template/new' component={TemplateFormContainer} />
                  <PrivateRoute path='/template/:id' component={TemplateFormContainer} />
                  <Route path='/about' component={AboutPage} />
                  <Route path='/' component={WelcomePage} />
                </Switch>
              )}
            </MainContainer>
          </div>
        </div>
      </BreadcrumbsProvider>
    </ThemeProvider>
  )
}

export default withRouter(App)

import React, { useContext, useState, useEffect } from 'react'
const uuidv4 = require('uuid/v4')

export enum NotificationType {
  INFO,
  SUCCESS,
  WARNING,
  ERROR
}

interface NotificationOptions {
  type?: NotificationType
  autoCloseDelay?: number
  autoClose?: boolean
  id?: string
}

interface Notification {
  id: string
  type: NotificationType
  text: string
  expires: number
  delay: number
  autoClose: boolean
}

var _notifications: Notification[] = []

type NotificationContextProps = {
  notifications: Notification[]
  notify(text: string, options?: NotificationOptions): void
  success(text:string, options?: NotificationOptions): void
  info(text:string, options?: NotificationOptions): void
  warning(text:string, options?: NotificationOptions): void
  error(text:string, options?: NotificationOptions): void
  dismiss(id: string): void
}

export const NotificationContext = React.createContext<
  NotificationContextProps
>({} as NotificationContextProps)

export const useNotification = () => useContext(NotificationContext)

interface Props {
  children: JSX.Element[] | JSX.Element
}

export const NotificationProvider = (props: Props) => {
  const [notifications, setNotifications] = useState([] as Notification[])
  useEffect(() => {}, [notifications])

  const addNotification = (n: Notification) => {
    if (notifications.filter(x => x.id === n.id).length > 0) {
      return
    }

    let delay = n.expires - Date.now()
    if (n.autoClose && delay < 0) {
      return
    }

    _notifications = [..._notifications, n]
    setNotifications(_notifications)

    if (n.autoClose && delay > 0) {
      setTimeout(() => dismiss(n.id), delay)
    }
  }

  const dismiss = (id: string) => {
    _notifications = _notifications.filter(n => n.id !== id)
    setNotifications(_notifications)
  }

  const notify = (text: string, options: NotificationOptions = {}) => {
    const type = options.type !== undefined ? options.type : NotificationType.INFO
    const delay = options.autoCloseDelay || (type === NotificationType.SUCCESS ? 3000 : 10000)
    const expires = Date.now() + delay
    const autoClose = options.autoClose != null ? options.autoClose : true
    const id = options.id || uuidv4()

    text = text.replace('GraphQL error:', '').trim()
    text = text.split('\n')[0] || '' // Only one line max
    text = text.substr(0, 200)    

    const n: Notification = {
      id,
      type,
      text,
      expires,
      delay,
      autoClose
    }

    addNotification(n)
  }

  const success = (text:string, options: NotificationOptions = {}) => {
    notify(text, { ...options, ...{type: NotificationType.SUCCESS}})
  }

  const warning = (text:string, options: NotificationOptions = {}) => {
    notify(text, { ...options, ...{type: NotificationType.WARNING}})
  }

  const info = (text:string, options: NotificationOptions = {}) => {
    notify(text, { ...options, ...{type: NotificationType.INFO}})
  }

  const error = (text:string, options: NotificationOptions = {}) => {
    notify(text, { ...options, ...{type: NotificationType.ERROR}})
  }

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        dismiss,
        notify,
        info,
        success,
        warning,
        error,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  )
}

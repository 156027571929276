import React from 'react';
import Menu from './Menu'
import LoginButton from './LoginButton'
import clsx from 'clsx'
import {
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  Eco as EcoIcon
} from '@material-ui/icons'
import NotificationArea from './NotificationArea';
import { useHistory } from 'react-router';
import Breadcrumbs from './Breadcrumbs'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  headerTitle: {
    cursor: 'pointer'
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  notificationArea: {
    zIndex: theme.zIndex.drawer + 2,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  titleIcon: {
    verticalAlign: 'text-bottom',
    marginRight: 4
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  fixedHeight: {
    height: 240
  }
}))

interface Props {
  showDrawer: boolean
}

const Header: React.FC<Props> = ({ showDrawer }) => {
  const history = useHistory()
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='absolute'
        className={clsx(
          classes.appBar,
          showDrawer && open && classes.appBarShift
        )}
      >        
        <NotificationArea className={clsx(classes.notificationArea, 'MuiPaper-elevation5')} />
        <Toolbar className={classes.toolbar}>
          {showDrawer && (
            <div>
              <IconButton
                edge='start'
                color='inherit'
                aria-label='open drawer'
                onClick={handleDrawerOpen}
                className={clsx(
                  classes.menuButton,
                  open && classes.menuButtonHidden
                )}
              >
                <MenuIcon />
              </IconButton>
            </div>
          )}
          <Typography
            component='h1'
            variant='h6'
            color='inherit'
            noWrap
            className={classes.title}
          >
            <span className={classes.headerTitle} onClick={() => history.push('/')}>
              <EcoIcon className={classes.titleIcon} />
              Eco Invoices
            </span>
          </Typography>
          <LoginButton />
        </Toolbar>
        <Breadcrumbs />
      </AppBar>
      {showDrawer && (
        <Drawer
          variant='permanent'
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Menu />
        </Drawer>
      )}      
    </div>
  )
}

export default Header

import { MUIDataTableColumnDef, MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";

export interface SortedColumn {
  name: string,
  direction: 'none' | 'asc' | 'desc' | undefined
}

export function createColumns(columns: MUIDataTableColumn[], sortedColumn: SortedColumn): MUIDataTableColumnDef[] {  
  return columns.map( (col) => {
    let options = col.options || {};
    return {
      name: col.name,
      label: col.label,
      options: {
        filter: true,
        sort: true,
        sortDirection: sortedColumn.name === col.name ? sortedColumn.direction : 'none',
        ...options
      }
    }
  })
}

export function onColumnSortChange(column: string, direction: string, setSortedColumn: (value: SortedColumn) => void) {
  let sortDir = 'none';
  switch (direction) {
    case 'ascending':
      sortDir = 'asc'
      break
    case 'descending':
      sortDir = 'desc'
      break
    default:
      sortDir = 'none'
      break
  }
  setSortedColumn({name: column, direction: sortDir} as SortedColumn)
}

export function onRowsSelect(current: any[], all: any[], rowsSelected: number[], setRowsSelected:(value:number[]) => void) {
  let index = current[0].dataIndex;      
  if (rowsSelected.length && !all.length) {
    setRowsSelected([])
  } else if (!rowsSelected.includes(index)) {
    setRowsSelected([index])
  }
}

export const defaultTableOptions: MUIDataTableOptions = {
  rowsPerPage: 15,
  rowsPerPageOptions: [10, 15, 100]
}
import React from 'react';
import { Typography, Link, Box } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom';



const Footer: React.FC = () => (
  <Box pt={4}>
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link component={RouterLink} color='inherit' to='https://ecoinvoices.com/'>
        Ecoinvoices
      </Link>{' '}
      {new Date().getFullYear()}
      {' | '}
      <Link component={RouterLink} color='inherit' to='/about'>
        About
      </Link>
    </Typography>
  </Box>
)

export default Footer
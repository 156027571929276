import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useAuth0 } from '../../react-auth0-spa'

interface PrivateRouteProps {
  component: React.FC
  path: string
}

const PrivateRoute:React.FC<PrivateRouteProps> = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (loading || isAuthenticated) {
      return
    }
    const fn = async () => {
      let currentPath = window.location.pathname
      await loginWithRedirect({
        appState: { targetUrl: currentPath }
      })
    }
    fn()
  }, [loading, isAuthenticated, loginWithRedirect, path])

  const render = (props: any) =>
    isAuthenticated === true ? <Component {...props} /> : null

  return <Route path={path} render={render} {...rest} />
}

export default PrivateRoute
